import React from 'react'
import { useAlert } from 'react-alert'
import { Platform, ScrollView, StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useMediaQuery } from 'react-responsive'

import { Header as AuthHeader } from '@components/AuthHeader'
import { Header } from '@components/Header'
import Footer from '@components/Footer'
import { useColorsContext, useStyles } from '@utils/styles'
import { useUserContext } from '@contexts/userContext'
import { ContentHeader } from './ContentHeader'
import { Box, Container, Tab, Tabs } from '@mui/material'
import HelpSections from './HelpSections'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}
const Help = ({ navigation }) => {
    const { user } = useUserContext()

    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const {
        primary,
        primaryVariant,
        red,
        redVariant,
        background,
        backgroundVariant,
        text,
        textVariant,
        border,
        yellow,
        green,
    } = useColorsContext()
    const defaultStyles = useStyles()

    const isXl = useMediaQuery({ query: '(min-width: 1280px)' })
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
    const isMd = useMediaQuery({ query: '(min-width: 768px)' })
    const isSm = useMediaQuery({ query: '(min-width: 640px)' })

    const customAlert = useAlert()

    const styles = StyleSheet.create({
        container: {
            backgroundColor: background,
            flex: 1,
        },
        scrollView: {
            flexGrow: 1,
            justifyContent: 'space-between',
            backgroundColor: background,
        },
        contentContainer: {
            padding: 0,
        },
    })

    return (
        <SafeAreaView style={styles.container}>
            <ScrollView
                contentContainerStyle={styles.scrollView}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            >
                <Container style={styles.contentContainer}>
                    <ContentHeader />
                    <Box>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant={isMd ? 'standard' : 'fullWidth'}
                        >
                            <Tab
                                label="Student"
                                sx={{ fontFamily: defaultStyles.titleFont }}
                            />
                            <Tab label="Tutor" />
                        </Tabs>
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        {value === 0 && <HelpSections type={'students'} />}
                        {value === 1 && <HelpSections type={'tutors'} />}
                    </Box>
                </Container>

                {isSm ? <Footer /> : null}
            </ScrollView>
        </SafeAreaView>
    )
}

export default Help
