import axios, {
    AxiosError,
    AxiosInstance,
    AxiosResponse,
    InternalAxiosRequestConfig,
} from 'axios'
import React, { createContext, useRef, useEffect, useContext } from 'react'
import { useUserContext } from './userContext'

export interface AxiosContextData {
    authenticatedAxios: AxiosInstance
}

export const AxiosContext = createContext<AxiosContextData>(
    {} as AxiosContextData
)

export const AxiosProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const { user } = useUserContext()

    const instanceRef = useRef(
        axios.create({
            baseURL: process.env.HOSTNAME,
            timeout: 10000,
        })
    )

    useEffect(() => {
        if (user?.accessToken) {
            const setupIntercepters = async () => {
                console.log('user', user)
                instanceRef.current.defaults.headers.common['Authorization'] =
                    'Bearer ' + user?.accessToken
                // const requestHandler = async (
                // 	request: InternalAxiosRequestConfig
                // ) => {
                // 	return request;
                // };
                // const responseHandler = (response: AxiosResponse) => {
                // 	return response;
                // };
                // const errorHandler = async (error: AxiosError) => {};
                // instanceRef.current.interceptors.request.use(
                // 	(request) => requestHandler(request),
                // 	(error) => errorHandler(error)
                // );
                // instanceRef.current.interceptors.response.use(
                // 	(response) => responseHandler(response),
                // 	(error) => errorHandler(error)
                // );
            }

            setupIntercepters()
        }
    }, [user?.accessToken])

    return (
        <AxiosContext.Provider
            value={{ authenticatedAxios: instanceRef.current }}
        >
            {children}
        </AxiosContext.Provider>
    )
}

export function useAxiosWithAuth(): AxiosContextData {
    const context = useContext(AxiosContext)

    if (!context) {
        throw new Error(
            'useAxiosWithAuth must be used with the AxiosContextProvider'
        )
    }

    return context
}
