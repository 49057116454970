import { ExpandMoreRounded } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Badge,
    Box,
    Chip,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Slider,
    Switch,
    TextField,
    Typography,
} from '@mui/material'
import { FilterType } from 'dataTypes'
import React, { FC } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import FontAwesome5 from '@expo/vector-icons/FontAwesome5'
import { useColorsContext } from '@utils/styles'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import TutredBaseChip from '@components/Chips/TutredBaseChip'

interface Props {
    filters: FilterType[]
    setFilters: React.Dispatch<React.SetStateAction<FilterType[]>>
    drawerOpen: boolean
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const getPriceMarks = (min: number, max: number) => {
    const marks = []
    for (let i = min; i <= max; i += 10) {
        marks.push({
            value: i,
            label: `${i}`,
        })
    }
    return marks
}

const TutorListFilters: FC<Props> = ({
    filters,
    setFilters,
    drawerOpen,
    setDrawerOpen,
}) => {
    const { text } = useColorsContext()

    const activeFilters = Object.keys(filters).filter((key) => {
        if (filters[key].type === 'boolean') {
            return filters[key].value
        } else if (filters[key].type === 'range') {
            return (
                filters[key].value[0] !== filters[key].minValue ||
                filters[key].value[1] !== filters[key].maxValue
            )
        } else if (filters[key].type === 'array') {
            return filters[key].value?.length > 0
        }
    })

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    marginTop: 2,
                    alignItems: 'center',
                }}
            >
                {drawerOpen ? (
                    <>
                        <Typography variant="h6">Filters</Typography>
                        <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                            <FontAwesome5
                                name={drawerOpen ? 'arrow-left' : 'arrow-right'}
                                size={18}
                                color={text}
                            />
                        </IconButton>
                    </>
                ) : (
                    <Badge
                        badgeContent={activeFilters?.length || 0}
                        color="primary"
                    >
                        <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                            <MaterialIcons
                                name="filter-list"
                                size={24}
                                color={text}
                            />
                        </IconButton>
                    </Badge>
                )}
            </Box>

            {drawerOpen && (
                <>
                    {Object.keys(filters).map((key) => {
                        const filter = filters[key]
                        if (filter.type === 'boolean') {
                            return (
                                <ListItem>
                                    <ListItemText
                                        sx={{ textTransform: 'capitalize' }}
                                        id={`switch-list-label-${key}`}
                                        primary={filter.label}
                                    />
                                    <Switch
                                        edge="end"
                                        onChange={() => {
                                            setFilters((prev) => {
                                                return {
                                                    ...prev,
                                                    [key]: {
                                                        ...prev[key],
                                                        value: !filters[key]
                                                            .value,
                                                    },
                                                }
                                            })
                                        }}
                                        checked={filters[key].value}
                                        inputProps={{
                                            'aria-labelledby': `switch-list-label-${key}`,
                                        }}
                                    />
                                </ListItem>
                            )
                        } else if (filter.type === 'range') {
                            return (
                                <ListItem disablePadding>
                                    <Accordion
                                        square
                                        sx={{
                                            m: 0,
                                            p: 0,
                                            width: '100%',
                                            boxShadow: 'none',
                                        }}
                                        disableGutters
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreRounded />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    sx={{ marginRight: 1 }}
                                                >
                                                    {filter.label}{' '}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {key === 'rate' && '$'}
                                                    {filter.value[0]}
                                                    {' - '}
                                                    {key === 'rate' && '$'}
                                                    {filter.value[1]}
                                                </Typography>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Slider
                                                getAriaLabel={() =>
                                                    filter.label
                                                }
                                                value={filter.value}
                                                onChange={(e, val) => {
                                                    setFilters((prev) => {
                                                        return {
                                                            ...prev,
                                                            [key]: {
                                                                ...prev[key],
                                                                value: val,
                                                            },
                                                        }
                                                    })
                                                }}
                                                valueLabelDisplay="auto"
                                                max={filter.maxValue}
                                                min={filter.minValue}
                                                marks={filter.marks || []}
                                                // getAriaValueText={}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </ListItem>
                            )
                        } else if (filter.type === 'array') {
                            return (
                                <ListItem disablePadding>
                                    <Accordion
                                        square
                                        sx={{
                                            m: 0,
                                            p: 0,
                                            width: '100%',
                                            boxShadow: 'none',
                                        }}
                                        disableGutters
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreRounded />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Badge
                                                    badgeContent={
                                                        filters[key].value
                                                            .length
                                                    }
                                                    invisible={
                                                        filters[key].value
                                                            .length === 0
                                                    }
                                                    color="primary"
                                                >
                                                    <Typography
                                                        sx={{ marginRight: 1 }}
                                                    >
                                                        {filter.label}{' '}
                                                    </Typography>
                                                </Badge>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Autocomplete
                                                multiple
                                                size="small"
                                                id="fixed-tags-demo"
                                                value={filters[key].value}
                                                onChange={(event, newValue) => {
                                                    setFilters((prev) => {
                                                        return {
                                                            ...prev,
                                                            [key]: {
                                                                ...prev[key],
                                                                value: newValue,
                                                            },
                                                        }
                                                    })
                                                }}
                                                options={filter.options}
                                                getOptionLabel={(option) =>
                                                    filter.optionType ===
                                                    'string'
                                                        ? option
                                                        : option.label
                                                }
                                                renderTags={(
                                                    tagValue,
                                                    getTagProps
                                                ) =>
                                                    tagValue.map(
                                                        (option, index) => {
                                                            const {
                                                                key,
                                                                ...tagProps
                                                            } = getTagProps({
                                                                index,
                                                            })
                                                            return (
                                                                <TutredBaseChip
                                                                    key={key}
                                                                    label={
                                                                        filter.optionType ===
                                                                        'string'
                                                                            ? option
                                                                            : option.label
                                                                    }
                                                                    {...tagProps}
                                                                />
                                                            )
                                                        }
                                                    )
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={filter.label}
                                                        placeholder={`Select ${filter.label}`}
                                                    />
                                                )}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </ListItem>
                            )
                        } else {
                            return null
                        }
                    })}
                </>
            )}
        </Box>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default TutorListFilters
