import React from 'react'
import { useAlert } from 'react-alert'
import { Platform, ScrollView, StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useMediaQuery } from 'react-responsive'

import { Header as AuthHeader } from '@components/AuthHeader'
import { Header } from '@components/Header'
import Footer from '@components/Footer'
import { useColorsContext, useStyles } from '@utils/styles'
import { useUserContext } from '@contexts/userContext'

export const About = ({ navigation }) => {
    const { user } = useUserContext()

    const {
        primary,
        primaryVariant,
        red,
        redVariant,
        background,
        backgroundVariant,
        text,
        textVariant,
        border,
        yellow,
        green,
    } = useColorsContext()
    const defaultStyles = useStyles()

    const isXl = useMediaQuery({ query: '(min-width: 1280px)' })
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
    const isMd = useMediaQuery({ query: '(min-width: 768px)' })
    const isSm = useMediaQuery({ query: '(min-width: 640px)' })

    const customAlert = useAlert()

    const styles = StyleSheet.create({
        container: {
            backgroundColor: background,
            flex: 1,
        },
        maxWidth: {
            flex: 1,
            backgroundColor: background,
            display: Platform.OS === 'web' ? 'flex' : 'none', // Use "flex" on web and "none" on mobile
            flexDirection: Platform.OS === 'web' ? 'row' : null,
            width: '100%',
            margin: Platform.OS === 'web' ? 'auto' : null,
        },
        contentContainer: {
            flexGrow: 1,
            justifyContent: 'space-between',
        },
        headingBig: {
            fontSize: 32,
            fontWeight: 900,
            color: text,
            marginBottom: 30,
        },
        heading: {
            fontSize: 24,
            fontWeight: 'bold',
            color: text,
            marginBottom: 10,
        },
        paragraph: {
            fontSize: 16,
            color: text,
            marginBottom: 20,
        },
        link: {
            color: primary,
            textDecorationLine: 'underline',
        },
    })

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.maxWidth}>
                <ScrollView
                    contentContainerStyle={styles.contentContainer}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                >
                    <View
                        style={{
                            padding: 30,
                            maxWidth: 1200,
                            margin: 'auto',
                        }}
                    >
                        <Text style={styles.headingBig}>About</Text>
                    </View>
                    {isSm ? <Footer /> : null}
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}
