import { A } from "@expo/html-elements";
import { AntDesign, Feather } from "@expo/vector-icons";
import { Link } from "@react-navigation/native";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Alert
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { Header as AuthHeader } from "../../../components/AuthHeader";
import Footer from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { LoadingPage } from "../../../components/LoadingPage";
import PaymentMethodModal from "../../../components/PaymentMethodModal";
import TutorMethodModal from "../../../components/TutorMethodModal";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";

// Firebase
import { deleteDoc, doc } from "firebase/firestore";


const hostname = process.env.HOSTNAME

// Account Screen
export const Account = ({ navigation }) => {
  const { user, name, URL, isTutor, getPaymentMethods, paymentMethods, customerId, id } =
    useUserContext();
  const { db } = useFirebaseContext();
  const [paymentMethodModal, setPaymentMethodModal] = useState(false);
  const [tutorMethodModal, setTutorMethodModal] = useState(false);
  const customAlert = useAlert();

  // User Context
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteText, setConfirmDeleteText] = useState("");
  const [lastUpdated, setLastUpdated] = useState(
    // Date formatted with year month day hour minute second in local time
    new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
  );

  // Colors and styles
  const {
    primary,
    primaryVariant,
    red,
    redVariant,
    background,
    backgroundVariant,
    text,
    textVariant,
    border,
    yellow,
    green,
    toggleDarkMode,
    darkMode,
  } = useColorsContext();
  const defaultStyles = useStyles();

  // Responsive Breakpoints
  const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
  const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  const isSm = useMediaQuery({ query: "(min-width: 640px)" });

  // Create stylesheet
  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      flex: 1,
    },
    maxWidth: {
      flex: 1,
      backgroundColor: background,
      display: "flex",
      flexDirection: Platform.OS === "web" ? "row" : null,
      paddingRight: 0, // Padding right is for the cover photo to stretch
      width: "100%",
      // maxWidth: Platform.OS === "web" ? 1350 : null,
      margin: Platform.OS === "web" ? "auto" : null,
    },
    mainSection: {
      flex: 1,
      padding: isSm ? 45 : 15,
      paddingTop: 30,
    },
    title: {
      fontSize: 36,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    subtitle: {
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
      color: text,
    },
    description: {
      fontSize: 16,
      fontFamily: defaultStyles.regularFont,
      color: textVariant,
    },
    infoTitle: {
      textAlign: "left",
      fontSize: 22,
      fontFamily: defaultStyles.boldFont,
      color: text,
    },
    infoSubtitle: {
      fontSize: 19,
      fontFamily: defaultStyles.boldFont,
      color: textVariant,
    },
    infoDescription: {
      fontSize: 16,
      fontFamily: defaultStyles.regularFont,
      color: textVariant,
      marginTop: 5,
      display: "flex",
      flex: 1,
    },
    infoLink: {
      fontSize: 14,
      fontFamily: defaultStyles.regularFont,
      color: primary,
      marginTop: 10,
    },
  });

  useEffect(() => {
    if (user) {
      // Log email notifications
      // console.log('notificationPreferences.newMessage: ', notificationPreferences.emailNotifications);
      getPaymentMethods(); // Fetch payment methods
      setLoading(false);
    } else {
      // No user found
      // Redirect to login
      window.location.href = "/";
    }
  }, [user]);

  // Delete Account
  const deleteAccount = () => {
    // Delete account
    let deleted = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone."
    );
    if (deleted) {
      let userRef = doc(db, "users", user.uid);
      // Delete user from database
      deleteDoc(userRef)
        .then(() => {
          // Delete user
          user.delete()
            .then(() => {
              // User deleted
              // Delete user from auth
              customAlert.success(
                "[Account Deleted] We are sorry to see you go! We hope to see you again soon."
              );
              auth.signOut();
              window.location.reload();
            })
            .catch((error) => {
              // An error happened.
              auth.signOut();
              customAlert.error(
                "[Error Deleting Account] An error occured while deleting your account. Please try again logging in again."
              );
            });
        })
        .catch((error) => {
          auth.signOut();
          customAlert.error(
            "[Error Deleting Account] An error occured while deleting your account. Please try again logging in again."
          );
        });
    }
  };

  const removePaymentMethod = (methodId) => {
    const confirmed = window.confirm("Are you sure you want to remove this card? This action cannot be undone.");
    if (confirmed) {
      fetch(`${hostname}/api/${customerId}/remove-payment-method`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({ paymentMethodId: methodId }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            customAlert.success("[Card Removed] Removed card successfully.");
            getPaymentMethods();
          } else {
            customAlert.error(data.message);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          customAlert.error("[Error Removing Card] Something went wrong. Please try again.");
        });
    }
  };

  console.log("paymentMethods", paymentMethods);

  return (
    <SafeAreaView style={styles.container}>

      {/* Main Content */}
      {loading ? (
        <LoadingPage />
      ) : (
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ backgroundColor: background }}
        >
          <PaymentMethodModal
            setModalVisible={setPaymentMethodModal}
            modalVisible={paymentMethodModal}
          />
          <TutorMethodModal
            setModalVisible={setTutorMethodModal}
            modalVisible={tutorMethodModal}
          />
          <View style={styles.maxWidth}>
            <View style={[styles.mainSection]}>
              {/* Title */}
              <Text
                style={{
                  color: text,
                  fontSize: 16,
                  fontFamily: defaultStyles.regularFont,
                  marginBottom: 5,
                }}
              >
                <Link
                  // onMouseEnter={handlePressIn}
                  // onMouseLeave={handlePressOut}
                  to={"/settings"}
                >
                  <Text>Settings</Text>
                </Link>{" "}
                <Text
                  style={{
                    fontFamily: defaultStyles.boldFont,
                    color: primary,
                  }}
                >
                  <Feather
                    name="chevron-right"
                    size={15}
                    color={text}
                  />{" "}
                  Account
                </Text>
              </Text>

              <Text style={styles.title}>Account settings</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    color: text,
                    fontSize: 16,
                    fontFamily: defaultStyles.regularFont,
                  }}
                >
                  {name ? name : "No Account, Please Sign in"}
                  ,{" "}
                </Text>
                <Text
                  style={{
                    color: text,
                    fontSize: 16,
                    fontFamily: defaultStyles.regularFont,
                  }}
                >
                  {user.email}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: isLg ? "row" : "column",
                  gap: 30,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    paddingRight: 30,
                    marginTop: 30,
                  }}
                >
                  {/* Put main Content here  */}
                  {/* Display name */}
                  <View
                    style={{
                      borderBottomColor: border,
                      borderBottomWidth: 1,
                      paddingBottom: 15,
                      marginBottom: 15,
                      display: "flex",
                      flexDirection: isMd
                        ? "row"
                        : "column",
                      justifyContent: "space-between",
                      alignItems: isMd
                        ? "center"
                        : "flex-start",
                    }}
                  >
                    <View
                      style={{
                        maxWidth: "75%",
                      }}
                    >
                      <Text style={styles.subtitle}>
                        Name
                      </Text>
                      <Text style={styles.description}>
                        {name ? name : "No name set"}
                      </Text>
                    </View>
                  </View>

                  {/* Display email */}
                  <View
                    style={{
                      borderBottomColor: border,
                      borderBottomWidth: 1,
                      paddingBottom: 15,
                      marginBottom: 15,
                      display: "flex",
                      flexDirection: isMd
                        ? "row"
                        : "column",
                      justifyContent: "space-between",
                      alignItems: isMd
                        ? "center"
                        : "flex-start",
                    }}
                  >
                    <View
                      style={{
                        maxWidth: "75%",
                      }}
                    >
                      <Text style={styles.subtitle}>
                        Email address
                      </Text>
                      <Text style={styles.description}>
                        {user.email}
                      </Text>
                    </View>
                  </View>

                  {!isTutor && user ? (
                    <>
                      {paymentMethods.map((method, index) => (
                        <View
                          key={index}
                          style={{
                            borderBottomColor: border,
                            borderBottomWidth: 1,
                            paddingBottom: 15,
                            marginBottom: 15,
                            display: "flex",
                            flexDirection: isMd ? "row" : "column",
                            justifyContent: "space-between",
                            alignItems: isMd ? "center" : "flex-start",
                          }}
                        >
                          <View style={{ maxWidth: "75%" }}>
                            <Text style={styles.subtitle}>Default card</Text>
                            <Text style={styles.description}>
                              {method.card.brand.toUpperCase()} •••• {method.card.last4} - {method.card.exp_month}/{method.card.exp_year}
                            </Text>
                          </View>
                          <View style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <TouchableOpacity
                              onPress={() => {
                                setPaymentMethodModal(true);
                              }}
                            >
                              <Text style={{ color: text, fontSize: 16, fontFamily: defaultStyles.regularFont, textAlign: "center" }}>
                                Update card
                              </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => removePaymentMethod(method.id)}
                            >
                              <Text style={{ color: "#FF0000", fontSize: 16, fontFamily: defaultStyles.regularFont, textAlign: "center" }}>
                                Remove card
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      ))}
                      {paymentMethods.length == 0 && (
                        <TouchableOpacity
                          onPress={() => {
                            // Trigger modal for payment method
                            setPaymentMethodModal(true);
                          }}
                          style={{
                            borderBottomColor: border,
                            borderBottomWidth: 1,
                            paddingBottom: 15,
                            marginBottom: 15,
                            display: "flex",
                            flexDirection: isMd ? "row" : "column",
                            justifyContent: "space-between",
                            alignItems: isMd ? "center" : "flex-start",
                          }}
                        >
                          <View style={{ maxWidth: "75%" }}>
                            <Text style={styles.subtitle}>Add a payment method</Text>
                            <Text style={styles.description}>Set your default payment method</Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </>
                  ) : null}

                  {/* Dark Mode  */}
                  <View
                    style={{
                      borderBottomColor: border,
                      borderBottomWidth: 1,
                      paddingBottom: 15,
                      marginBottom: 15,
                      display: "flex",
                      flexDirection: isMd
                        ? "row"
                        : "column",
                      justifyContent: "space-between",
                      alignItems: isMd
                        ? "center"
                        : "flex-start",
                    }}
                  >
                    <View
                      style={{
                        maxWidth: "75%",
                      }}
                    >
                      <Text style={styles.subtitle}>
                        Toggle{" "}
                        {darkMode ? "dark" : "light"}{" "}
                        mode
                      </Text>
                      <Text style={styles.description}>
                        Toggle between dark and light
                        mode.
                      </Text>
                    </View>
                    <Switch
                      style={{ marginTop: !isMd && 15 }}
                      trackColor={{
                        false: "#767577",
                        true: primary,
                      }}
                      thumbColor={
                        darkMode ? primary : "#f4f3f4"
                      }
                      ios_backgroundColor={primary}
                      onValueChange={toggleDarkMode}
                      value={darkMode}
                    />
                  </View>

                  {/* Change Password */}
                  <View
                    style={{
                      borderBottomColor: border,
                      borderBottomWidth: 1,
                      paddingBottom: 15,
                      marginBottom: 15,
                      display: "flex",
                      flexDirection: isMd
                        ? "row"
                        : "column",
                      justifyContent: "space-between",
                      alignItems: isMd
                        ? "center"
                        : "flex-start",
                    }}
                  >
                    <View
                      style={{
                        maxWidth: "75%",
                      }}
                    >
                      <Text style={[styles.subtitle]}>
                        Reset password
                      </Text>
                      <Text style={styles.description}>
                        Reset your password. You will be
                        sent an email with a link to
                        reset your password.
                      </Text>
                    </View>
                    <TouchableOpacity
                      onPress={() => {
                        fetch(`${hostname}/api/send-password-reset`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ email: user.email }),
                        })
                          .then(response => response.json())
                          .then(data => {
                            if (data.success) {
                              Platform.OS === "web"
                                ? customAlert.success(
                                  "[Password Reset] Password reset email sent! Please check your email to reset your password"
                                )
                                : Alert.alert(
                                  "Password reset email sent!",
                                  "Please check your email to reset your password",
                                  [{ text: "OK", onPress: () => console.log("OK Pressed") }]
                                );
                            } else {
                              Platform.OS === "web"
                                ? customAlert.error(
                                  "[Password Reset] Error sending password reset email. Please try again."
                                )
                                : Alert.alert(
                                  "Error sending password reset email",
                                  "Please try again.",
                                  [{ text: "OK", onPress: () => console.log("OK Pressed") }]
                                );
                            }
                          }).catch(error => {
                            console.error('Error:', error);
                            Platform.OS === "web"
                              ? customAlert.error(
                                "[Password Reset] Error sending password reset email. Please try again."
                              )
                              : Alert.alert(
                                "Error sending password reset email",
                                "Please try again.",
                                [{ text: "OK", onPress: () => console.log("OK Pressed") }]
                              );
                          });
                      }}
                      style={{ marginTop: 15 }}
                    >
                      <Text
                        style={{
                          color: primary,
                          fontSize: 16,
                          fontFamily: defaultStyles.regularFont,
                          textAlign: "center",
                        }}
                      >
                        Reset password
                      </Text>
                    </TouchableOpacity>
                  </View>

                  <TouchableOpacity
                    onPress={() => {
                      console.log('Attempting to confirm identity', { customerId });

                      fetch(`${hostname}/api/confirm-identity`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: "Bearer " + user.accessToken,
                        },
                        body: JSON.stringify({
                          customerId: customerId
                        }),
                      })
                        .then(response => response.json())
                        .then(data => {
                          if (data.success) {
                            console.log('Identity confirmation initiated successfully', data);
                            Platform.OS === "web"
                              ? customAlert.success("[Identity Confirmed] Identity confirmation initiated successfully.")
                              : Alert.alert(
                                "Identity confirmation initiated!",
                                "Please check your email for further instructions.",
                                [{ text: "OK", onPress: () => console.log("OK Pressed") }]
                              );
                          } else {
                            console.log('Error initiating identity confirmation', data);
                            Platform.OS === "web"
                              ? customAlert.error("[Identity Confirmation] Error initiating identity confirmation. Please try again.")
                              : Alert.alert(
                                "Error initiating identity confirmation",
                                "Please try again.",
                                [{ text: "OK", onPress: () => console.log("OK Pressed") }]
                              );
                          }
                        }).catch(error => {
                          console.error('Error:', error);
                          Platform.OS === "web"
                            ? customAlert.error("[Identity Confirmation] Error initiating identity confirmation. Please try again.")
                            : Alert.alert(
                              "Error initiating identity confirmation",
                              "Please try again.",
                              [{ text: "OK", onPress: () => console.log("OK Pressed") }]
                            );
                        });
                    }}
                    style={{ marginTop: 15 }}
                  >
                    {/* <Text
        style={{
            color: primary,
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
            textAlign: "center",
        }}
    >
        Confirm Identity
    </Text> */}
                  </TouchableOpacity>

                  {/* Delete Account */}
                  <View
                    style={{
                      borderBottomColor: border,
                      borderBottomWidth: 1,
                      paddingBottom: 15,
                      marginBottom: 15,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: isMd
                          ? "row"
                          : "column",
                        justifyContent: "space-between",
                        alignItems: isMd
                          ? "center"
                          : "flex-start",
                      }}
                    >
                      <View
                        style={{
                          maxWidth: "75%",
                        }}
                      >
                        <Text style={styles.subtitle}>
                          Delete account
                        </Text>
                        <Text
                          style={[styles.description]}
                        >
                          Deleting your account will
                          remove all your data from
                          our servers.
                        </Text>
                      </View>
                      <TouchableOpacity
                        onPress={() => {
                          confirmDelete &&
                            confirmDeleteText ===
                            "DELETE ACCOUNT"
                            ? deleteAccount()
                            : setConfirmDelete(
                              true
                            );
                        }}
                        style={{
                          marginTop: 15,
                        }}
                      >
                        <Text
                          style={{
                            color: "#FF0000",
                            fontSize: 16,
                            fontFamily:
                              defaultStyles.regularFont,
                            textAlign: "center",
                          }}
                        >
                          {confirmDelete
                            ? "Confirm delete"
                            : "Delete account"}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {/* Prompt to confirm delete by entering 'DELETE ACCOUNT' */}
                    {confirmDelete && (
                      <View
                        style={{
                          display: "flex",
                          flexDirection:
                            "column-reverse",
                          marginTop: 15,
                        }}
                      >
                        <Text
                          style={{
                            color: text,
                            fontSize: 12,
                            fontFamily:
                              defaultStyles.regularFont,
                          }}
                        >
                          Confirm by entering{" "}
                          <Text
                            style={{
                              color: "#FF0000",
                              fontStyle: "italic",
                              fontFamily:
                                defaultStyles.boldFont,
                            }}
                          >
                            DELETE ACCOUNT
                          </Text>
                          . This action cannot be
                          undone.
                        </Text>
                        <TextInput
                          style={{
                            height: 40,
                            width: 200,
                            border:
                              "1px solid" +
                              border,
                            borderRadius: 10,
                            color: text,
                            paddingLeft: 10,
                            marginVertical: 10,
                          }}
                          onChangeText={(text) =>
                            setConfirmDeleteText(
                              text
                            )
                          }
                          value={confirmDeleteText}
                          placeholder="DELETE ACCOUNT"
                          placeholderTextColor={text}
                        />
                      </View>
                    )}
                  </View>

                  {/* Notifications Section  */}
                  {/* <Text style={{  fontSize: 28, fontFamily: defaultStyles.regularFont, color: text, marginVertical: 15}}>Notification Center </Text>
                                    <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start'}}>
                                        <View>
                                            <Text style={styles.subtitle}>New Message Notifications</Text>
                                            <Text style={styles.description}>
                                                Receive notifications when you receive a new message.
                                            </Text>
                                    </View>
                                        <Switch
                                            style={{marginTop: !isMd && 15}}
                                            trackColor={{ false: "#767577", true: primary }}
                                            thumbColor={notificationPreferences.newMessage ? primary : "#f4f3f4"}
                                            ios_backgroundColor={primary}
                                            onValueChange={() => {
                                                toggleNotificationPreferences('newMessage');
                                                customAlert.success('[Account] New message preference updated!');
                                            }}
                                            value={notificationPreferences.newMessage}
                                        />
                                    </View> */}
                </View>

                {/* Right side */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: !isLg ? "row" : "column",
                    width: isLg ? 330 : "100%",
                  }}
                >
                  <View
                    style={{
                      border: "1px solid " + border,
                      borderRadius: defaultStyles.radius,
                      padding: 15,
                      width: isLg ? 330 : "100%",
                    }}
                  >
                    {/* Icon  */}
                    <AntDesign
                      name="eyeo"
                      size={32}
                      color={text}
                      style={{ marginBottom: 10 }}
                    />

                    {/* Title */}
                    <Text style={styles.infoSubtitle}>
                      Why is some of my information not
                      visible or displayed?
                    </Text>
                    {/* Description */}
                    <Text style={styles.infoDescription}>
                      We are hiding some of your
                      information to protect your privacy.
                    </Text>
                    {/* Divider  */}
                    <View
                      style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: border,
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                    ></View>

                    {/* Icon  */}

                    <AntDesign
                      name="user"
                      size={32}
                      color={text}
                      style={{ marginBottom: 10 }}
                    />

                    {/* Title */}
                    <Text style={styles.infoSubtitle}>
                      Where can I edit my public profile?
                    </Text>
                    {/* Description */}
                    <Text style={styles.infoDescription}>
                      You can edit your public profile
                      through the profile screen, you can
                      navigate there by clicking the
                      button below. Only tutors have
                      public profiles to edit at this
                      time.
                    </Text>

                    {/* Link to learn about super tutor program */}
                    <Link
                      to={
                        isTutor
                          ? `/tutors/${URL}`
                          : `/students/${URL}`
                      }
                      style={styles.infoLink}
                    >
                      Visit profile
                    </Link>

                    {/* Divider  */}
                    <View
                      style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: border,
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                    ></View>

                    {/* Icon  */}
                    <AntDesign
                      name="Safety"
                      size={32}
                      color={text}
                      style={{ marginBottom: 10 }}
                    />

                    {/* Title */}
                    <Text style={styles.infoSubtitle}>
                      Where is my information stored?
                    </Text>
                    {/* Description */}
                    <Text style={styles.infoDescription}>
                      We store your information on our
                      secure servers. We do not share your
                      information with anyone.
                    </Text>

                    {/* Link to learn about super tutor program */}
                    <A href="" style={styles.infoLink}>
                      Learn more
                    </A>
                  </View>
                  {isMd && <View style={{ flex: 1 }}></View>}
                </View>
              </View>
            </View>
            {/* Last updated in bottom left corner */}
            {/* <Text style={{color: primary, fontSize: 12, fontFamily: defaultStyles.regularFont, position: 'absolute', bottom: 0, left: 0, marginBottom: 15, padding: isSm ? 45: 15,}}>Last updated: {lastUpdated}</Text> */}
          </View>
          {/* Footer  */}
          {isSm ? <Footer></Footer> : null}
        </ScrollView>
      )}
    </SafeAreaView>
  );
};