import React from 'react'
import {
    Chip,
    Avatar,
    ChipProps,
    Typography,
    IconButton,
    Box,
} from '@mui/material'
import { languageIcons } from '../../../config/languages'
import AntDesign from '@expo/vector-icons/AntDesign'
import { useTheme } from '@mui/material/styles'
import { useColorsContext } from '@utils/styles'
import { subjectIcons } from '@config/subjects'
import TutredBaseChip from '../TutredBaseChip'

interface SubjectChipProps extends ChipProps {
    subject: string
}

const SubjectChip: React.FC<SubjectChipProps> = ({
    subject,
    onDelete,
    ...rest
}) => {
    const theme = useTheme()
    const { darkMode } = useColorsContext()

    return (
        <TutredBaseChip
            sx={{
                pl: 1,
                borderRadius: 8,
                borderColor: darkMode
                    ? theme.palette.grey[400]
                    : theme.palette.grey[800],
                borderWidth: 2,
                fontFamily: 'Poppins_500Medium',
                fontSize: 16,
            }}
            color="primary"
            icon={
                <Box
                    sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: '#FFF',
                        WebkitMask: `url(${subjectIcons[subject]}) no-repeat center `,
                        mask: `url(${subjectIcons[subject]}) no-repeat center `,
                        maskSize: 'contain',
                        WebkitMaskSize: 'contain',
                        mr: 2,
                    }}
                />
            }
            label={subject}
            onDelete={onDelete}
            {...rest}
        />
    )
}

export default SubjectChip
