import { Box, Card, Link, Paper, Typography } from '@mui/material'
import { OnboardingStep } from '..'
import OnboardingProgressItem from './OnboardingProgressItem'
import AntDesign from '@expo/vector-icons/AntDesign'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import { useColorsContext } from '@utils/styles'
import TakePhotoIcon from '@icons/TakePhotoIcon'
import YourDetailsIcon from '@icons/YourDetailsIcon'
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'
import BackgroundIcon from '@icons/BackgroundIcon'
import AboutMeIcon from '@icons/AboutMeIcon'
import { Image, useWindowDimensions } from 'react-native'

import {} from '@mui/material'
import TutredIcon from '@icons/TutredIcon'

interface OnboardingProgressProps {
    step: OnboardingStep
    setVisible: (visible: boolean) => void
}

const OnboardingProgress: React.FC<OnboardingProgressProps> = ({
    step,
    setVisible,
}) => {
    const { text, primaryLight, textInverse } = useColorsContext()
    const { height } = useWindowDimensions()

    return (
        <Card
            sx={{
                height: height * 0.8 - 16,
                padding: 1,
                borderRadius: 2,
                flexDirection: 'column',
                display: 'flex',
                backgroundColor: primaryLight,
                minHeight: 600,
            }}
            elevation={0}
        >
            {/* <Image
                style={{
                    width: 120,
                    height: 65,
                    alignSelf: 'center',
                    resizeMode: 'contain',
                }}
                source={{ uri: tutredLogo }}
            /> */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    mb: 2,
                    mt: 2,
                }}
            >
                <TutredIcon />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <OnboardingProgressItem
                        icon={<YourDetailsIcon size={16} />}
                        title="Your Details"
                        subtitle="provide your address"
                        selectedStep={step}
                        step="welcome"
                        index={1}
                    />
                    <OnboardingProgressItem
                        icon={<Feather name="book" size={16} color={text} />}
                        title="Subjects"
                        subtitle="select what you want to teach"
                        selectedStep={step}
                        step="photo"
                        index={2}
                    />
                    <OnboardingProgressItem
                        icon={<BackgroundIcon size={16} />}
                        title="Background"
                        subtitle="tell us about your qualifications"
                        selectedStep={step}
                        step="subjects"
                        index={3}
                    />
                    <OnboardingProgressItem
                        icon={<Feather name="user" size={16} color={text} />}
                        title="Profile"
                        subtitle="Enter your bio, pricing"
                        selectedStep={step}
                        step="availability"
                        index={4}
                    />
                    <OnboardingProgressItem
                        icon={<TakePhotoIcon size={16} />}
                        title="Profile photo"
                        subtitle="upload a photo of yourself"
                        selectedStep={step}
                        step="details"
                        index={5}
                    />

                    <OnboardingProgressItem
                        icon={
                            <AntDesign name="calendar" size={16} color={text} />
                        }
                        title="Availability"
                        subtitle="pick when you're available"
                        selectedStep={step}
                        step="background"
                        index={6}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 12,
                    cursor: 'pointer',
                }}
                onClick={() => setVisible(false)}
            >
                <MaterialIcons
                    name="arrow-back"
                    size={20}
                    color={text}
                    style={{ marginRight: 8 }}
                />
                <Typography variant="subtitle2" color={text}>
                    Back to Home
                </Typography>
            </Box>
        </Card>
    )
}

export default OnboardingProgress
