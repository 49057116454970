import {
    Box,
    Container,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React, { useEffect } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import AntDesign from '@expo/vector-icons/AntDesign'
import { useColorsContext } from '@utils/styles'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import SimpleLineIcons from '@expo/vector-icons/SimpleLineIcons'
import Feather from '@expo/vector-icons/Feather'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import Schedule from './Schedule'
import Education from './Education'
import ExamPrep from './ExamPrep'
import Instabook from './Instabook'
import Reviews from './Reviews'
import { useNavigation, useRoute } from '@react-navigation/native'
import ExamPrepIcon from '@icons/ExamPrepIcon'

interface Props {
    tutor: any
}

const Sections: React.FC<Props> = ({ tutor }) => {
    const [value, setValue] = React.useState<string>('schedule')
    const route = useRoute()
    const navigation = useNavigation()
    const { tab } = route.params || ''
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        navigation.setParams({ tab: newValue })
        setValue(newValue)
    }

    useEffect(() => {
        if (tab) {
            setValue(tab)
        }
    }, [tab])

    const { primary } = useColorsContext()

    return (
        <Container sx={{ width: '100%', marginTop: 3, padding: 0 }}>
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant={isMd ? 'fullWidth' : 'scrollable'}
                    color={primary}
                >
                    <Tab
                        label="Schedule"
                        iconPosition="start"
                        value={'schedule'}
                        icon={
                            <AntDesign
                                name="calendar"
                                size={20}
                                color={value === 'schedule' ? primary : 'black'}
                                style={{ marginRight: 8 }}
                            />
                        }
                        sx={{ fontSize: 16 }}
                    />
                    <Tab
                        label="Education"
                        iconPosition="start"
                        value={'education'}
                        icon={
                            <SimpleLineIcons
                                name="graduation"
                                size={20}
                                color={
                                    value === 'education' ? primary : 'black'
                                }
                                style={{ marginRight: 8 }}
                            />
                        }
                        sx={{ fontSize: 16 }}
                    />
                    <Tab
                        label="Exam Prep"
                        iconPosition="start"
                        value={'examPrep'}
                        icon={<ExamPrepIcon style={{ marginRight: 8 }} />}
                        sx={{ fontSize: 16 }}
                    />
                    <Tab
                        label="Instabook"
                        iconPosition="start"
                        value={'instabook'}
                        icon={
                            <MaterialCommunityIcons
                                name="lightning-bolt-outline"
                                size={24}
                                color={
                                    value === 'instabook' ? primary : 'black'
                                }
                                style={{ marginRight: 8 }}
                            />
                        }
                        sx={{ fontSize: 16 }}
                    />
                    <Tab
                        label="Reviews"
                        iconPosition="start"
                        value={'reviews'}
                        icon={
                            <FontAwesome
                                name="star-o"
                                size={24}
                                color={value === 'reviews' ? primary : 'black'}
                                style={{ marginRight: 8 }}
                            />
                        }
                        sx={{ fontSize: 16 }}
                    />
                </Tabs>
            </Box>
            <Box sx={{ padding: 2 }}>
                {value === 'schedule' && <Schedule tutor={tutor} />}
                {value === 'education' && <Education degrees={tutor.degrees} />}
                {value === 'examPrep' && <ExamPrep exams={tutor?.examTutor} />}
                {value === 'instabook' && <Instabook tutor={tutor} />}
                {value === 'reviews' && <Reviews tutor={tutor} />}
            </Box>
        </Container>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default Sections
