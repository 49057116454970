import React from 'react'
import { Box, Divider } from '@mui/material'
import { useNotificationsContext } from '../../../contexts/notificationsContext'
import NotificationItem from '../NotificationItem'
import { Notification } from 'dataTypes'
import { FlatList } from 'react-native'
import SleepyDino from '@icons/sleepy-dino'
import EmptyNotifications from '../EmptyNotifications'
import NotificationListHeader from '../NotificationListHeader'
import { useColorsContext } from '@utils/styles'

const UnreadNotifications = ({
    showHeader = true,
    dense = false,
    handleNavigateToNotifications,
}) => {
    const { unreadNotifications } = useNotificationsContext()
    const { primary } = useColorsContext()

    const pendingNotifications = unreadNotifications.filter(
        (notification: Notification) => notification.status === 'pending'
    )

    const filteredUnread = unreadNotifications.filter(
        (notification: Notification) => notification.status !== 'pending'
    )

    return (
        <Box sx={{ py: dense ? 0 : 4 }}>
            {pendingNotifications.length > 0 && (
                <>
                    <FlatList
                        ListHeaderComponent={
                            showHeader && (
                                <NotificationListHeader
                                    showMarkAllAsRead={false}
                                    titleColor={primary}
                                    title={`${pendingNotifications.length} pending ${pendingNotifications.length === 1 ? 'request' : 'requests'}`}
                                />
                            )
                        }
                        data={pendingNotifications}
                        renderItem={({ item, index }) => (
                            <NotificationItem
                                notification={item}
                                lastNotification={
                                    pendingNotifications[index - 1]
                                }
                                index={index}
                                type="pending"
                                dense={dense}
                            />
                        )}
                    />
                    <Divider sx={{ my: 2 }} />
                </>
            )}

            <FlatList
                ListHeaderComponent={
                    <>
                        {filteredUnread.length > 0 && (
                            <NotificationListHeader
                                showMarkAllAsRead={true}
                                title={`${filteredUnread.length} unread ${filteredUnread.length === 1 ? 'notification' : 'notifications'}`}
                            />
                        )}
                    </>
                }
                data={filteredUnread}
                renderItem={({ item, index }) => (
                    <NotificationItem
                        notification={item}
                        lastNotification={filteredUnread[index - 1]}
                        index={index}
                        type="unread"
                        dense={dense}
                    />
                )}
                ListEmptyComponent={
                    <>
                        {filteredUnread.length === 0 &&
                            pendingNotifications.length === 0 && (
                                <EmptyNotifications
                                    title="Nothing new here..."
                                    dense={dense}
                                    handleNavigateToNotifications={
                                        handleNavigateToNotifications
                                    }
                                />
                            )}
                    </>
                }
            />
        </Box>
    )
}

export default UnreadNotifications
