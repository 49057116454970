import React from 'react'
import { Chip, ChipProps, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useColorsContext } from '@utils/styles'
import AntDesign from '@expo/vector-icons/AntDesign'

interface TutredBaseChipProps extends Omit<ChipProps, 'icon'> {
    backgroundColor?: string
}

const TutredBaseChip: React.FC<TutredBaseChipProps> = ({
    label,
    variant = 'filled',
    backgroundColor,
    ...rest
}) => {
    const theme = useTheme()
    const { darkMode } = useColorsContext()

    return (
        <Chip
            variant={variant}
            label={label}
            sx={{
                borderRadius: 8,
                fontFamily: 'Poppins_500Medium',
                fontSize: 16,
                borderWidth: variant === 'outlined' ? 2 : 0,
                borderColor:
                    variant === 'outlined'
                        ? darkMode
                            ? theme.palette.grey[400]
                            : theme.palette.grey[900]
                        : 'none',
                backgroundColor:
                    variant == 'outlined'
                        ? theme.palette.background
                        : backgroundColor || theme.palette.primary.main,
                color:
                    variant == 'outlined' && !darkMode
                        ? theme.palette.grey[900]
                        : '#fff',
            }}
            deleteIcon={
                <IconButton>
                    <AntDesign
                        name="close"
                        size={18}
                        color={
                            variant !== 'filled' && !darkMode
                                ? theme.palette.grey[800]
                                : theme.palette.grey[100]
                        }
                    />
                </IconButton>
            }
            {...rest}
        />
    )
}

export default TutredBaseChip
