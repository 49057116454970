import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useColorsContext } from '@utils/styles';

const CalendarIcon = ({ size = 25, color }) => {
    const { text } = useColorsContext();

    return (
        <Svg width={size} height={size * 69 / 63} viewBox="0 0 63 69" fill="none">
            <Path
                d="M53.375 9.5H9.625C6.17322 9.5 3.375 12.2982 3.375 15.75V59.5C3.375 62.9518 6.17322 65.75 9.625 65.75H53.375C56.8268 65.75 59.625 62.9518 59.625 59.5V15.75C59.625 12.2982 56.8268 9.5 53.375 9.5Z"
                stroke={color || text}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M44 3.25V15.75"
                stroke={color || text}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M19 3.25V15.75"
                stroke={color || text}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M3.375 28.25H59.625"
                stroke={color || text}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>

    );
};

export default CalendarIcon;
