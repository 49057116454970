import { useColorsContext } from '@utils/styles';
import Svg, { Path } from 'react-native-svg';

const SchoolIcon = ({ size = 30, color }) => {
    const { text } = useColorsContext();

    return (
        <Svg width={size} height={size} viewBox="0 0 30 30" fill="none">
            <Path d="M28.6364 12.2727H24.5455C24.1364 12.2727 23.8636 12.5454 23.8636 12.9545C23.8636 13.3636 24.1364 13.6363 24.5455 13.6363H27.9546V25.9091H18.4091V22.5C18.4091 20.5909 16.9091 19.0909 15 19.0909C13.0909 19.0909 11.5909 20.5909 11.5909 22.5V25.9091H2.04546V13.6363H5.45455C5.86364 13.6363 6.13637 13.3636 6.13637 12.9545C6.13637 12.5454 5.86364 12.2727 5.45455 12.2727H1.36364C0.954551 12.2727 0.681824 12.5454 0.681824 12.9545V26.5909C0.681824 27 0.954551 27.2727 1.36364 27.2727H28.6364C29.0455 27.2727 29.3182 27 29.3182 26.5909V12.9545C29.3182 12.5454 29.0455 12.2727 28.6364 12.2727ZM12.9546 22.5C12.9546 21.3409 13.8409 20.4545 15 20.4545C16.1591 20.4545 17.0455 21.3409 17.0455 22.5V25.9091H12.9546V22.5Z" fill={color || text} />
            <Path d="M7.5 10.9091V23.8637C7.5 24.2727 7.77273 24.5455 8.18182 24.5455C8.59091 24.5455 8.86364 24.2727 8.86364 23.8637V10.9091C8.86364 10.5 8.59091 10.2273 8.18182 10.2273C7.77273 10.2273 7.5 10.5 7.5 10.9091Z" fill={color || text} />
            <Path d="M21.1364 10.9091V23.8637C21.1364 24.2727 21.4091 24.5455 21.8182 24.5455C22.2273 24.5455 22.5 24.2727 22.5 23.8637V10.9091C22.5 10.5 22.2273 10.2273 21.8182 10.2273C21.4091 10.2273 21.1364 10.5 21.1364 10.9091Z" fill={color || text} />
            <Path d="M7.02273 9.47727L15 6.88636L22.9773 9.54545C23.0455 9.54545 23.1136 9.61364 23.1818 9.61364C23.4545 9.61364 23.7273 9.40909 23.7955 9.13636C23.9318 8.79545 23.7273 8.38636 23.3864 8.25L15.6818 5.65909V1.36364H18L17.7955 1.77273C17.7273 1.97727 17.7273 2.18182 17.7955 2.38636L18 2.72727H17.0455C16.6364 2.72727 16.3636 3 16.3636 3.40909C16.3636 3.81818 16.6364 4.09091 17.0455 4.09091H19.0909C19.2955 4.09091 19.5682 3.95455 19.7045 3.75C19.8409 3.54545 19.8409 3.27273 19.7045 3.06818L19.1591 2.04545L19.7045 0.954545C19.8409 0.75 19.7727 0.477273 19.7045 0.272727C19.6364 0.0681818 19.2955 0 19.0909 0H15C14.5909 0 14.3182 0.272727 14.3182 0.681818V5.65909L6.61364 8.25C6.27273 8.38636 6.06818 8.72727 6.20455 9.13636C6.27273 9.40909 6.68182 9.61364 7.02273 9.47727Z" fill={color || text} />
            <Path d="M12.2727 11.591C12.2727 11.1819 12 10.9092 11.5909 10.9092C11.1818 10.9092 10.9091 11.1819 10.9091 11.591V12.9546C10.9091 13.3637 11.1818 13.6365 11.5909 13.6365C12 13.6365 12.2727 13.3637 12.2727 12.9546V11.591Z" fill={color || text} />
            <Path d="M15.6818 11.591C15.6818 11.1819 15.4091 10.9092 15 10.9092C14.5909 10.9092 14.3182 11.1819 14.3182 11.591V12.9546C14.3182 13.3637 14.5909 13.6365 15 13.6365C15.4091 13.6365 15.6818 13.3637 15.6818 12.9546V11.591Z" fill={color || text} />
            <Path d="M19.0909 11.591C19.0909 11.1819 18.8182 10.9092 18.4091 10.9092C18 10.9092 17.7273 11.1819 17.7273 11.591V12.9546C17.7273 13.3637 18 13.6365 18.4091 13.6365C18.8182 13.6365 19.0909 13.3637 19.0909 12.9546V11.591Z" fill={color || text} />
            <Path d="M11.5909 15.6819C11.1818 15.6819 10.9091 15.9546 10.9091 16.3637V17.7273C10.9091 18.1364 11.1818 18.4092 11.5909 18.4092C12 18.4092 12.2727 18.1364 12.2727 17.7273V16.3637C12.2727 15.9546 12 15.6819 11.5909 15.6819Z" fill={color || text} />
            <Path d="M15 15.6819C14.5909 15.6819 14.3182 15.9546 14.3182 16.3637V17.7273C14.3182 18.1364 14.5909 18.4092 15 18.4092C15.4091 18.4092 15.6818 18.1364 15.6818 17.7273V16.3637C15.6818 15.9546 15.4091 15.6819 15 15.6819Z" fill={color || text} />
            <Path d="M18.4091 15.6819C18 15.6819 17.7273 15.9546 17.7273 16.3637V17.7273C17.7273 18.1364 18 18.4092 18.4091 18.4092C18.8182 18.4092 19.0909 18.1364 19.0909 17.7273V16.3637C19.0909 15.9546 18.8182 15.6819 18.4091 15.6819Z" fill={color || text} />
            <Path d="M25.2273 18.4092C25.6364 18.4092 25.9091 18.1364 25.9091 17.7273V16.3637C25.9091 15.9546 25.6364 15.6819 25.2273 15.6819C24.8182 15.6819 24.5455 15.9546 24.5455 16.3637V17.7273C24.5455 18.1364 24.8182 18.4092 25.2273 18.4092Z" fill={color || text} />
            <Path d="M5.45455 16.3637C5.45455 15.9546 5.18182 15.6819 4.77273 15.6819C4.36364 15.6819 4.09091 15.9546 4.09091 16.3637V17.7273C4.09091 18.1364 4.36364 18.4092 4.77273 18.4092C5.18182 18.4092 5.45455 18.1364 5.45455 17.7273V16.3637Z" fill={color || text} />
            <Path d="M24.5455 23.1819C24.5455 23.591 24.8182 23.8637 25.2273 23.8637C25.6364 23.8637 25.9091 23.591 25.9091 23.1819V21.8183C25.9091 21.4092 25.6364 21.1365 25.2273 21.1365C24.8182 21.1365 24.5455 21.4092 24.5455 21.8183V23.1819Z" fill={color || text} />
            <Path d="M4.77273 21.1365C4.36364 21.1365 4.09091 21.4092 4.09091 21.8183V23.1819C4.09091 23.591 4.36364 23.8637 4.77273 23.8637C5.18182 23.8637 5.45455 23.591 5.45455 23.1819V21.8183C5.45455 21.4092 5.18182 21.1365 4.77273 21.1365Z" fill={color || text} />
            <Path d="M25.9091 28.6365H4.09091C3.68182 28.6365 3.40909 28.9092 3.40909 29.3183C3.40909 29.7274 3.68182 30.0001 4.09091 30.0001H25.9091C26.3182 30.0001 26.5909 29.7274 26.5909 29.3183C26.5909 28.9092 26.3182 28.6365 25.9091 28.6365Z" fill={color || text} />
        </Svg>
    );
};

export default SchoolIcon;
