const exams = [
    // Elementary School Exams
    { value: 'eqao', label: 'EQAO (Elementary)' },

    // High School Exams
    { value: 'osslt', label: 'OSSLT (High School)' },
    { value: 'ontario_gr11_math', label: 'Ontario Grade 11 Math' },
    { value: 'ontario_gr12_math', label: 'Ontario Grade 12 Math' },
    { value: 'bc_gr12_english', label: 'BC Grade 12 English' },
    { value: 'alberta_diploma', label: 'Alberta Diploma Exams' },
    { value: 'math_30', label: 'Math 30 (Alberta)' },
    { value: 'chemistry_30', label: 'Chemistry 30 (Alberta)' },
    { value: 'biology_30', label: 'Biology 30 (Alberta)' },
    { value: 'physics_30', label: 'Physics 30 (Alberta)' },

    // Advanced Placement (AP) Exams
    { value: 'ap_calculus_ab', label: 'AP Calculus AB' },
    { value: 'ap_calculus_bc', label: 'AP Calculus BC' },
    { value: 'ap_english_lang', label: 'AP English Language and Composition' },
    { value: 'ap_english_lit', label: 'AP English Literature and Composition' },
    { value: 'ap_biology', label: 'AP Biology' },
    { value: 'ap_chemistry', label: 'AP Chemistry' },
    { value: 'ap_physics_1', label: 'AP Physics 1' },
    { value: 'ap_physics_2', label: 'AP Physics 2' },
    { value: 'ap_physics_c_mech', label: 'AP Physics C: Mechanics' },
    { value: 'ap_physics_c_em', label: 'AP Physics C: Electricity and Magnetism' },
    { value: 'ap_us_history', label: 'AP United States History' },
    { value: 'ap_world_history', label: 'AP World History' },
    { value: 'ap_macroeconomics', label: 'AP Macroeconomics' },
    { value: 'ap_microeconomics', label: 'AP Microeconomics' },

    // International Baccalaureate (IB) Exams
    { value: 'ib_math_sl', label: 'IB Mathematics SL' },
    { value: 'ib_math_hl', label: 'IB Mathematics HL' },
    { value: 'ib_bio_sl', label: 'IB Biology SL' },
    { value: 'ib_bio_hl', label: 'IB Biology HL' },
    { value: 'ib_chem_sl', label: 'IB Chemistry SL' },
    { value: 'ib_chem_hl', label: 'IB Chemistry HL' },
    { value: 'ib_physics_sl', label: 'IB Physics SL' },
    { value: 'ib_physics_hl', label: 'IB Physics HL' },
    { value: 'ib_english_a1', label: 'IB English A1' },
    { value: 'ib_history', label: 'IB History' },

    // College/University Admission Exams
    { value: 'sat', label: 'SAT' },
    { value: 'act', label: 'ACT' },

    // Graduate Level Exams
    { value: 'lsat', label: 'LSAT' },
    { value: 'gre', label: 'GRE' },
    { value: 'gmat', label: 'GMAT' },
    { value: 'mcat', label: 'MCAT' },

    // Language Proficiency Exams
    { value: 'ielts', label: 'IELTS' },
    { value: 'toefl', label: 'TOEFL' },
    { value: 'celpip', label: 'CELPIP' },
    { value: 'clb', label: 'CLB (Canadian Language Benchmarks)' },

    // Professional Certification Exams
    { value: 'cfa', label: 'CFA (Chartered Financial Analyst)' },
    { value: 'bar_exam', label: 'Bar Exam' },
    { value: 'cpa', label: 'CPA (Chartered Professional Accountant)' },
    { value: 'pe_exam', label: 'PE (Professional Engineer)' },

    // Medical and Healthcare Certification Exams
    { value: 'usmle', label: 'USMLE (United States Medical Licensing Examination)' },
    { value: 'nclex', label: 'NCLEX (National Council Licensure Examination)' },
    { value: 'cfpc', label: 'CFPC (College of Family Physicians of Canada)' },

    // Other Exams
    { value: 'cfe', label: 'CFE (Common Final Examination for CPAs)' },
    { value: 'leat', label: 'LEAT (Law Enforcement Aptitude Test)' },
    { value: 'firefighter_exam', label: 'Firefighter Aptitude Test' },
];

module.exports = {
    exams,
};
