import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";

// Tutor Review component
export const TutorSession = ({ session, user }) => {
	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		reviewImg: {
			position: "block",
			marginVertical: 2,
			marginRight: 15,
			borderRadius: 100,
			width: 50,
			height: 50,
			opacity: 1,
		},
		reviewTitle: {
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},

		reviewDate: {
			fontFamily: defaultStyles.regularFont,
			fontSize: 16,
			color: textVariant,
		},
		reviewBody: {
			fontFamily: defaultStyles.regularFont,
			fontSize: 16,
			color: text,
			minHeight: 20,
		},
	});

	return (
		<View
			style={{
				borderBottomWidth: 1,
				borderBottomColor: border,
				width: "100%",
				display: "flex",
				flexDirection: "row",
				paddingVertical: 15,
				flex: 1,
				// minHeight: 110,
			}}
		>
			<Image
				style={styles.reviewImg}
				source={{
					uri: user.picture
						? user.picture
						: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
				}}
			/>

			<View style={{ flex: 1, display: "flex", flexDirection: "column" }}>
				<Text style={styles.reviewTitle}>
					{session.subject ? session.subject : "InstaBook"} Session
				</Text>

				<Text style={styles.reviewDate}>
					{session.createdAt.toDate().toLocaleDateString()}
				</Text>

				<Text numberOfLines={3} style={styles.reviewBody}>
					{session.lengthInMinutes > 60
						? `${Math.round(session.lengthInMinutes / 60).toFixed(
								0
						  )} hours`
						: `${Math.round(session.lengthInMinutes).toFixed(
								0
						  )} minutes`}
				</Text>
			</View>
		</View>
	);
};
