import { useColorsContext } from "@utils/styles"
import * as React from "react"
import Svg, { Path, G, } from "react-native-svg"

const SpecialtyIcon = ({
  size = 20,
}) => {
  const { text, textInverse } = useColorsContext();
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <G clipPath="url(#clip0_6_25)">
        <Path d="M5.99999 6.71999H1.67999C1.28231 6.71999 0.959991 6.39767 0.959991 5.99999V1.67999C0.959991 1.28231 1.28231 0.959991 1.67999 0.959991H5.99999C6.39767 0.959991 6.71999 1.28231 6.71999 1.67999V5.99999C6.71999 6.39767 6.39767 6.71999 5.99999 6.71999Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <Path d="M14.16 6.71999H9.84C9.44232 6.71999 9.12 6.39767 9.12 5.99999V1.67999C9.12 1.28231 9.44232 0.959991 9.84 0.959991H14.16C14.5577 0.959991 14.88 1.28231 14.88 1.67999V5.99999C14.88 6.39767 14.5577 6.71999 14.16 6.71999Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <Path d="M5.99999 14.88H1.67999C1.28231 14.88 0.959991 14.5577 0.959991 14.16V9.84C0.959991 9.44232 1.28231 9.12 1.67999 9.12H5.99999C6.39767 9.12 6.71999 9.44232 6.71999 9.84V14.16C6.71999 14.5577 6.39767 14.88 5.99999 14.88Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <Path d="M22.32 14.88H18C17.6023 14.88 17.28 14.5577 17.28 14.16V9.84C17.28 9.44232 17.6023 9.12 18 9.12H22.32C22.7177 9.12 23.04 9.44232 23.04 9.84V14.16C23.04 14.5577 22.7177 14.88 22.32 14.88Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <Path d="M14.16 14.88H9.84C9.44232 14.88 9.12 14.5577 9.12 14.16V9.84C9.12 9.44232 9.44232 9.12 9.84 9.12H14.16C14.5577 9.12 14.88 9.44232 14.88 9.84V14.16C14.88 14.5577 14.5577 14.88 14.16 14.88Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <Path d="M5.99999 23.04H1.67999C1.28231 23.04 0.959991 22.7177 0.959991 22.32V18C0.959991 17.6023 1.28231 17.28 1.67999 17.28H5.99999C6.39767 17.28 6.71999 17.6023 6.71999 18V22.32C6.71999 22.7177 6.39767 23.04 5.99999 23.04Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <Path d="M22.32 23.04H18C17.6023 23.04 17.28 22.7177 17.28 22.32V18C17.28 17.6023 17.6023 17.28 18 17.28H22.32C22.7177 17.28 23.04 17.6023 23.04 18V22.32C23.04 22.7177 22.7177 23.04 22.32 23.04Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <Path d="M14.16 23.04H9.84C9.44232 23.04 9.12 22.7177 9.12 22.32V18C9.12 17.6023 9.44232 17.28 9.84 17.28H14.16C14.5577 17.28 14.88 17.6023 14.88 18V22.32C14.88 22.7177 14.5577 23.04 14.16 23.04Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <Path d="M20.16 6.71999C21.7506 6.71999 23.04 5.43057 23.04 3.83999C23.04 2.24941 21.7506 0.959991 20.16 0.959991C18.5694 0.959991 17.28 2.24941 17.28 3.83999C17.28 5.43057 18.5694 6.71999 20.16 6.71999Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      </G>
    </Svg>
  )
}


export default SpecialtyIcon
