const countries = [
    "Canada",
    "United States",
]


const provinces = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon", 
]

const cities = {
    "Alberta": [
        "Airdrie",
        "Beaumont",
        "Brooks",
        "Calgary",
        "Camrose",
        "Chestermere",
        "Cold Lake",
        "Edmonton",
        "Fort Saskatchewan",
        "Grande Prairie",
        "Lacombe",
        "Leduc",
        "Lethbridge",
        "Lloydminster",
        "Medicine Hat",
        "Red Deer",
        "Spruce Grove",
        "St. Albert",
        "Wetaskiwin"
    ],
    "British Columbia": [
        "Abbotsford",
        "Armstrong",
        "Burnaby",
        "Campbell River",
        "Castlegar",
        "Chilliwack",
        "Colwood",
        "Coquitlam",
        "Courtenay",
        "Cranbrook",
        "Dawson Creek",
        "Delta",
        "Duncan",
        "Enderby",
        "Fernie",
        "Fort St. John",
        "Grand Forks",
        "Greenwood",
        "Kamloops",
        "Kelowna",
        "Kimberley",
        "Langford",
        "Langley",
        "Maple Ridge",
        "Merritt",
        "Mission",
        "Nanaimo",
        "Nelson",
        "New Westminster",
        "North Vancouver",
        "Parksville",
        "Penticton",
        "Pitt Meadows",
        "Port Alberni",
        "Port Coquitlam",
        "Port Moody",
        "Powell River",
        "Prince George",
        "Prince Rupert",
        "Quesnel",
        "Revelstoke",
        "Richmond",
        "Rossland",
        "Salmon Arm",
        "Surrey",
        "Terrace",
        "Trail",
        "Vancouver[a]",
        "Vernon",
        "Victoria[b]",
        "West Kelowna",
        "White Rock",
        "Williams Lake"
    ],      
    "Manitoba": [  "Brandon",  "Dauphin",  "Flin Flon",  "Morden",  "Portage la Prairie",  "Selkirk",  "Steinbach",  "Thompson",  "Winkler",  "Winnipeg"],
    "New Brunswick": [  "Bathurst",  "Campbellton",  "Dieppe",  "Edmundston",  "Fredericton",  "Miramichi",  "Moncton",  "Saint John"],
    "Newfoundland and Labrador": [
        "Corner Brook",
        "Mount Pearl",
        "St. John's"
    ],
    "Northwest Territories": [
        "Yellowknife"
    ],
    "Nova Scotia": [
        "Cape Breton",
        "Halifax",
        "Queens",
        "West Hants"
    ],
    "Nunavut": ["Iqaluit"],
    "Ontario": [  "Barrie",  "Belleville",  "Brampton",  "Brant",  "Brantford",  "Brockville",  "Burlington",  "Cambridge",  "Clarence-Rockland",  "Cornwall",  "Dryden",  "Elliot Lake",  "Greater Sudbury",  "Guelph",  "Haldimand County",  "Hamilton",  "Kawartha Lakes",  "Kenora",  "Kingston",  "Kitchener",  "London",  "Markham",  "Mississauga",  "Niagara Falls",  "Norfolk County",  "North Bay",  "Orillia",  "Oshawa",  "Ottawa",  "Owen Sound",  "Pembroke",  "Peterborough",  "Pickering",  "Port Colborne",  "Prince Edward County",  "Quinte West",  "Richmond Hill",  "Sarnia",  "Sault Ste. Marie",  "St. Catharines",  "St. Thomas",  "Stratford",  "Temiskaming Shores",  "Thorold",  "Thunder Bay",  "Timmins",  "Toronto",  "Vaughan",  "Waterloo",  "Welland",  "Windsor",  "Woodstock"],
    "Prince Edward Island": [
        "Charlottetown",
        "Summerside",
        "Queens"
    ],
    "Quebec": [  "Montreal",  "Québec",  "Ottawa - Gatineau (Quebec part)",  "Sherbrooke",  "Trois-Rivières",  "Chicoutimi - Jonquière",  "Saint-Jean-sur-Richelieu",  "Châteauguay",  "Drummondville",  "Saint-Jérôme"],
    "Saskatchewan": [  "Estevan",  "Flin Flon",  "Humboldt",  "Lloydminster",  "Martensville",  "Meadow Lake",  "Melfort",  "Melville[SK 9]",  "Moose Jaw",  "North Battleford",  "Prince Albert",  "Regina[SK 10]",  "Saskatoon[SK 11]",  "Swift Current",  "Warman[SK 12]",  "Weyburn",  "Yorkton"],
    "Yukon": ["Whitehorse"]
}



// United States Information

const statesUSA = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
]

const citiesUSA = {
    "Alabama": [
      "Birmingham",
      "Montgomery",
      "Mobile",
      "Huntsville",
      "Tuscaloosa",
      "Hoover",
      "Dothan",
      "Auburn",
      "Decatur",
      "Madison"
    ],
    "Alaska": [
      "Anchorage",
      "Fairbanks",
      "Juneau",
      "Sitka",
      "Ketchikan",
      "Wasilla",
      "Kenai",
      "Kodiak",
      "Bethel",
      "Palmer"
    ],
    "Arizona": [
      "Phoenix",
      "Tucson",
      "Mesa",
      "Chandler",
      "Glendale",
      "Scottsdale",
      "Gilbert",
      "Tempe",
      "Peoria",
      "Surprise"
    ],
    "Arkansas": [
      "Little Rock",
      "Fort Smith",
      "Fayetteville",
      "Springdale",
      "Jonesboro",
      "North Little Rock",
      "Conway",
      "Rogers",
      "Bentonville",
      "Pine Bluff"
    ],
    "California": [
      "Los Angeles",
      "San Diego",
      "San Jose",
      "San Francisco",
      "Fresno",
      "Sacramento",
      "Long Beach",
      "Oakland",
      "Bakersfield",
      "Anaheim"
    ],
    "Colorado": [
      "Denver",
      "Colorado Springs",
      "Aurora",
      "Fort Collins",
      "Lakewood",
      "Thornton",
      "Arvada",
      "Westminster",
      "Pueblo",
      "Centennial"
    ],
    "Connecticut": [
      "Bridgeport",
      "New Haven",
      "Stamford",
      "Hartford",
      "Waterbury",
      "Norwalk",
      "Danbury",
      "New Britain",
      "Meriden",
      "Bristol"
    ],
    "Delaware": [
      "Wilmington",
      "Dover",
      "Newark",
      "Middletown",
      "Smyrna",
      "Milford",
      "Seaford",
      "Georgetown",
      "Elsmere",
      "New Castle"
    ],
    "Florida": [
      "Jacksonville",
      "Miami",
      "Tampa",
      "Orlando",
      "St. Petersburg",
      "Hialeah",
      "Tallahassee",
      "Fort Lauderdale",
      "Port St. Lucie",
      "Cape Coral"
    ],
    "Georgia": [
      "Atlanta",
      "Augusta",
      "Columbus",
      "Macon",
      "Savannah",
      "Athens",
      "Sandy Springs",
      "Roswell",
      "Johns Creek",
      "Albany"
    ],
    "Hawaii": [
      "Honolulu",
      "East Honolulu",
      "Pearl City",
      "Hilo",
      "Kailua",
      "Waipahu",
      "Kaneohe",
      "Mililani Town",
      "Kahului",
      "Ewa Gentry"
    ],
    "Idaho": [
        "Boise",
        "Meridian",
        "Nampa",
        "Idaho Falls",
        "Pocatello",
        "Caldwell",
        "Coeur d'Alene",
        "Twin Falls",
        "Lewiston",
        "Post Falls"
      ],
      "Illinois": [
        "Chicago",
        "Aurora",
        "Rockford",
        "Joliet",
        "Naperville",
        "Springfield",
        "Peoria",
        "Elgin",
        "Waukegan",
        "Cicero"
      ],
      "Indiana": [
        "Indianapolis",
        "Fort Wayne",
        "Evansville",
        "South Bend",
        "Carmel",
        "Bloomington",
        "Fishers",
        "Hammond",
        "Gary",
        "Lafayette"
      ],
      "Iowa": [
        "Des Moines",
        "Cedar Rapids",
        "Davenport",
        "Sioux City",
        "Iowa City",
        "Waterloo",
        "Ames",
        "West Des Moines",
        "Council Bluffs",
        "Dubuque"
      ],
      "Kansas": [
        "Wichita",
        "Overland Park",
        "Kansas City",
        "Olathe",
        "Topeka",
        "Lawrence",
        "Shawnee",
        "Manhattan",
        "Lenexa",
        "Salina"
      ],
      "Kentucky": [
        "Louisville",
        "Lexington",
        "Bowling Green",
        "Owensboro",
        "Covington",
        "Hopkinsville",
        "Frankfort",
        "Henderson",
        "Richmond",
        "Jeffersontown"
      ],
      "Louisiana": [
        "New Orleans",
        "Baton Rouge",
        "Shreveport",
        "Lafayette",
        "Lake Charles",
        "Kenner",
        "Bossier City",
        "Monroe",
        "Alexandria",
        "Houma"
      ],
      "Maine": [
        "Portland",
        "Lewiston",
        "Bangor",
        "South Portland",
        "Auburn",
        "Biddeford",
        "Sanford",
        "Augusta",
        "Saco",
        "Westbrook"
      ],
      "Maryland": [
        "Baltimore",
        "Frederick",
        "Rockville",
        "Gaithersburg",
        "Bowie",
        "Hagerstown",
        "Annapolis",
        "College Park",
        "Salisbury",
        "Laurel"
      ],
      "Massachusetts": [
        "Boston",
        "Worcester",
        "Springfield",
        "Lowell",
        "Cambridge",
        "New Bedford",
        "Brockton",
        "Quincy",
        "Lynn",
        "Fall River"
      ],
      "Michigan": [
        "Detroit",
        "Grand Rapids",
        "Warren",
        "Sterling Heights",
        "Ann Arbor",
        "Lansing",
        "Flint",
        "Dearborn",
        "Livonia",
        "Westland"
      ],
      "Minnesota": [
        "Minneapolis",
        "St. Paul",
        "Rochester",
        "Duluth",
        "Bloomington",
        "Brooklyn Park",
        "Plymouth",
        "St. Cloud",
        "Eagan",
        "Woodbury"
      ],
      "Mississippi": [
        "Jackson",
        "Gulfport",
        "Southaven",
        "Hattiesburg",
        "Biloxi",
        "Meridian",
        "Tupelo",
        "Olive Branch",
        "Greenville",
        "Horn Lake"
      ],
      "Missouri": [
        "Kansas City",
        "St. Louis",
        "Springfield",
        "Independence",
        "Columbia",
        "Lee's Summit",
        "O'Fallon",
        "St. Joseph",
        "St. Charles",
        "Blue Springs"
      ],
      "Montana": [
        "Billings",
        "Missoula",
        "Great Falls",
        "Bozeman",
        "Butte",
        "Helena",
        "Kalispell",
        "Havre",
        "Anaconda",
        "Miles City"
      ],
      "Nebraska": [
        "Omaha",
        "Lincoln",
        "Bellevue",
        "Grand Island",
        "Kearney",
        "Fremont",
        "Hastings",
        "Norfolk",
        "North Platte",
        "Columbus"
      ],
      "Nevada": [
        "Las Vegas",
        "Henderson",
        "Reno",
        "North Las Vegas",
        "Sparks",
        "Carson City",
        "Elko",
        "Mesquite",
        "Boulder City",
        "Fernley"
      ],
      "New Hampshire": [
        "Manchester",
        "Nashua",
        "Concord",
        "Derry",
        "Dover",
        "Rochester",
        "Salem",
        "Merrimack",
        "Hudson",
        "Londonderry"
      ],
      "New Jersey": [
        "Newark",
        "Jersey City",
        "Paterson",
        "Elizabeth",
        "Edison",
        "Woodbridge",
        "Lakewood",
        "Toms River",
        "Hamilton",
        "Trenton"
      ],
      "New Mexico": [
        "Albuquerque",
        "Las Cruces",
        "Rio Rancho",
        "Santa Fe",
        "Roswell",
        "Farmington",
        "Clovis",
        "Hobbs",
        "Alamogordo",
        "Carlsbad"
      ],
      "New York": [
        "New York City",
        "Buffalo",
        "Rochester",
        "Yonkers",
        "Syracuse",
        "Albany",
        "New Rochelle",
        "Mount Vernon",
        "Schenectady",
        "Utica"
      ],
      "North Carolina": [
        "Charlotte",
        "Raleigh",
        "Greensboro",
        "Durham",
        "Winston-Salem",
        "Fayetteville",
        "Cary",
        "Wilmington",
        "High Point",
        "Greenville"
      ],
      "North Dakota": [
        "Fargo",
        "Bismarck",
        "Grand Forks",
        "Minot",
        "West Fargo",
        "Williston",
        "Mandan",
        "Dickinson",
        "Jamestown",
        "Wahpeton"
      ],
      "Ohio": [
        "Columbus",
        "Cleveland",
        "Cincinnati",
        "Toledo",
        "Akron",
        "Dayton",
        "Parma",
        "Canton",
        "Youngstown",
        "Lorain"
      ],
      "Oklahoma": [
        "Oklahoma City",
        "Tulsa",
        "Norman",
        "Broken Arrow",
        "Lawton",
        "Edmond",
        "Moore",
        "Midwest City",
        "Enid",
        "Stillwater"
      ],
      "Oregon": [
        "Portland",
        "Salem",
        "Eugene",
        "Gresham",
        "Hillsboro",
        "Beaverton",
        "Bend",
        "Medford",
        "Springfield",
        "Corvallis"
      ],
      "Pennsylvania": [
        "Philadelphia",
        "Pittsburgh",
        "Allentown",
        "Erie",
        "Reading",
        "Scranton",
        "Bethlehem",
        "Lancaster",
        "Harrisburg",
        "Altoona"
      ],
      "Rhode Island": [
        "Providence",
        "Warwick",
        "Cranston",
        "Pawtucket",
        "East Providence",
        "Woonsocket",
        "Coventry",
        "Cumberland",
        "North Providence",
        "South Kingstown"
      ],
      "South Carolina": [
        "Columbia",
        "Charleston",
        "North Charleston",
        "Mount Pleasant",
        "Rock Hill",
        "Greenville",
        "Summerville",
        "Sumter",
        "Goose Creek",
        "Hilton Head Island"
      ],
      "South Dakota": [
        "Sioux Falls",
        "Rapid City",
        "Aberdeen",
        "Brookings",
        "Watertown",
        "Mitchell",
        "Yankton",
        "Pierre",
        "Huron",
        "Vermillion"
      ],
      "Tennessee": [
        "Nashville",
        "Memphis",
        "Knoxville",
        "Chattanooga",
        "Clarksville",
        "Murfreesboro",
        "Franklin",
        "Jackson",
        "Johnson City",
        "Bartlett"
      ],
      "Texas": [
        "Houston",
        "San Antonio",
        "Dallas",
        "Austin",
        "Fort Worth",
        "El Paso",
        "Arlington",
        "Corpus Christi",
        "Plano",
        "Laredo"
      ],
      "Utah": [
        "Salt Lake City",
        "West Valley City",
        "Provo",
        "West Jordan",
        "Orem",
        "Sandy",
        "Ogden",
        "St. George",
        "Layton",
        "Taylorsville"
      ],
      "Vermont": [
        "Burlington",
        "South Burlington",
        "Rutland",
        "Essex",
        "Colchester",
        "Bennington",
        "Brattleboro",
        "Hartford",
        "Milton",
        "Barre"
      ],
      "Virginia": [
        "Virginia Beach",
        "Norfolk",
        "Chesapeake",
        "Richmond",
        "Newport News",
        "Alexandria",
        "Hampton",
        "Roanoke",
        "Portsmouth",
        "Suffolk"
      ],
      "Washington": [
        "Seattle",
        "Spokane",
        "Tacoma",
        "Vancouver",
        "Bellevue",
        "Kent",
        "Everett",
        "Renton",
        "Yakima",
        "Federal Way"
      ],
      "West Virginia": [
        "Charleston",
        "Huntington",
        "Morgantown",
        "Parkersburg",
        "Wheeling",
        "Weirton",
        "Fairmont",
        "Martinsburg",
        "Beckley",
        "Clarksburg"
      ],
      "Wisconsin": [
        "Milwaukee",
        "Madison",
        "Green Bay",
        "Kenosha",
        "Racine",
        "Appleton",
        "Waukesha",
        "Eau Claire",
        "Oshkosh",
        "Janesville"
      ],
      "Wyoming": [
        "Cheyenne",
        "Casper",
        "Laramie",
        "Gillette",
        "Rock Springs",
        "Sheridan",
        "Green River",
        "Evanston",
        "Riverton",
        "Jackson"
      ]
}








module.exports = {
    countries,
    provinces,
    cities,
    statesUSA,
    citiesUSA
}