import React from "react";
import {
	ActivityIndicator,
	Platform,
	SafeAreaView,
	StyleSheet,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";

// Tutor Review component
export const LoadingPage = ({}) => {
	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			paddingRight: 0, // Padding right is for the cover photo to stretch
			width: "100%",
			// maxWidth: Platform.OS === "web" ? 1350 : null,
			margin: Platform.OS === "web" ? "auto" : null,
		},
		mainSection: {
			flex: 1,
		},
	});

	return (
		<SafeAreaView style={styles.container}>
			<View style={styles.maxWidth}>
				<View
					style={[
						styles.mainSection,
						{
							alignItems: "center",
							justifyContent: "center",
						},
					]}
				>
					<ActivityIndicator size="large" color={primary} />
				</View>
			</View>
		</SafeAreaView>
	);
};
