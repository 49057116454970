import {
    Autocomplete,
    Box,
    Button,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'
import { StyleSheet } from 'react-native'
import { schoolLogos, schools } from '@config/schools'
import { Controller, useForm } from 'react-hook-form'
import { Degree } from 'dataTypes'
import InputIconLabel from '@components/InputIconLabel'
import Feather from '@expo/vector-icons/Feather'
import DegreeTypeIcon from '@icons/DegreeTypeIcon'
import { useColorsContext } from '@utils/styles'
import CompletionYearIcon from '@icons/CompletionYearIcon'
import DegreeIcon from '@icons/DegreeIcon'
import SchoolIcon from '@icons/SchoolIcon'
import { v4 as uuidv4 } from 'uuid'

interface DegreeFormProps {
    handleAddDegree: (degree: Degree) => void
}

const degrees = [
    'Bachelor of Arts',
    'Bachelor of Business Administration',
    'Bachelor of Commerce',
    'Bachelor of Computer Science',
    'Bachelor of Education',
    'Bachelor of Engineering',
    'Bachelor of Fine Arts',
    'Bachelor of Health Sciences',
    'Bachelor of Journalism',
    'Bachelor of Laws (LLB)',
    'Bachelor of Music',
    'Bachelor of Nursing',
    'Bachelor of Science',
    'Bachelor of Social Work',
    'Bachelor of Technology',
    'Bachelor of Tourism and Hospitality Management',
]
const degreeTypes = [
    'Associates Degree',
    'Bachelors Degree',
    'Masters Degree',
    'Doctorate Degree',
    'Professional Degree',
]

const years = () => {
    const currentYear = new Date().getFullYear()
    const years = []
    for (let i = currentYear; i > 1939; i--) {
        years.push(i)
    }
    years.unshift('In progress')
    return years
}

interface IFormInput {
    school: string
    otherSchool?: string
    degree: string
    type: string
    year: string
}

const DegreeForm: React.FC<DegreeFormProps> = ({ handleAddDegree }) => {
    const {
        setFocus,
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
    } = useForm<IFormInput>()

    const watchSchool = watch('school')

    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const { text } = useColorsContext()

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <Box sx={{ overflowY: 'auto', pr: 1, flex: 1 }}>
                <Box sx={styles.inputContainer}>
                    <InputIconLabel
                        icon={<DegreeTypeIcon size={22} color={text} />}
                        label="Degree Type"
                    />
                    <Controller
                        defaultValue=""
                        name="type"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                disablePortal={true}
                                options={degreeTypes}
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                {...field}
                                onChange={(_, value) => {
                                    field.onChange(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        {...params}
                                        error={!!errors?.type}
                                        placeholder="Choose a Degree Type"
                                    />
                                )}
                            />
                        )}
                    />
                </Box>
                <Box sx={styles.inputContainer}>
                    <InputIconLabel
                        icon={<SchoolIcon size={22} color={text} />}
                        label="School"
                    />
                    <Controller
                        defaultValue=""
                        name="school"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                disablePortal={true}
                                options={schools}
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                {...field}
                                onChange={(_, value) => {
                                    field.onChange(value)
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <Box
                                            component="li"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                p: 1,
                                            }}
                                            {...props}
                                        >
                                            <img
                                                src={schoolLogos[option]}
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                    marginRight: 16,
                                                    objectFit: 'contain',
                                                }}
                                            />
                                            <Typography>{option}</Typography>
                                        </Box>
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        {...params}
                                        error={!!errors?.school}
                                        placeholder="Choose an institution"
                                    />
                                )}
                            />
                        )}
                    />
                </Box>
                {watchSchool == 'Other' && (
                    <Box sx={styles.inputContainer}>
                        <InputIconLabel
                            icon={
                                <Feather name="edit-2" size={22} color={text} />
                            }
                            label="Custom School"
                        />
                        <Controller
                            defaultValue=""
                            name="otherSchool"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { ref, ...field } }) => (
                                <TextField
                                    style={{ width: '100%' }}
                                    inputRef={ref}
                                    onChange={(_, value) => {
                                        field.onChange(value)
                                    }}
                                    {...field}
                                    error={!!errors?.otherSchool}
                                    placeholder="Enter a Custom Institution"
                                />
                            )}
                        />
                    </Box>
                )}
                <Box sx={styles.inputContainer}>
                    <InputIconLabel
                        icon={<DegreeIcon size={22} color={text} />}
                        label="Degree"
                    />
                    <Controller
                        defaultValue=""
                        name="degree"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                disablePortal={true}
                                options={degrees}
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                {...field}
                                onChange={(_, value) => {
                                    field.onChange(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        {...params}
                                        error={!!errors?.degree}
                                        placeholder="Choose a degree"
                                    />
                                )}
                            />
                        )}
                    />
                </Box>

                <Box sx={[styles.inputContainer, { marginBottom: 4 }]}>
                    <InputIconLabel
                        icon={<CompletionYearIcon size={22} color={text} />}
                        label="Year"
                    />
                    <Controller
                        defaultValue=""
                        name="year"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                disablePortal={true}
                                options={years()}
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                {...field}
                                onChange={(_, value) => {
                                    field.onChange(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        {...params}
                                        error={!!errors?.year}
                                        placeholder="Choose a Year"
                                    />
                                )}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit((data) => {
                    handleAddDegree({
                        id: uuidv4(),
                        school:
                            data.school !== 'Other'
                                ? data.school
                                : data.otherSchool,
                        name: data.degree,
                        type: data.type,
                        year: data.year,
                        verificationImg: null,
                        isVerified: false,
                    })
                    reset()
                })}
            >
                Add Degree
            </Button>
        </Box>
    )
}

const styles = StyleSheet.create({
    inputContainer: {
        marginBottom: 2,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 16,
    },
})

export default DegreeForm
