import {
    Autocomplete,
    Box,
    Chip,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { EditProfileForm } from '@screens/loggedIn/Profile/EditProfileNew'
import React, { useMemo } from 'react'
import {
    Control,
    Controller,
    FieldErrors,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form'
import { StyleSheet } from 'react-native'
import { languageIcons, languages } from '@config/languages'
import { subjectData, subjectIcons, subjects } from '@config/subjects'
import { exams } from '@config/exams'
import LanguageChip from '@components/Chips/LanguageChip'
import { useColorsContext } from '@utils/styles'
import SubjectChip from '@components/Chips/SubjectChip'
import TutredBaseChip from '@components/Chips/TutredBaseChip'
interface EditTutorProfileProps {
    user: any
    control: Control<any>
    errors: FieldErrors<EditProfileForm>
    watch: UseFormWatch<EditProfileForm>
    setValue: UseFormSetValue<EditProfileForm>
}

const EditTutorProfile: React.FC<EditTutorProfileProps> = ({
    user,
    control,
    errors,
    watch,
    setValue,
}) => {
    const watchSubjects = watch('subjects')
    const theme = useTheme()
    const { darkMode } = useColorsContext()

    const skillOptions = useMemo(() => {
        const options = []
        let userSubjects = []
        if (user?.subjects) {
            userSubjects = Object.keys(user?.subjects).map((key) => key)
        }

        if (watchSubjects) {
            userSubjects = watchSubjects
        }
        userSubjects?.forEach((subject) => {
            subjectData[subject]?.specialities.forEach((specialty) => {
                options.push(specialty)
            })
        })
        return options
    }, [watchSubjects])

    const existingExams = useMemo(() => {
        let existing = []
        if (user?.examTutor) {
            user?.examTutor.forEach((userExam) => {
                console.log('userExam', userExam)
                const exam = exams.find((exam) => userExam === exam.value)
                if (exam) {
                    existing.push(exam)
                }
            })
        }
        return existing
    }, [user?.exams])

    return (
        <Box sx={styles.container}>
            <Box sx={styles.inputContainer}>
                <Controller
                    name="languages"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={Object.keys(user?.languages).map(
                        (key) => key
                    )}
                    render={({ field: { ref, ...field } }) => (
                        <Autocomplete
                            multiple
                            value={field.value}
                            disablePortal={true}
                            options={languages}
                            getOptionLabel={(option) => option}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({
                                        index,
                                    })
                                    return (
                                        <LanguageChip
                                            key={key}
                                            language={option}
                                            {...tagProps}
                                        />
                                    )
                                })
                            }
                            sx={{
                                '& .Mui-Autocomplete-root': {
                                    zIndex: 9999,
                                },
                            }}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value)
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            p: 1,
                                        }}
                                        {...props}
                                    >
                                        <img
                                            src={languageIcons[option]}
                                            style={{
                                                width: 32,
                                                height: 32,
                                                marginRight: 16,
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <Typography>{option}</Typography>
                                    </Box>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    inputRef={ref}
                                    {...params}
                                    label="Languages"
                                    error={!!errors?.languages}
                                    placeholder="Select Languages"
                                />
                            )}
                        />
                    )}
                />
            </Box>
            <Box sx={styles.inputContainer}>
                <Controller
                    name="subjects"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={Object.keys(user?.subjects).map((key) => key)}
                    render={({ field: { ref, ...field } }) => (
                        <Autocomplete
                            multiple
                            // value={Object.keys(user?.languages).map(
                            //     (key) => key
                            // )}
                            disablePortal={true}
                            options={subjects}
                            getOptionLabel={(option) => option}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({
                                        index,
                                    })
                                    return (
                                        <SubjectChip
                                            key={key}
                                            subject={option}
                                            {...tagProps}
                                        />
                                    )
                                })
                            }
                            sx={{
                                '& .Mui-Autocomplete-root': {
                                    zIndex: 9999,
                                },
                            }}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value)
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <Box
                                        key={option}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            p: 1,
                                        }}
                                        {...props}
                                    >
                                        <Box
                                            sx={{
                                                width: 28,
                                                height: 28,
                                                backgroundColor: darkMode
                                                    ? theme.palette.grey[400]
                                                    : theme.palette.grey[800],
                                                WebkitMask: `url(${subjectIcons[option]}) no-repeat center `,
                                                mask: `url(${subjectIcons[option]}) no-repeat center `,
                                                maskSize: 'contain',
                                                WebkitMaskSize: 'contain',
                                                mr: 2,
                                            }}
                                        />
                                        <Typography>{option}</Typography>
                                    </Box>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    inputRef={ref}
                                    {...params}
                                    label="Subjects"
                                    error={!!errors?.subjects}
                                    placeholder="Select Subjects"
                                />
                            )}
                        />
                    )}
                />
            </Box>
            <Box sx={styles.inputContainer}>
                <Controller
                    name="skills"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={user?.skills}
                    render={({ field: { ref, ...field } }) => (
                        <Autocomplete
                            multiple
                            // value={Object.keys(user?.languages).map(
                            //     (key) => key
                            // )}
                            disablePortal={true}
                            options={skillOptions}
                            getOptionLabel={(option) => option}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({
                                        index,
                                    })
                                    return (
                                        <TutredBaseChip
                                            key={key}
                                            sx={{
                                                backgroundColor: '#E50A8E',
                                                color: '#FFF',
                                            }}
                                            label={
                                                <Typography fontFamily="Poppins_500Medium">
                                                    {option}
                                                </Typography>
                                            }
                                            {...tagProps}
                                        />
                                    )
                                })
                            }
                            sx={{
                                '& .Mui-Autocomplete-root': {
                                    zIndex: 9999,
                                },
                            }}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    inputRef={ref}
                                    {...params}
                                    label="Specialties"
                                    error={!!errors?.skills}
                                    placeholder="Select Specialties"
                                />
                            )}
                        />
                    )}
                />
            </Box>
            <Box sx={styles.inputContainer}>
                <Controller
                    name="examTutor"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={existingExams}
                    render={({ field: { ref, ...field } }) => (
                        <Autocomplete
                            multiple
                            // value={Object.keys(user?.languages).map(
                            //     (key) => key
                            // )}
                            disablePortal={true}
                            options={exams}
                            isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                            }
                            getOptionLabel={(option) => option.label}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({
                                        index,
                                    })
                                    return (
                                        <TutredBaseChip
                                            key={key}
                                            variant="outlined"
                                            label={option.label}
                                            {...tagProps}
                                        />
                                    )
                                })
                            }
                            sx={{
                                '& .Mui-Autocomplete-root': {
                                    zIndex: 9999,
                                },
                            }}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    inputRef={ref}
                                    {...params}
                                    label="Exams"
                                    error={!!errors?.examTutor}
                                    placeholder="Select exams"
                                />
                            )}
                        />
                    )}
                />
            </Box>
            <Box sx={styles.inputContainer}>
                <Controller
                    defaultValue={user?.headline}
                    name="headline"
                    control={control}
                    rules={{ required: true, maxLength: 80 }}
                    render={({ field: { ref, ...field } }) => (
                        <TextField
                            inputRef={ref}
                            sx={styles.input}
                            {...field}
                            inputProps={{ maxLength: 80 }}
                            label="Profile Headline"
                            error={!!errors?.headline}
                            placeholder="Enter a profile headline"
                            onChange={(e) => {
                                field.onChange(e.target.value)
                            }}
                            helperText={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Typography variant="caption">
                                        {field?.value?.length}
                                        /80
                                    </Typography>
                                </Box>
                            }
                        />
                    )}
                />
            </Box>
            <Box sx={styles.inputContainer}>
                <Controller
                    defaultValue={user?.description}
                    name="description"
                    control={control}
                    rules={{ required: true, maxLength: 1000, minLength: 200 }}
                    render={({ field: { ref, ...field } }) => (
                        <TextField
                            inputRef={ref}
                            sx={styles.input}
                            minRows={5}
                            {...field}
                            label="Bio"
                            multiline
                            inputProps={{ maxLength: 1000 }}
                            error={!!errors?.description}
                            placeholder="Enter a Bio"
                            helperText={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Typography variant="caption">
                                        {field?.value?.length}
                                        /1000
                                    </Typography>
                                </Box>
                            }
                        />
                    )}
                />
            </Box>
        </Box>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    inputContainer: {
        marginBottom: 2,
    },
    input: {
        width: '100%',
    },
})

export default EditTutorProfile
