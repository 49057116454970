import OnboardingFooter from '@components/OnboardingDialog/OnboardingFooter'
import OnboardingSectionHeader from '@components/OnboardingDialog/OnboardingSectionHeader'
import { useFirebaseContext } from '@contexts/firebaseContext'
import BackgroundIcon from '@icons/BackgroundIcon'
import { useUserContext } from '@contexts/userContext'
import { Box, Button, Checkbox, Typography } from '@mui/material'
import AddNewDegreeModal from '@screens/loggedIn/Profile/Education/AddNewDegreeModal'
import DegreeCard from '@screens/loggedIn/Profile/Education/DegreeCard'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import DegreeForm from './DegreeForm'
import ProfilePreviewIcon from '@icons/ProfilePreviewIcon'
import InputIconLabel from '@components/InputIconLabel'
import UploadVerificationDialog from './UploadVerificationDialog'
import { useConfirmActionDialog } from '@hooks/useConfirmAction'

const Background = ({ setStep }) => {
    const [noDegree, setNoDegree] = useState(false)
    const alert = useAlert()
    const { user, extendedUser } = useUserContext()
    const { db } = useFirebaseContext()
    const { confirmAction, ConfirmActionDialogComponent } =
        useConfirmActionDialog()

    const removeDegree = (id: string) => {
        confirmAction({
            title: 'Delete Degree',
            message: 'Are you sure you want to delete this degree?',
            confirmText: 'Delete',
            onConfirm: () => {
                const docRef = doc(db, 'users', user.uid)
                updateDoc(docRef, {
                    degrees: extendedUser?.degrees?.filter(
                        (degree) => degree.id !== id
                    ),
                })
            },
        })
    }

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            <ConfirmActionDialogComponent />
            <OnboardingSectionHeader
                title="Background"
                subtitle="Tell students your qualifications. Share your education and certifications to showcase your expertise."
                icon={<BackgroundIcon size={44} />}
            />

            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    gap: 2,
                    p: 2,
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ flex: 6, overflow: 'hidden' }}>
                    <DegreeForm
                        handleAddDegree={(degree) => {
                            const docRef = doc(db, 'users', user.uid)
                            updateDoc(docRef, {
                                degrees: arrayUnion(degree),
                            })
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 8,
                        pb: 2,
                        overflowY: 'auto',
                        pr: 1,
                    }}
                >
                    <InputIconLabel
                        icon={<ProfilePreviewIcon size={22} />}
                        label="Preview"
                        center={true}
                    />
                    {extendedUser?.degrees?.map((doc, index) => {
                        return (
                            <DegreeCard
                                key={`degree-card-${index}`}
                                degree={doc}
                                showVerificationOption={true}
                                remove={() => removeDegree(doc.id)}
                            ></DegreeCard>
                        )
                    })}
                </Box>
            </Box>
            {/* <Box>
                {degreeArray.length === 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: 1,
                            marginBottom: 1,
                        }}
                    >
                        <Checkbox
                            value={noDegree}
                            onChange={() => setNoDegree(!noDegree)}
                        />
                        <Typography variant="body1" sx={{ marginLeft: 1 }}>
                            I have no post secondary experience
                        </Typography>
                    </Box>
                )}
            </Box> */}

            <OnboardingFooter
                step={3}
                onNext={() => {
                    if (extendedUser?.degrees?.length === 0 && !noDegree) {
                        alert.error(
                            '[Error] Please add at least one degree, or select no post secondary experience'
                        )
                    }
                    setStep(4)
                }}
                onPrev={() => {
                    setStep(2)
                }}
            />
        </Box>
    )
}

export default Background
