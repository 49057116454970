import { useUserContext } from '@contexts/userContext'
import {
    Link,
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import { useColorsContext, useStyles } from '@utils/styles'
import React, { useState } from 'react'
import { View, Text } from 'react-native'
import { MenuOption } from 'react-native-popup-menu'
import { AntDesign } from '@expo/vector-icons'

const ProfileNavigation = ({}) => {
    const { isTutor } = useUserContext()
    const [isHovered, setIsHovered] = useState(false)
    const defaultStyles = useStyles()
    const { border, backgroundVariant, textVariant, text } = useColorsContext()
    const navigation: NavigationProp<ParamListBase> = useNavigation()

    const handlePressIn = () => {
        setIsHovered(true)
    }

    const handlePressOut = () => {
        setIsHovered(false)
    }

    return (
        <MenuOption
            onSelect={() => {
                if (isTutor) {
                    navigation.navigate('Tutors', {
                        screen: 'TutorProfile',
                        tutorId: URL,
                    })
                } else {
                    navigation.navigate('Listings', {
                        screen: 'StudentProfile',
                        student: URL,
                    })
                }
            }}
            style={{
                backgroundColor: isHovered ? border : backgroundVariant,
                borderRadius: defaultStyles.radius,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 10,
            }}
        >
            <Link
                onMouseEnter={handlePressIn}
                onMouseLeave={handlePressOut}
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10,
                }}
                to={isTutor ? `/tutors/${URL}` : `/students/${URL}`}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 25,
                        height: 25,
                        display: 'flex',
                        margin: 5,
                    }}
                >
                    <AntDesign
                        size={19}
                        name={'user'}
                        color={textVariant}
                    ></AntDesign>
                </View>
                <Text
                    style={{
                        color: text,
                        fontSize: 16,
                        fontFamily: defaultStyles.regularFont,
                    }}
                >
                    Profile
                </Text>
            </Link>
        </MenuOption>
    )
}

export default ProfileNavigation
