import React, { useMemo } from 'react'
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { useUserContext } from '@contexts/userContext'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { useColorsContext } from '@utils/styles'
import HeartIcon from '@icons/HeartIcon'

interface Props {
    tutor: any
    size?: number
    iconStyle?: ViewStyle
    containerStyle?: ViewStyle
}

const Favourite: React.FC<Props> = ({
    tutor,
    size = 26,
    iconStyle,
    containerStyle,
}) => {
    const { favourites, user } = useUserContext()
    const { db } = useFirebaseContext()
    const { text } = useColorsContext()

    const isFavourite = useMemo(() => {
        return favourites.find((f) => f.id == tutor?.uid)
    }, [favourites, tutor?.uid])

    return (
        <TouchableOpacity
            style={[styles.container, { ...containerStyle }]}
            disabled={!user || user?.uid === tutor.uid}
            onPress={() => {
                if (!user) return
                const removeFav = doc(db, 'users', tutor.uid)
                const userRef = doc(db, 'users', user.uid)
                updateDoc(userRef, {
                    favourites: isFavourite
                        ? arrayRemove(removeFav)
                        : arrayUnion(removeFav),
                })
            }}
        >
            <HeartIcon size={size} filled={isFavourite} />
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5FCFF',
    },
})

export default Favourite
