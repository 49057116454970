import {
    average,
    collection,
    count,
    getAggregateFromServer,
    onSnapshot,
    query,
    where,
} from 'firebase/firestore'
import React, { useEffect, useMemo, useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { useFirebaseContext } from '../../contexts/firebaseContext'
import { useColorsContext, useStyles } from '../../utils/styles'
import {
    Avatar,
    Box,
    Card,
    Typography,
    Grid,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import AntDesign from '@expo/vector-icons/AntDesign'
import formatName from '@helpers/formatName'
import ShowMoreText from 'react-show-more-text'

import {
    languageIcons,
    languages as languagesData,
} from '../../config/languages'
import TutorSubjectsAndSpecialties from './TutorSubjects&Specialties'
import { FontAwesome } from '@expo/vector-icons'
import { useUserContext } from '@contexts/userContext'
import Favourite from '@components/Favourite'
import Foundation from '@expo/vector-icons/Foundation'
import Subjects from '@components/Subjects'
import Specialties from '@components/Specialties'
import LanguagesIcon from '@icons/LanaugesIcon'
import LocationIcon from '@icons/LocationIcon'
import StarIcon from '@icons/StarIcon'
import SendIcon from '@icons/SendIcon'
import CheckIcon from '@icons/CheckIcon'
import Rating from './Rating'

// Tutor component
export const TutorListCard = ({
    navigation,
    tutor,
    navigateToChat,
    maxRating,
    minRating,
    filterRating,
    preview = false,
    small = false,
}) => {
    const { db } = useFirebaseContext()

    // Colors and styles
    const defaultStyles = useStyles()
    const { primary, primaryLight, textSecondary, text, background } =
        useColorsContext()
    // Media queries

    // States
    const [reviewCount, setReviewCount] = useState(0)
    const [rating, setRating] = useState(0)
    const { user, profile, favourites } = useUserContext()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    // Get all reviews for tutor

    const getRating = async () => {
        let reviewsRef = collection(db, 'reviews')
        let q = query(reviewsRef)
        q = query(q, where('user.uid', '==', tutor.uid))
        const snapshot = await getAggregateFromServer(q, {
            averageRating: average('rating'),
            ratingCount: count(),
        })
        const { averageRating, ratingCount } = snapshot.data()
        setRating(averageRating?.toFixed(1) || 0)
        setReviewCount(ratingCount)
    }

    useEffect(() => {
        getRating()
    }, [tutor.uid])

    const languages = useMemo(() => {
        if (tutor?.languages?.length === 0) {
            return []
        } else {
            const spokenLanguages = []
            languagesData.forEach((language) => {
                if (tutor?.languages?.hasOwnProperty(language)) {
                    spokenLanguages.push(language)
                }
            })
            return spokenLanguages
        }
    }, [tutor.languages])

    if (
        filterRating &&
        ((rating !== 0 && rating < minRating) || rating > maxRating)
    )
        return null

    return (
        <Card
            sx={{
                marginBottom: small || !isMd ? 1 : 2,
                padding: small || !isMd ? 2 : 3,
                borderRadius: 3,
                backgroundColor: background,
                borderColor: '#000',
                minWidth: isMd ? 600 : '100%',
            }}
            variant="outlined"
        >
            <Grid container space={2}>
                {/* Name, Language, Location, Tag Line, Description, Subjects */}
                <Grid
                    item
                    md={8}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden',
                        flexWrap: 'wrap',
                        backgroundColor: background,
                        paddingRight: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            sx={{
                                marginRight: small || !isMd ? 1 : 2,
                                width: small || !isMd ? 64 : 72,
                                height: small || !isMd ? 64 : 72,
                            }}
                            alt={tutor.name}
                            src={tutor.picture}
                        ></Avatar>
                        {/* Name, Language, Location */}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {/* Name & Verified */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant={small || !isMd ? 'h6' : 'h5'}
                                    fontFamily={defaultStyles.boldFont}
                                    sx={{ color: text }}
                                >
                                    {formatName(tutor.name)}
                                </Typography>
                                {tutor.isVerified && (
                                    <AntDesign
                                        name="checkcircle"
                                        size={small || !isMd ? 16 : 20}
                                        color={primary}
                                        style={{ marginLeft: small ? 4 : 8 }}
                                    />
                                )}
                            </Box>
                            {/* Languages */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <LanguagesIcon size={isMd ? 22 : 18} />

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'flex-end',
                                            flexWrap: 'no-wrap',
                                            marginLeft: 1,
                                        }}
                                    >
                                        <Typography
                                            fontFamily="Poppins_500Medium"
                                            whiteSpace="nowrap"
                                            variant={
                                                small || !isMd
                                                    ? 'body2'
                                                    : 'body1'
                                            }
                                        >
                                            I Speak
                                        </Typography>
                                        {languages
                                            .slice(0, small || !isMd ? 2 : 3)
                                            .map((language) => {
                                                return (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'flex-end',
                                                        }}
                                                    >
                                                        <Typography
                                                            fontFamily={
                                                                defaultStyles.boldFont
                                                            }
                                                            sx={{
                                                                marginRight:
                                                                    small ||
                                                                    !isMd
                                                                        ? 0.5
                                                                        : 0.75,
                                                                marginLeft:
                                                                    small ||
                                                                    !isMd
                                                                        ? 0.25
                                                                        : 0.5,
                                                            }}
                                                            variant={
                                                                small || !isMd
                                                                    ? 'body2'
                                                                    : 'body1'
                                                            }
                                                        >
                                                            {language}
                                                        </Typography>
                                                        <img
                                                            src={
                                                                languageIcons[
                                                                    language
                                                                ]
                                                            }
                                                            alt={language}
                                                            width={
                                                                small || !isMd
                                                                    ? 16
                                                                    : 20
                                                            }
                                                            height={
                                                                small || !isMd
                                                                    ? 16
                                                                    : 20
                                                            }
                                                            style={{
                                                                objectFit:
                                                                    'contain',
                                                                marginBottom:
                                                                    small ||
                                                                    !isMd
                                                                        ? 1
                                                                        : 2,
                                                            }}
                                                        />
                                                    </Box>
                                                )
                                            })}
                                        {languages.length >
                                            (small || !isMd ? 2 : 3) && (
                                            <Typography
                                                variant={
                                                    small
                                                        ? 'body2'
                                                        : 'subtitle1'
                                                }
                                                sx={{
                                                    color: text,
                                                    marginLeft: small
                                                        ? 0.25
                                                        : 0.5,
                                                }}
                                            >
                                                ...
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            {/* Location */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    mt: 0.5,
                                }}
                            >
                                <LocationIcon size={small || !isMd ? 18 : 24} />
                                <Typography
                                    variant={small || !isMd ? 'body2' : 'body1'}
                                    fontFamily={defaultStyles.boldFont}
                                    sx={{ color: text, marginLeft: 1 }}
                                >
                                    {tutor.location.province}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid
                    item
                    md={4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                        backgroundColor: background,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {isMd && (
                            <Rating
                                reviewCount={reviewCount}
                                small={small}
                                rating={rating}
                            />
                        )}

                        <Favourite
                            tutor={tutor}
                            size={36}
                            containerStyle={{ marginLeft: small ? 12 : 24 }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container direction={isMd ? 'row' : 'column'}>
                <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden',
                        flexWrap: 'wrap',
                        backgroundColor: background,
                        paddingRight: 2,
                    }}
                >
                    {!isMd && <Rating reviewCount={reviewCount} small={true} />}
                    {/* Headline & Description */}
                    <Box sx={{ marginTop: small || !isMd ? 0 : 2 }}>
                        <Typography
                            variant={small || !isMd ? 'subtitle1' : 'h6'}
                            sx={{
                                color: text,
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                            }}
                            fontFamily={defaultStyles.boldFont}
                        >
                            {tutor.headline}
                        </Typography>
                        <ShowMoreText
                            more={'Read More'}
                            lines={3}
                            onClick={() => {
                                navigation.navigate('TutorProfile', {
                                    id: tutor.URL,
                                })
                            }}
                            expandByClick={false}
                            className="show-more-text"
                        >
                            {tutor.description}
                        </ShowMoreText>
                        <Subjects subjects={tutor.subjects} showTitle={false} />
                        <Specialties
                            specialties={tutor.skills}
                            showTitle={false}
                        />
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={4}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: isMd ? 'column' : 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        paddingTop: small ? 2 : 4,
                        gap: small ? 0.5 : 1,
                    }}
                >
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => navigateToChat(tutor, user, profile)}
                        disableElevation
                        sx={{
                            backgroundColor: primaryLight,
                            borderColor: '#D0D0D0',
                            color: '#000',
                            fontFamily: defaultStyles.titleFont,
                            fontSize: '1.25rem',
                            width: { xs: 42, md: '100%' },
                            p: 0,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            pr: 1,
                        }}
                        endIcon={isMd ? <SendIcon size={32} /> : null}
                        size={small || !isMd ? 'small' : 'medium'}
                    >
                        {isMd ? 'Message' : <SendIcon size={32} />}
                    </Button>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() =>
                            navigation.navigate('TutorProfile', {
                                id: tutor.URL,
                            })
                        }
                        disableElevation
                        size={small || !isMd ? 'small' : 'medium'}
                        sx={{
                            backgroundColor: primary,
                            fontFamily: defaultStyles.titleFont,
                            color: '#000',
                            fontSize: '1.25rem',
                            textTransform: 'none',
                            borderWidth: 3,
                            borderColor: '#000',
                            p: 0,
                        }}
                        endIcon={<CheckIcon size={22} />}
                    >
                        Book ${tutor.sessionPricing?.[60]?.rate || tutor?.rate}
                        /hr
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}
