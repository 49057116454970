import { AntDesign, Entypo, Feather } from '@expo/vector-icons'
import { getAuth } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { useColorsContext, useStyles } from '@utils/styles'

import {
    Dialog,
    DialogContent,
    Typography,
    DialogTitle,
    TextField,
    useTheme,
    DialogActions,
    Button,
    useMediaQuery,
    IconButton,
    ListItem,
    ListItemText,
    ListItemIcon,
    List,
    Box,
    Checkbox,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import TutredIcon from '@icons/TutredIcon'
import { useMutation } from '@tanstack/react-query'
import PasswordStrengthBar from 'react-password-strength-bar'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import useSignUp from '@hooks/auth/useSignUp'
import TutorSideBar from './TutorSideBar'
import { LoginDialog } from '@components/LoginDialog'

export interface SignUpFormData {
    firstName: string
    lastName: string
    email: string
    password: string
    agreeToTerms: boolean
    accountType: 'tutor' | 'student'
}

interface SignUpDialogProps {
    open: boolean
    setOpen: (open: boolean) => void
    type: 'tutor' | 'student'
    openLogin: () => void
}

export const SignUpDialog = ({
    open,
    setOpen,
    type = 'student',
    openLogin,
}: SignUpDialogProps) => {
    const {
        control,
        handleSubmit,
        setError,
        setValue,
        watch,
        formState: { errors },
    } = useForm<SignUpFormData>()

    const [showPassword, setShowPassword] = useState(false)
    const [passwordStrength, setPasswordStrength] = useState(0)

    const { primary, text } = useColorsContext()

    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    const onClose = () => {
        setOpen(false)
    }
    useEffect(() => {
        setValue('accountType', type)
    }, [type])

    const { signup, isPending, error } = useSignUp()
    const { password } = watch()

    useEffect(() => {
        if (error?.message.includes('auth/email-already-in-use')) {
            setError('email', { message: 'Email already in use' })
        } else if (error) {
            setError('email', { message: 'Something went wrong' })
        }
    }, [error])

    const barColors = [
        theme.palette.grey[500],
        theme.palette.error.main,
        theme.palette.warning.light,
        theme.palette.primary.main,
        theme.palette.success.main,
    ]

    return (
        <>
            {/* Without this it would cause a memory leak. Nesting them makes it easier to ensure the login dialog is available to the signup and vice-versa */}
            <Dialog
                open={open}
                maxWidth={type === 'tutor' ? 'md' : 'sm'}
                onClose={onClose}
                scroll="body"
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        pt: 0,
                        paddingLeft: type === 'tutor' && isMd ? '374px' : null,
                        overflow: 'hidden',
                    }}
                >
                    <>
                        <IconButton
                            onClick={onClose}
                            sx={{ position: 'absolute', top: 4, right: 4 }}
                        >
                            <AntDesign
                                name="close"
                                size={24}
                                color={theme.palette.grey[500]}
                            />
                        </IconButton>
                        <Typography
                            variant={isSm ? 'h4' : 'h5'}
                            fontFamily="Inter_700Bold"
                            textAlign={'center'}
                        >
                            Sign up
                        </Typography>
                        <Typography
                            variant={isSm ? 'body1' : 'body2'}
                            sx={{
                                color: theme.palette.text.secondary,
                                textAlign: 'center',
                                fontFamily: 'Inter_700Bold',
                            }}
                        >
                            Have an account?{' '}
                            <span
                                style={{ color: primary, cursor: 'pointer' }}
                                onClick={() => {
                                    setOpen(false)
                                    openLogin()
                                }}
                            >
                                Log in
                            </span>
                        </Typography>
                    </>
                </DialogTitle>
                <DialogContent
                    sx={{
                        paddingLeft: type === 'tutor' && isMd ? '374px' : null,
                        overflow: 'hidden',
                    }}
                >
                    {type === 'tutor' && isMd && <TutorSideBar />}

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: isSm ? 'nowrap' : 'wrap',
                            gap: isSm ? 2 : 0,
                            flexDirection: 'row',
                        }}
                    >
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'First name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size={isSm ? 'medium' : 'small'}
                                    label="First Name"
                                    sx={{ width: isSm ? '50%' : '100%', mt: 1 }}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName?.message}
                                    margin="normal"
                                    variant="outlined"
                                />
                            )}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Last name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size={isSm ? 'medium' : 'small'}
                                    label="Last Name"
                                    sx={{ width: isSm ? '50%' : '100%', mt: 1 }}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName?.message}
                                    margin="normal"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Box>

                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                size={isSm ? 'medium' : 'small'}
                                sx={{ mt: 1 }}
                                {...field}
                                label="Email"
                                fullWidth
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                margin="normal"
                                variant="outlined"
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'Password is required',
                            minLength: {
                                value: 8,
                                message:
                                    'Password must be at least 8 characters',
                            },
                            pattern: {
                                value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]).*$/,
                                message:
                                    'Password must contain at least one uppercase letter, one number, and one special character',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                size={isSm ? 'medium' : 'small'}
                                sx={{ mt: 1 }}
                                {...field}
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                error={!!errors.password}
                                helperText={errors.password?.message}
                                margin="normal"
                                variant="outlined"
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <>
                                                {showPassword ? (
                                                    <IconButton
                                                        tabIndex={-1}
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword
                                                            )
                                                        }
                                                    >
                                                        <Feather
                                                            name="eye-off"
                                                            size={24}
                                                            color={text}
                                                        />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        tabIndex={-1}
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword
                                                            )
                                                        }
                                                    >
                                                        <Feather
                                                            name="eye"
                                                            size={24}
                                                            color={text}
                                                        />
                                                    </IconButton>
                                                )}
                                            </>
                                        ),
                                    },
                                }}
                            />
                        )}
                    />
                    <PasswordStrengthBar
                        password={password}
                        className="password-strength-bar"
                        barColors={barColors}
                        scoreWordStyle={{
                            fontFamily: 'Poppins_500Medium',
                            color: barColors[passwordStrength],
                        }}
                        scoreWords={['Weak', 'Weak', 'Fair', 'Good', 'Strong']}
                        shortScoreWord="Too Short"
                        onChangeScore={(score) => setPasswordStrength(score)}
                    />
                    <List dense sx={{ p: 0, lineHeight: 1 }}>
                        <ListItem sx={{ p: 0, m: 0 }} alignItems="center">
                            <ListItemIcon sx={{ marginRight: 0, minWidth: 16 }}>
                                <Entypo
                                    name="dot-single"
                                    size={20}
                                    color={
                                        password?.length >= 8
                                            ? theme.palette.success.main
                                            : errors.password
                                              ? theme.palette.error.main
                                              : theme.palette.grey[500]
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText sx={{ m: 0 }}>
                                <Typography
                                    variant="body2"
                                    fontFamily={'Poppins_500Medium'}
                                    color={
                                        password?.length >= 8
                                            ? theme.palette.success.main
                                            : errors.password
                                              ? theme.palette.error.main
                                              : theme.palette.grey[500]
                                    }
                                >
                                    Minimum 8 characters
                                </Typography>
                            </ListItemText>
                            {password?.length >= 8 && (
                                <AntDesign
                                    name="check"
                                    size={18}
                                    color={theme.palette.success.main}
                                />
                            )}
                        </ListItem>
                        <ListItem sx={{ p: 0, m: 0 }} alignItems="center">
                            <ListItemIcon sx={{ marginRight: 0, minWidth: 16 }}>
                                <Entypo
                                    name="dot-single"
                                    size={20}
                                    color={
                                        /[A-Z]/.test(password)
                                            ? theme.palette.success.main
                                            : errors.password
                                              ? theme.palette.error.main
                                              : theme.palette.grey[500]
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText sx={{ m: 0 }}>
                                <Typography
                                    variant="body2"
                                    fontFamily={'Poppins_500Medium'}
                                    color={
                                        /[A-Z]/.test(password)
                                            ? theme.palette.success.main
                                            : errors.password
                                              ? theme.palette.error.main
                                              : theme.palette.grey[500]
                                    }
                                >
                                    One uppercase character
                                </Typography>
                            </ListItemText>
                            {/[A-Z]/.test(password) && (
                                <AntDesign
                                    name="check"
                                    size={18}
                                    color={theme.palette.success.main}
                                />
                            )}
                        </ListItem>
                        <ListItem sx={{ p: 0, m: 0 }} alignItems="center">
                            <ListItemIcon sx={{ marginRight: 0, minWidth: 16 }}>
                                <Entypo
                                    name="dot-single"
                                    size={20}
                                    color={
                                        /[!@#$%^&*(),.?":{}|<>]/.test(password)
                                            ? theme.palette.success.main
                                            : errors.password
                                              ? theme.palette.error.main
                                              : theme.palette.grey[500]
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText sx={{ m: 0 }}>
                                <Typography
                                    variant="body2"
                                    fontFamily={'Poppins_500Medium'}
                                    color={
                                        /[!@#$%^&*(),.?":{}|<>]/.test(password)
                                            ? theme.palette.success.main
                                            : errors.password
                                              ? theme.palette.error.main
                                              : theme.palette.grey[500]
                                    }
                                >
                                    One special character
                                </Typography>
                            </ListItemText>
                            {/[!@#$%^&*(),.?":{}|<>]/.test(password) && (
                                <AntDesign
                                    name="check"
                                    size={18}
                                    color={theme.palette.success.main}
                                />
                            )}
                        </ListItem>
                        <ListItem sx={{ p: 0, m: 0 }} alignItems="center">
                            <ListItemIcon sx={{ marginRight: 0, minWidth: 16 }}>
                                <Entypo
                                    name="dot-single"
                                    size={20}
                                    color={
                                        /\d/.test(password)
                                            ? theme.palette.success.main
                                            : errors.password
                                              ? theme.palette.error.main
                                              : theme.palette.grey[500]
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText sx={{ m: 0 }}>
                                <Typography
                                    variant="body2"
                                    fontFamily={'Poppins_500Medium'}
                                    color={
                                        /\d/.test(password)
                                            ? theme.palette.success.main
                                            : errors.password
                                              ? theme.palette.error.main
                                              : theme.palette.grey[500]
                                    }
                                >
                                    One number
                                </Typography>
                            </ListItemText>
                            {/\d/.test(password) && (
                                <AntDesign
                                    name="check"
                                    size={18}
                                    color={theme.palette.success.main}
                                />
                            )}
                        </ListItem>
                    </List>
                    <Box
                        sx={{
                            mt: isSm ? 8 : 4,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Controller
                            name="agreeToTerms"
                            control={control}
                            defaultValue={false}
                            rules={{
                                required:
                                    'You must agree to the Terms of Service',
                            }}
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    icon={
                                        <MaterialIcons
                                            name="radio-button-unchecked"
                                            size={30}
                                            color={theme.palette.text.secondary}
                                        />
                                    }
                                    checkedIcon={
                                        <MaterialIcons
                                            name="check-circle"
                                            size={30}
                                            color={theme.palette.primary.main}
                                        />
                                    }
                                    checked={field.value}
                                    onChange={(e) =>
                                        field.onChange(e.target.checked)
                                    }
                                    color="primary"
                                />
                            )}
                        />
                        <Typography
                            variant={isSm ? 'body2' : 'caption'}
                            fontFamily="Poppins_500Medium"
                            sx={{ ml: 1 }}
                        >
                            I agree to the Terms of Service and confirm I am 13
                            years old or older
                        </Typography>
                    </Box>
                    {errors.agreeToTerms && (
                        <Typography
                            color="error"
                            variant="caption"
                            sx={{ mt: 1 }}
                        >
                            {errors.agreeToTerms.message}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingLeft: type === 'tutor' && isMd ? '374px' : 0,
                        overflow: 'hidden',
                        pb: 0,
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{ width: isMd ? '50%' : '100%' }}
                        onClick={() => {
                            handleSubmit((data) => {
                                signup(data)
                            })()
                        }}
                        disabled={isPending}
                    >
                        {isPending ? (
                            <ActivityIndicator size="small" />
                        ) : (
                            'Create account'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
