import { useUserContext } from '@contexts/userContext'
import formatName from '@helpers/formatName'
import useFinalizeInstaBookSession from '@hooks/useFinalizeInstaBookSession'
import {
    Box,
    Checkbox,
    Divider,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useAlert } from 'react-alert'
import { useColorsContext, useStyles } from '@utils/styles'
import { useMemo, useState } from 'react'
import {
    ActivityIndicator,
    Modal,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import { HelpOutline } from '@mui/icons-material'
import { useSessionContext } from '@contexts/sessionContext'
import PaymentMethod from '@components/PaymentMethod'
import paymentMethodIcon from '@icons/payment-method.png'
import serviceFeeIcon from '@icons/service-fee.png'
import totalIcon from '@icons/total.png'
import sessionLengthIcon from '@icons/session-length.png'

export const SessionExtensionConfirmation = ({
    tutor,
    session,
    showModal,
    setShowModal,
    customerId,
}) => {
    if (!tutor?.instaBookPricing) {
        return null
    }
    const [loading, setLoading] = useState(false)
    const customAlert = useAlert()

    const { background, border, text, green, redVariant } = useColorsContext()
    const defaultStyles = useStyles()
    const { paymentMethods } = useUserContext()
    const theme = useTheme()
    const isXl = useMediaQuery(theme.breakpoints.up('xl'))
    const isLg = useMediaQuery(theme.breakpoints.up('lg'))
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    const totalSessionFee = tutor.instaBookExtensionRate * 15
    const taxesAndFees = useMemo(() => {
        return (totalSessionFee * 0.1).toFixed(2)
    }, [totalSessionFee])

    const totalCost = useMemo(() => {
        return (totalSessionFee + Number(taxesAndFees)).toFixed(2)
    }, [totalSessionFee, taxesAndFees])

    const { proposeSessionExtension } = useSessionContext()
    return (
        <View>
            <Modal
                key={3}
                animationType="fade"
                transparent={true}
                visible={showModal}
                onRequestClose={() => {
                    setShowModal(!showModal)
                    setLoading(false)
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: background,
                            borderRadius: defaultStyles.radius,
                            border: `1px solid ${border}`,
                            width: isXl
                                ? '40%'
                                : isLg
                                  ? '40%'
                                  : isMd
                                    ? '40%'
                                    : isSm
                                      ? '70%'
                                      : '95%',
                            padding: isMd ? 4 : 2,
                            shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.25,
                            shadowRadius: 4,
                            elevation: 5,
                        }}
                    >
                        <Box>
                            <Typography
                                variant={isMd ? 'h5' : 'h6'}
                                sx={{
                                    fontFamily: defaultStyles.titleFont,
                                    textAlign: 'center',
                                }}
                            >
                                Request an extension of your session?
                            </Typography>
                        </Box>
                        <Divider
                            variant="middle"
                            sx={{
                                backgroundColor: border,
                                marginTop: isMd ? 4 : 2,
                                marginBottom: isMd ? 4 : 2,
                            }}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 3,
                                justifyContent: 'space-between',
                                marginBottom: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={sessionLengthIcon}
                                    width={24}
                                    height={24}
                                    style={{
                                        marginLeft: -2,
                                        marginRight: 12,
                                    }}
                                />
                                <Typography
                                    variant={isMd ? 'body1' : 'body2'}
                                    sx={{ fontFamily: defaultStyles.titleFont }}
                                >
                                    15 minute extension:{' '}
                                </Typography>
                            </Box>

                            <Typography
                                variant={isMd ? 'body1' : 'body2'}
                                sx={{
                                    fontFamily: defaultStyles.regularFont,
                                }}
                            >
                                $
                                {(
                                    tutor?.instaBookExtensionRate * 15 ||
                                    15 * 0.5
                                ).toFixed(2)}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={serviceFeeIcon}
                                    width={26}
                                    height={26}
                                    style={{
                                        marginRight: 12,
                                    }}
                                />
                                <Typography
                                    variant={isMd ? 'body1' : 'body2'}
                                    sx={{
                                        fontFamily: defaultStyles.titleFont,
                                    }}
                                >
                                    Service Fee:{' '}
                                </Typography>
                                <Tooltip title="Includes taxes and our service fee.">
                                    <HelpOutline
                                        color={text}
                                        sx={{ marginLeft: 1 }}
                                    />
                                </Tooltip>
                            </Box>

                            <Typography
                                variant={isMd ? 'body1' : 'body2'}
                                sx={{
                                    fontFamily: defaultStyles.regularFont,
                                }}
                            >
                                ${taxesAndFees}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={totalIcon}
                                    width={32}
                                    height={32}
                                    style={{
                                        marginLeft: -2,
                                        marginRight: 12,
                                    }}
                                />
                                <Typography
                                    variant={isMd ? 'body1' : 'body2'}
                                    sx={{
                                        fontFamily: defaultStyles.titleFont,
                                    }}
                                >
                                    Total:{' '}
                                </Typography>
                            </Box>

                            <Typography
                                variant={isMd ? 'body1' : 'body2'}
                                sx={{
                                    fontFamily: defaultStyles.regularFont,
                                }}
                            >
                                ${totalCost}
                            </Typography>
                        </Box>
                        {paymentMethods?.length > 0 && (
                            <Box
                                sx={{
                                    marginBottom: isMd ? 4 : 2,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'start',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        src={paymentMethodIcon}
                                        style={{
                                            marginLeft: -4,
                                            width: 36,
                                            height: 36,
                                            marginRight: 12,
                                        }}
                                    />
                                    <Typography
                                        variant={isMd ? 'body1' : 'body2'}
                                        sx={{
                                            fontFamily: defaultStyles.titleFont,
                                        }}
                                    >
                                        Payment Method:
                                    </Typography>
                                </Box>
                                <PaymentMethod
                                    brand={paymentMethods[0].card.brand}
                                    endingIn={paymentMethods[0].card.last4}
                                />
                            </Box>
                        )}

                        <Box
                            sx={{
                                marginBottom: isMd ? 4 : 2,
                            }}
                        >
                            <Typography
                                variant={isMd ? 'body2' : 'caption'}
                                sx={{
                                    fontFamily: defaultStyles.regularFont,
                                    textAlign: 'center',
                                }}
                            >
                                You won't be charged for the extension unless{' '}
                                {formatName(tutor?.name)} accepts the extension
                                request.
                            </Typography>
                        </Box>

                        {/* Buttons */}
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginTop: 30,
                            }}
                        >
                            <TouchableOpacity
                                disabled={loading}
                                style={{
                                    backgroundColor: green,
                                    width: 100,
                                    height: 40,
                                    marginLeft: 5,
                                    borderRadius: defaultStyles.buttonRadius,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onPress={async () => {
                                    setLoading(true)
                                    const tutorPayout = totalSessionFee * 0.9
                                    proposeSessionExtension(
                                        session,
                                        totalCost,
                                        tutorPayout,
                                        customerId,
                                        tutor
                                    )
                                        .then(() => {
                                            customAlert.success(
                                                'Extension requested, thank you!'
                                            )
                                        })
                                        .catch(() => {
                                            customAlert.error(
                                                'We were unable to request your extension. Please try again later.'
                                            )
                                        })
                                        .finally(() => {
                                            setLoading(false)
                                            setShowModal(false)
                                        })
                                }}
                            >
                                {loading ? (
                                    <ActivityIndicator size="small" />
                                ) : (
                                    <Text
                                        style={{
                                            color: '#fff',
                                            fontSize: 16,
                                            fontFamily: defaultStyles.boldFont,
                                        }}
                                    >
                                        Request
                                    </Text>
                                )}
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={{
                                    backgroundColor: redVariant,
                                    width: 100,
                                    height: 40,
                                    marginLeft: 5,
                                    borderRadius: defaultStyles.buttonRadius,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                disabled={loading}
                                onPress={() => {
                                    setShowModal(false)
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontSize: 16,
                                        fontFamily: defaultStyles.boldFont,
                                    }}
                                >
                                    Cancel
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </Box>
                </Box>
            </Modal>
        </View>
    )
}
