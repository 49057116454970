import React from 'react'
import {
    Chip,
    Avatar,
    ChipProps,
    Typography,
    IconButton,
    Box,
} from '@mui/material'
import { languageIcons } from '../../../config/languages'
import AntDesign from '@expo/vector-icons/AntDesign'
import { useTheme } from '@mui/material/styles'
import { useColorsContext } from '@utils/styles'
import { specialtyIcons } from '@config/subjects'
import TutredBaseChip from '../TutredBaseChip'

interface SpecialtyChipProps extends ChipProps {
    specialty: string
}

const SpecialtyChip: React.FC<SpecialtyChipProps> = ({
    specialty,
    onDelete,
    ...rest
}) => {
    const theme = useTheme()
    const { darkMode } = useColorsContext()

    return (
        <TutredBaseChip
            sx={{
                pl: 1,
                borderRadius: 8,
                borderColor: darkMode
                    ? theme.palette.grey[400]
                    : theme.palette.grey[800],
                borderWidth: 2,
                fontFamily: 'Poppins_500Medium',
                fontSize: 16,
                backgroundColor: '#E50A8E',
                color: '#FFF',
            }}
            color="primary"
            icon={
                <Box
                    sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: '#FFF',
                        WebkitMask: `url(${specialtyIcons[specialty]}) no-repeat center `,
                        mask: `url(${specialtyIcons[specialty]}) no-repeat center `,
                        maskSize: 'contain',
                        WebkitMaskSize: 'contain',
                        mr: 2,
                    }}
                />
            }
            label={specialty}
            onDelete={onDelete}
            {...rest}
        />
    )
}

export default SpecialtyChip
