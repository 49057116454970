import OnboardingFooter from '@components/OnboardingDialog/OnboardingFooter'
import { useUserContext } from '@contexts/userContext'
import {
    Box,
    Button,
    Divider,
    Grid2,
    List,
    ListItem,
    Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Image, TouchableOpacity } from 'react-native'
import {
    AntDesign,
    Feather,
    FontAwesome,
    FontAwesome5,
    FontAwesome6,
    MaterialIcons,
} from '@expo/vector-icons'
import useUpdateProfilePicture from '@screens/loggedIn/Profile/useUpdateProfilePicture'
import { useColorsContext, useStyles } from '@utils/styles'
import formatName from '@helpers/formatName'
import example1 from '@assets/images/onboarding/example-1.jpg'
import example2 from '@assets/images/onboarding/example-2.jpg'
import example3 from '@assets/images/onboarding/example-3.jpg'
import UploadPhotoDialog from '@components/UploadPhotoDialog'
import TakePhotoIcon from '@icons/TakePhotoIcon'
import TutorList from '@screens/TutorList'
import { TutorListCard } from '@components/TutorListCard'
import { useNavigation } from '@react-navigation/native'
import ProfilePreviewIcon from '@icons/ProfilePreviewIcon'
import PhotoGuidelinesIcon from '@icons/PhotoGuidelinesIcon'
import ClearImageIcon from '@icons/ClearImageIcon'
import RecentPhotoIcon from '@icons/RecentPhotoIcon'
import NaturalExpressionIcon from '@icons/NaturalExpressionIcon'
import CenteredIcon from '@icons/CenteredIcon'
import PlainBackgroundIcon from '@icons/PlainBackgroundIcon'
import VisibleFaceIcon from '@icons/VisibleFaceIcon'
import OnboardingSectionHeader from '@components/OnboardingDialog/OnboardingSectionHeader'

const ProfilePhoto = ({ setStep }) => {
    const defaultStyles = useStyles()
    const { extendedUser } = useUserContext()
    const [loading, setLoading] = useState(false)
    const { text } = useColorsContext()
    const [error, setError] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const navigation = useNavigation()

    useEffect(() => {
        if (extendedUser?.picture) {
            setError(false)
        }
    }, [extendedUser?.picture])

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <UploadPhotoDialog visible={showModal} setVisible={setShowModal} />
            {/* header */}
            <OnboardingSectionHeader
                title="Profile Photo"
                subtitle="Say cheese! Upload a photo of yourself and some life to your profile."
                icon={<TakePhotoIcon size={48} />}
            />

            {/* Main Content */}
            <Box
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    paddingBottom: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: 2,
                        paddingRight: 2,
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            mb: 2,
                            mt: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <ProfilePreviewIcon size={24} />
                        <Typography
                            variant="h6"
                            fontFamily={defaultStyles.regularFont}
                        >
                            Profile Preview
                        </Typography>
                    </Box>
                    <TutorListCard
                        navigation={navigation}
                        tutor={extendedUser}
                        navigateToChat={() => {}}
                        maxRating={5}
                        minRating={1}
                        filterRating={1}
                        small={true}
                        preview={true}
                    />
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        paddingLeft: 2,
                        paddingRight: 2,
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            mt: 1,
                            mb: 2,
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => setShowModal(true)}
                        >
                            Upload
                        </Button>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                            }}
                        >
                            <Box sx={{ mb: 0.5 }}>
                                <PhotoGuidelinesIcon size={32} />
                            </Box>
                            <Typography
                                variant="h5"
                                sx={{ ml: 1 }}
                                fontFamily={defaultStyles.titleFont}
                            >
                                Photo Guidelines
                            </Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Divider
                                variant="middle"
                                color={text}
                                sx={{ marginTop: 1, marginBottom: 1 }}
                            />
                        </Box>
                        <Grid2 container spacing={3} sx={{ pb: 4, pt: 2 }}>
                            <Grid2
                                size={{ xs: 12, md: 6 }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <VisibleFaceIcon size={28} />
                                <Box sx={{ ml: 1 }}>
                                    <Typography
                                        variant="body1"
                                        sx={{ fontSize: 16 }}
                                        fontFamily={defaultStyles.titleFont}
                                    >
                                        Visible Face
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontFamily={defaultStyles.regularFont}
                                    >
                                        No hats, sunglasses, or shadows.
                                    </Typography>
                                </Box>
                            </Grid2>
                            <Grid2
                                size={{ xs: 12, md: 6 }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <PlainBackgroundIcon size={28} />
                                <Box sx={{ ml: 1 }}>
                                    <Typography
                                        variant="body1"
                                        fontFamily={defaultStyles.titleFont}
                                    >
                                        Plain background
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontFamily={defaultStyles.regularFont}
                                    >
                                        Keep it simple and uncluttered.
                                    </Typography>
                                </Box>
                            </Grid2>
                            <Grid2
                                size={{ xs: 12, md: 6 }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <CenteredIcon size={28} />
                                <Box sx={{ ml: 1 }}>
                                    <Typography
                                        variant="body1"
                                        fontFamily={defaultStyles.titleFont}
                                    >
                                        Centered
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontFamily={defaultStyles.regularFont}
                                    >
                                        Face should fill most of the frame.
                                    </Typography>
                                </Box>
                            </Grid2>
                            <Grid2
                                size={{ xs: 12, md: 6 }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <ClearImageIcon size={28} />
                                <Box sx={{ ml: 1 }}>
                                    <Typography
                                        variant="body1"
                                        fontFamily={defaultStyles.titleFont}
                                    >
                                        Clear image
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontFamily={defaultStyles.regularFont}
                                    >
                                        Use a high-resolution, non-blurry photo.
                                    </Typography>
                                </Box>
                            </Grid2>
                            <Grid2
                                size={{ xs: 12, md: 6 }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <NaturalExpressionIcon size={28} />
                                <Box sx={{ ml: 1 }}>
                                    <Typography
                                        variant="body1"
                                        fontFamily={defaultStyles.titleFont}
                                    >
                                        Natural Expression
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontFamily={defaultStyles.regularFont}
                                    >
                                        Avoid filters and exaggerated
                                        expressions.
                                    </Typography>
                                </Box>
                            </Grid2>
                            <Grid2
                                size={{ xs: 12, md: 6 }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <RecentPhotoIcon size={28} />
                                <Box sx={{ ml: 1 }}>
                                    <Typography
                                        variant="body1"
                                        fontFamily={defaultStyles.titleFont}
                                    >
                                        Recent photo
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontFamily={defaultStyles.regularFont}
                                    >
                                        Must reflect your current appearance.
                                    </Typography>
                                </Box>
                            </Grid2>
                        </Grid2>
                    </Box>
                </Box>
            </Box>
            {/* footer */}
            <OnboardingFooter
                step={5}
                onNext={() => {
                    if (extendedUser?.picture) setStep(6)
                    else setError(true)
                }}
                onPrev={() => {
                    setStep(4)
                }}
                loading={loading}
            />
        </Box>
    )
}

export default ProfilePhoto
