

const schoolsData = [
    { name: "Acadia University", path: require("@assets/images/schools/Acadia_University_logo.png") },
    // { name: "Alberta University of the Arts", path: require("@assets/images/schools/Alberta_University_of_the_Arts_logo.png") },
    { name: "Algoma University", path: require("@assets/images/schools/Algoma_University_logo.png") },
    { name: "Ambrose University", path: require("@assets/images/schools/Ambrose_University_logo.jpg") },
    { name: "Athabasca University", path: require("@assets/images/schools/Athabasca_University_logo.jpg") },
    { name: "Bishop's University", path: require("@assets/images/schools/Bishop's_University_logo.png") },
    // { name: "Booth University College", path: require("@assets/images/schools/Booth_University_College_logo.png") },
    { name: "Brandon University", path: require("@assets/images/schools/Brandon_University_logo.png") },
    { name: "Brock University", path: require("@assets/images/schools/Brock_University_logo.jpg") },
    { name: "Burman University", path: require("@assets/images/schools/Burman_University_logo.png") },
    { name: "Canadian Mennonite University", path: require("@assets/images/schools/Canadian_Mennonite_University_logo.png") },
    { name: "Cape Breton University", path: require("@assets/images/schools/Cape_Breton_University_logo.png") },
    { name: "Capilano University", path: require("@assets/images/schools/Capilano_University_logo.jpg") },
    { name: "Carleton University", path: require("@assets/images/schools/Carleton_University_logo.png") },
    { name: "Concordia University", path: require("@assets/images/schools/Concordia_University_logo.png") },
    { name: "Concordia University of Edmonton", path: require("@assets/images/schools/Concordia_University_of_Edmonton_logo.png") },
    { name: "Crandall University", path: require("@assets/images/schools/Crandall_University_logo.jpg") },
    { name: "Dalhousie University", path: require("@assets/images/schools/Dalhousie_University_logo.png") },
    { name: "Dominican University College", path: require("@assets/images/schools/Dominican_University_College_logo.png") },
    { name: "Emily Carr University of Art and Design", path: require("@assets/images/schools/Emily_Carr_University_of_Art_and_Design_logo.jpg") },
    { name: "First Nations University of Canada", path: require("@assets/images/schools/First_Nations_University_of_Canada_logo.png") },
    { name: "Kingswood University", path: require("@assets/images/schools/Kingswood_University_logo.png") },
    { name: "Kwantlen Polytechnic University", path: require("@assets/images/schools/Kwantlen_Polytechnic_University_logo.png") },
    { name: "Lakehead University", path: require("@assets/images/schools/Lakehead_University_logo.jpg") },
    { name: "Laurentian University", path: require("@assets/images/schools/Laurentian_University_logo.png") },
    { name: "MacEwan University", path: require("@assets/images/schools/MacEwan_University_logo.jpg") },
    { name: "McGill University", path: require("@assets/images/schools/McGill_University_logo.png") },
    { name: "McMaster University", path: require("@assets/images/schools/McMaster_University_logo.png") },
    { name: "Memorial University of Newfoundland", path: require("@assets/images/schools/Memorial_University_of_Newfoundland_logo.png") },
    { name: "Mount Allison University", path: require("@assets/images/schools/Mount_Allison_University_logo.png") },
    { name: "Mount Royal University", path: require("@assets/images/schools/Mount_Royal_University_logo.png") },
    { name: "Mount Saint Vincent University", path: require("@assets/images/schools/Mount_Saint_Vincent_University_logo.jpg") },
    { name: "Nipissing University", path: require("@assets/images/schools/Nipissing_University_logo.png") },
    { name: "Nova Scotia College of Art and Design University", path: require("@assets/images/schools/Nova_Scotia_College_of_Art_and_Design_University_logo.png") },
    { name: "Ontario College of Art and Design University", path: require("@assets/images/schools/Ontario_College_of_Art_and_Design_University_logo.png") },
    { name: "Ontario Institute for Studies in Education", path: require("@assets/images/schools/Ontario_Institute_for_Studies_in_Education_logo.JPG") },
    { name: "Ontario Tech University", path: require("@assets/images/schools/Ontario_Tech_University_logo.png") },
    { name: "Providence University College and Theological Seminary", path: require("@assets/images/schools/Providence_University_College_and_Theological_Seminary_logo.jpg") },
    { name: "Queen's University at Kingston", path: require("@assets/images/schools/Queen's_University_at_Kingston_logo.png") },
    { name: "Redeemer University", path: require("@assets/images/schools/Redeemer_University_logo.png") },
    { name: "Royal Roads University", path: require("@assets/images/schools/Royal_Roads_University_logo.png") },
    { name: "Saint Francis Xavier University", path: require("@assets/images/schools/Saint_Francis_Xavier_University_logo.png") },
    { name: "Saint Mary's University", path: require("@assets/images/schools/Saint_Mary's_University_logo.png") },
    { name: "Simon Fraser University", path: require("@assets/images/schools/Simon_Fraser_University_logo.png") },
    { name: "St. Stephen's University", path: require("@assets/images/schools/St._Stephen's_University_logo.jpg") },
    { name: "St. Thomas University", path: require("@assets/images/schools/St._Thomas_University_logo.png") },
    { name: "The King's University", path: require("@assets/images/schools/The_King's_University_logo.png") },
    { name: "Thompson Rivers University", path: require("@assets/images/schools/Thompson_Rivers_University_logo.jpg") },
    { name: "Toronto Metropolitan University", path: require("@assets/images/schools/Toronto_Metropolitan_University_logo.png") },
    { name: "Trent University", path: require("@assets/images/schools/Trent_University_logo.png") },
    { name: "Trinity Western University", path: require("@assets/images/schools/Trinity_Western_University_logo.png") },
    { name: "Tyndale University", path: require("@assets/images/schools/Tyndale_University_logo.png") },
    { name: "University Canada West", path: require("@assets/images/schools/University_Canada_West_logo.png") },
    { name: "University College of the North", path: require("@assets/images/schools/University_College_of_the_North_logo.jpg") },
    { name: "University of Alberta", path: require("@assets/images/schools/University_of_Alberta_logo.png") },
    { name: "University of British Columbia", path: require("@assets/images/schools/University_of_British_Columbia_logo.png") },
    { name: "University of Calgary", path: require("@assets/images/schools/University_of_Calgary_logo.png") },
    { name: "University of Guelph", path: require("@assets/images/schools/University_of_Guelph_logo.png") },
    { name: "University of King's College", path: require("@assets/images/schools/University_of_King's_College_logo.png") },
    { name: "University of Lethbridge", path: require("@assets/images/schools/University_of_Lethbridge_logo.png") },
    { name: "University of Manitoba", path: require("@assets/images/schools/University_of_Manitoba_logo.png") },
    { name: "University of New Brunswick", path: require("@assets/images/schools/University_of_New_Brunswick_logo.png") },
    { name: "University of Northern British Columbia", path: require("@assets/images/schools/University_of_Northern_British_Columbia_logo.png") },
    { name: "University of Ottawa", path: require("@assets/images/schools/University_of_Ottawa_logo.png") },
    { name: "University of Prince Edward Island", path: require("@assets/images/schools/University_of_Prince_Edward_Island_logo.jpg") },
    { name: "University of Regina", path: require("@assets/images/schools/University_of_Regina_logo.png") },
    { name: "University of Saskatchewan", path: require("@assets/images/schools/University_of_Saskatchewan_logo.png") },
    { name: "University of the Fraser Valley", path: require("@assets/images/schools/University_of_the_Fraser_Valley_logo.jpg") },
    { name: "University of Toronto", path: require("@assets/images/schools/University_of_Toronto_logo.png") },
    { name: "University of Victoria", path: require("@assets/images/schools/University_of_Victoria_logo.png") },
    { name: "University of Waterloo", path: require("@assets/images/schools/University_of_Waterloo_logo.png") },
    { name: "University of Western Ontario", path: require("@assets/images/schools/University_of_Western_Ontario_logo.jpg") },
    { name: "University of Windsor", path: require("@assets/images/schools/University_of_Windsor_logo.png") },
    { name: "University of Winnipeg", path: require("@assets/images/schools/University_of_Winnipeg_logo.png") },
    { name: "Vancouver Island University", path: require("@assets/images/schools/Vancouver_Island_University_logo.png") },
    { name: "Wilfrid Laurier University", path: require("@assets/images/schools/Wilfrid_Laurier_University_logo.png") },
    { name: "York University", path: require("@assets/images/schools/York_University_logo.png") },
    { name: "Yukon University", path: require("@assets/images/schools/Yukon_University_logo.png") },
    { name: "Other", path: require("@assets/images/schools/Other_University_logo.png") },
];

const schools = schoolsData.map((school) => school.name)
const schoolLogos = {}
schoolsData.forEach((school) => {
    schoolLogos[school.name] = school.path
})

module.exports = {
    schools,
    schoolLogos
}


