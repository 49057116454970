// Imports
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";

const Stack = createNativeStackNavigator();

// Import screens
import { Meeting } from "../../screens/loggedIn/Sessions/Meeting";
import { Sessions } from "../../screens/loggedIn/Sessions/Sessions";

// Components
export const SessionsStack = ({ navigation, route, params }) => {
	return (
		<Stack.Navigator initialRouteName="Sessions">
			<Stack.Screen
				options={{ headerShown: false }}
				name="Sessions"
				component={Sessions}
			/>
			<Stack.Screen
				options={{ headerShown: false }}
				name="Meeting"
				component={Meeting}
			/>
		</Stack.Navigator>
	);
};
