import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'
import { StyleSheet } from 'react-native'

export const ContentHeader = () => {
    const { text } = useColorsContext()
    const theme = useTheme()
    const defaultStyles = useStyles()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    const styles = StyleSheet.create({
        container: {
            marginBottom: 16,
        },
        headingBig: {
            fontSize: 48,
            fontWeight: 700,
            color: text,
            marginBottom: 12,
            textAlign: 'left',
        },
        headingSmall: {
            fontSize: 24,
            fontWeight: 500,
            color: text,
        },
    })
    return (
        <Box style={styles.container} sx={{ padding: 2 }}>
            <Typography
                variant={isMd ? 'h2' : 'h4'}
                sx={{ fontFamily: defaultStyles.titleFont }}
            >
                Help Center
            </Typography>
            <Typography
                variant={isMd ? 'h6' : 'body1'}
                sx={{ marginTop: isMd ? 2 : 0 }}
            >
                How can we help?
            </Typography>
        </Box>
    )
}
