import React from 'react';
import { useColorsContext } from '@utils/styles';

const ProfileIcon = ({ size = 24 }) => {
    const { text, primaryLight } = useColorsContext();
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_271_7820)">
                <path d="M16.4603 12.249C17.4143 11.1863 18 9.78675 18 8.25C18 4.94175 15.3082 2.25 12 2.25C8.69175 2.25 6 4.94175 6 8.25C6 11.5582 8.69175 14.25 12 14.25C13.1663 14.25 14.2522 13.9102 15.1747 13.332C18.4373 14.5612 20.6835 17.547 20.9692 21H3.0315C3.22575 18.6758 4.31625 16.5082 6.09 14.9618C6.40275 14.6895 6.435 14.2163 6.16275 13.9035C5.89125 13.5915 5.41725 13.5585 5.1045 13.8307C2.81325 15.8272 1.5 18.7132 1.5 21.75C1.5 22.1647 1.836 22.5 2.25 22.5H21.75C22.1647 22.5 22.5 22.1647 22.5 21.75C22.5 17.6355 20.115 13.9613 16.4603 12.249ZM7.5 8.25C7.5 5.76825 9.51825 3.75 12 3.75C14.4818 3.75 16.5 5.76825 16.5 8.25C16.5 10.7318 14.4818 12.75 12 12.75C9.51825 12.75 7.5 10.7318 7.5 8.25Z" fill={text} />
            </g>
            <defs>
                <clipPath id="clip0_271_7820">
                    <rect width="24" height="24" fill={primaryLight} />
                </clipPath>
            </defs>
        </svg>
    );
}

export default ProfileIcon;
