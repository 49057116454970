import { FontAwesome } from "@expo/vector-icons";
import { CloseOutlined } from "@mui/icons-material";
import Slider from "@react-native-community/slider";
import { Link } from "@react-navigation/native";
import { Video } from 'expo-av';
import tutredLogo from "../assets/tutred_logo.png";
import {
  addDoc,
  collection,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  Image,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useMediaQuery } from "react-responsive";

import { useFirebaseContext } from "../contexts/firebaseContext";
import { useUserContext } from "../contexts/userContext";
import { useColorsContext, useStyles } from "../utils/styles";


const hostname = process.env.HOSTNAME

export const PastSessionModal = ({
  pastSessionModal,
  setPastSessionModal,
  session,
  navigation,
}) => {
  // Get db instance
  const { db } = useFirebaseContext();

  // Make sure data is loaded
  if (!session) {
    return null;
  }

  const status = session.status;

  // Colors and styles
  const {
    primary,
    primaryVariant,
    red,
    redVariant,
    background,
    backgroundVariant,
    text,
    textVariant,
    border,
    yellow,
    green,
  } = useColorsContext();
  const defaultStyles = useStyles();

  // Responsive Breakpoints
  const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
  const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  const isSm = useMediaQuery({ query: "(min-width: 640px)" });

  const { user, isTutor, name, profile } = useUserContext();
  const [image, setImage] = useState(
    "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e"
  );
  const [selectedRating, setSelectedRating] = useState(0);
  const [review, setReview] = useState("");
  const [previousReviews, setPreviousReviews] = useState([]);
  const [tutorURL, setTutorURL] = useState("");
  const [studentURL, setStudentURL] = useState("");
  const [videoURL, setVideoURL] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(true);

  const findPreviousReview = async () => {
    const tutor = session.tutorProfile.id;
    const user = session.user.uid;
    const q = query(
      collection(db, "reviews"),
      where("user.uid", "==", user),
      where("tutor", "==", tutor),
      limit(5)
    );

    const unsubscribe = onSnapshot(q, (snapshot) =>
      setPreviousReviews(
        snapshot.docs.map((doc) => ({
          rating: doc.data().rating,
          review: doc.data().review,
          created: doc.data().created.toDate().toLocaleDateString(),
        }))
      )
    );

    return unsubscribe;
  };

  const getTutorURL = async (tutorId) => {
    const q = query(collection(db, "users"), where("uid", "==", tutorId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setTutorURL(doc.data().URL);
    });
  };

  const getStudentURL = async (userId) => {
    const q = query(collection(db, "users"), where("uid", "==", userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setStudentURL(doc.data().URL);
    });
  };

  const getSessionRecording = (sessionId) => {
    fetch(`${hostname}/api/${sessionId}/video`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          setVideoURL(json.file);
          setVideoLoaded(true);
        } else {
          setVideoURL(null);
          setVideoLoaded(true);
        }
      })
      .catch(() => {
        setVideoURL(null);
        setVideoLoaded(true);
      });
  };

  useEffect(() => {
    if (isTutor !== null && session) {
      setVideoURL(null);
      getSessionRecording(session.id);
      if (isTutor) {
        getStudentURL(session.user.uid);
      } else {
        findPreviousReview();
        getTutorURL(session.tutorProfile.id);
      }
      if (isTutor && session.user.image) {
        setImage(session.user.image);
      } else if (!isTutor && session.tutorProfile.picture) {
        setImage(session.tutorProfile.picture);
      }
    }
  }, [session, isTutor]);

  // Create stylesheet
  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      flex: 1,
    },
    maxWidth: {
      flex: 1,
      paddingTop: 30,
      paddingBottom: 30,
      backgroundColor: background,
      display: "flex",
      flexDirection: Platform.OS === "web" ? "row" : null,
      padding: Platform.OS === "web" ? 30 : null,

      width: "100%",
      margin: Platform.OS === "web" ? "auto" : null,
    },
    header: {
      marginTop: 30,
      flexDirection: "column",
      display: "flex",
    },
    bigTitle: {
      color: text,
      fontSize: 36,
      fontFamily: defaultStyles.titleFont,
    },
    title: {
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
    logo: {
      position: "absolute",
      height: 40,
      width: 40,
      resizeMode: "contain",
    },
    layoutGrid: {
      marginTop: 60,
      display: !isLg ? "row" : "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "1fr",
      gridColumnGap: 50,
      gridRowGap: 0,
    },
    colOne: {
      gridArea: 1 / 1 / 2 / 2,
      color: text,
    },
    colTwo: {
      gridArea: 1 / 2 / 2 / 3,
      color: text,
      display: "flex",
      paddingLeft: isLg ? 30 : null,
      marginTop: !isLg ? 30 : 0,
    },
    welcome: {
      fontSize: 16,
      color: text,
      fontFamily: defaultStyles.regularFont,
    },
    name: {
      color: primary,
    },
    icon: {
      width: 50,
      height: 50,
      borderRadius: defaultStyles.radius,
      backgroundColor: "transparent",
      marginRight: 15,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: "auto",
      marginLeft: 5,
    },
    statsGrid: {
      display: "flex",
      flexDirection: isMd ? "row" : "column",
      width: "100%",
      backgroundColor: background,
      border: "1px solid " + border,
      borderRadius: defaultStyles.radius,
      padding: 30,
      marginTop: 30,
    },
    statsTitle: {
      color: textVariant,
      fontSize: 14,
      fontFamily: defaultStyles.regularFont,
    },
    statsIcon: {
      width: 60,
      height: 60,
      borderRadius: defaultStyles.radius,
      backgroundColor: backgroundVariant,
      marginRight: 10,
      justifyContent: "center",
      alignItems: "center",
    },
    statsText: {
      color: text,
      fontFamily: defaultStyles.boldFont,
      fontSize: 22,
    },
    id: {
      fontSize: 12,
      fontFamily: defaultStyles.regularFont,
      color: textVariant,
      fontWeight: 900,
    },
    centeredView: {
      flex: 1,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      backgroundColor: background,
      borderRadius: defaultStyles.radius,
      border: `1px solid ${border}`,
      width: isXl
        ? "40%"
        : isLg
          ? "50%"
          : isMd
            ? "60%"
            : isSm
              ? "70%"
              : "80%",
      height: isMd ? "55%" : "70%",
      padding: 15,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    modalHeader: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 5,
    },
    modalTitle: {
      fontSize: 18,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    joinButton: {
      borderRadius: defaultStyles.buttonRadius,
      height: 35,
      paddingHorizontal: 15,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: primary,
    },
    cancelButton: {
      borderRadius: defaultStyles.buttonRadius,
      height: 35,
      paddingHorizontal: 15,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: red,
      marginLeft: 5,
    },
    nameTitle: {
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
      color: text,
    },
    buttonText: {
      color: "#fff",
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
    img: {
      width: 80,
      height: 80,
      borderRadius: defaultStyles.buttonRadius,
      border: "1px solid " + border,
    },
    details: {
      fontSize: 16,
      color: textVariant,
      fontFamily: defaultStyles.regularFont,
    },
    pickerInfoText: {
      fontSize: 12,
      color: textVariant,
      fontFamily: defaultStyles.lightFont,
      marginBottom: 1,
    },
    infoBorder: {
      borderLeftWidth: 5,
      borderLeftColor: border,
      paddingLeft: 10,
      borderBottomEndRadius: 20,
      minWidth: 250,
    },
    marginTop: { marginVertical: 20 },
    infoContainer: {
      flex: 1,
      display: "flex",
      flexDirection: !isMd ? "column" : "row",
      justifyContent: "space-between",
    },
    reviewInput: {
      width: "100%",
      backgroundColor: backgroundVariant,
      borderRadius: defaultStyles.radius,
      padding: 15,
      marginTop: 5,
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.regularFont,
      textAlignVertical: "top",
      height: 150,
      borderWidth: 1,
      borderStyle: "dashed",
      borderColor: border,
    },
    confirmButton: {
      backgroundColor: primary,
      padding: 15,
      borderRadius: defaultStyles.buttonRadius,
      marginTop: 15,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 150,
      marginBottom: 15,
      height: 35,
    },
    confirmButtonText: {
      color: "#fff",
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
    videoModalView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    videoPlayer: {
      width: "90%",
      aspectRatio: 16 / 9, // This will maintain a 16:9 aspect ratio for the video
      left: 60,
    },
    closeButton: {
      marginRight: 15,
    },
    buttonText: {
      color: "#fff",
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
  });

  const handleReviewSubmit = async () => {
    if (selectedRating === 0) {
      return alert("Please select a rating");
    }

    const reviewsRef = collection(db, "reviews");

    const data = {
      rating: selectedRating,
      review: review,
      user: {
        uid: user.uid,
        name: name,
        picture: user.photoURL,
      },
      tutor: session.tutorProfile.id,
      created: new Date(),
    };

    try {
      addDoc(reviewsRef, data);
    } catch (err) {
      alert("Error submitting review. Please try again later.");
    }
  };

  const PreviousReviewCard = ({ review }) => {
    return (
      <View
        style={{
          backgroundColor: backgroundVariant,
          borderRadius: defaultStyles.radius,
          border: "1px solid " + border,
          padding: 20,
          marginTop: 15,
          width: "100%",
          marginBottom: 15,
        }}
      >
        <Text>
          {[...Array(review.rating)].map((e, i) => {
            return (
              <FontAwesome
                key={i}
                style={{ marginRight: 5 }}
                name="star"
                size={18}
                color={yellow}
              />
            );
          })}
        </Text>

        <Text
          style={{
            marginTop: 10,
            fontFamily: defaultStyles.regularFont,
            fontSize: 16,
            color: text,
          }}
        >
          {review.review}
        </Text>

        <Text
          style={{
            marginTop: 10,
            fontFamily: defaultStyles.regularFont,
            fontSize: 14,
            color: textVariant,
          }}
        >
          {review.created}
        </Text>
      </View>
    );
  };

  return (
    <View>
      <Modal
        key={3}
        animationType="fade"
        transparent={true}
        visible={pastSessionModal}
        onRequestClose={() => {
          setPastSessionModal(!pastSessionModal);
        }}
      >
        {status == "completed" ? (
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={styles.modalHeader}>
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => {
                    setPastSessionModal(!pastSessionModal);
                  }}
                >
                  <CloseOutlined style={{ fontSize: 22, color: text }} />
                </TouchableOpacity>

                <Text style={styles.modalTitle}>Past Session</Text>
              </View>

              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{
                  paddingHorizontal: 30,
                  paddingVertical: 30,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Image source={{ uri: image }} style={styles.img} />
                  <View
                    style={{
                      flexDirection: "column",
                      marginLeft: 15,
                    }}
                  >
                    <View>
                      {!isTutor ? (
                        <Link
                          onPress={() => {
                            setPastSessionModal(!pastSessionModal);
                          }}
                          to={
                            tutorURL
                              ? `/tutors/${tutorURL}`
                              : `/tutors/${session.tutorProfile.uid}`
                          }
                        >
                          <Text
                            style={[
                              styles.nameTitle,
                              {
                                fontSize: 22,
                                fontFamily: defaultStyles.boldFont,
                              },
                            ]}
                          >
                            {session.tutorProfile.name.split(" ").length > 1
                              ? session.tutorProfile.name.split(" ")[0] +
                              " " +
                              session.tutorProfile.name.split(" ")[1][0]
                              : session.tutorProfile.name}
                          </Text>
                        </Link>
                      ) : (
                        <Link
                          onPress={() => {
                            setPastSessionModal(!pastSessionModal);
                          }}
                          to={
                            studentURL
                              ? `/students/${studentURL}`
                              : `/students/${session.user.uid}`
                          }
                        >
                          <Text
                            style={[
                              styles.nameTitle,
                              {
                                fontSize: 22,
                                fontFamily: defaultStyles.boldFont,
                              },
                            ]}
                          >
                            {session.user.name.split(" ").length > 1
                              ? session.user.name.split(" ")[0] +
                              " " +
                              session.user.name.split(" ")[1][0]
                              : session.user.name}
                          </Text>
                        </Link>
                      )}
                    </View>
                    <Text
                      style={{
                        color: green,
                        paddingTop: 5,
                        fontFamily: defaultStyles.boldFont,
                      }}
                    >
                      Completed
                    </Text>

                    <TouchableOpacity
                      style={{
                        backgroundColor: primary,
                        padding: 10,
                        height: 35,
                        borderRadius: defaultStyles.buttonRadius,
                        marginTop: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onPress={() => {
                        const chatsRef = collection(db, "chats");
                        const q = query(
                          chatsRef,
                          where(
                            "tutor.uid",
                            "==",
                            session.tutorProfile.uid
                          ),
                          where("user.uid", "==", session.user.uid)
                        );
                        getDocs(q).then((querySnapshot) => {
                          if (querySnapshot.docs.length > 0) {
                            var hoursSinceOnline = null;
                            navigation.navigate("Chats", {
                              screen: "Chats",
                              params: {
                                chat: querySnapshot.docs[0].id,
                                lo: hoursSinceOnline,
                              },
                            });
                            setPastSessionModal(!pastSessionModal);
                            return;
                          } else {
                            const chatsRef = collection(db, "chats");
                            var hoursSinceOnline = null;

                            addDoc(chatsRef, {
                              tutor: {
                                name: session.tutorProfile.name,
                                image: session.tutorProfile.picture
                                  ? session.tutorProfile.picture
                                  : null,
                                uid: session.tutorProfile.uid,
                                id: session.tutorProfile.uid,
                              },
                              user: {
                                name: name,
                                image: session.user.image,
                                uid: session.user.uid,
                                id: session.user.uid,
                              },
                              created: new Date(),
                              lastUpdated: new Date(),
                              lastMessage: {},
                            }).then((docRef) => {
                              setPastSessionModal(!pastSessionModal);
                              navigation.navigate("Chats", {
                                screen: "Chats",
                                params: {
                                  chat: docRef.id,
                                  lo: hoursSinceOnline,
                                },
                              });
                              return;
                            });
                          }
                        });
                      }}
                    >
                      <Text
                        style={{
                          color: "#fff",
                          fontSize: 16,
                          fontFamily: defaultStyles.boldFont,
                        }}
                      >
                        Message
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <View style={styles.infoContainer}>
                  <View style={[styles.marginTop, styles.infoBorder]}>
                    <Text style={styles.title}>Session date</Text>
                    <Text style={styles.pickerInfoText}>
                      Requested date of session.
                    </Text>
                    <Text style={styles.details}>{session.date}</Text>
                  </View>

                  <View style={[styles.marginTop, styles.infoBorder]}>
                    <Text style={styles.title}>{isTutor ? "Earned" : "Cost"}</Text>
                    <Text style={styles.pickerInfoText}>
                      Total session amount.
                    </Text>
                    {isTutor ?
                      <Text style={[styles.details, { color: green }]}>
                        ${session.earnings ? session.earnings : "N/A"}
                      </Text>
                      :
                      <Text style={[styles.details, { color: green }]}>
                        ${session.sessionFee ? session.sessionFee : "N/A"}
                      </Text>
                    }

                  </View>
                </View>

                <View style={styles.infoContainer}>
                  <View style={[styles.marginTop, styles.infoBorder]}>
                    <Text style={styles.title}>Completed at</Text>
                    <Text style={styles.pickerInfoText}>
                      Session was completed at.
                    </Text>
                    <Text style={styles.details}>
                      {session.completedAt == null
                        ? "N/A"
                        : session.completedAt.toDate().toLocaleTimeString()}
                    </Text>
                  </View>

                  <View style={[styles.marginTop, styles.infoBorder]}>
                    <Text style={styles.title}>Status</Text>
                    <Text style={styles.pickerInfoText}>Status of session.</Text>
                    <Text style={[styles.details, { color: green }]}>
                      Completed
                    </Text>
                  </View>
                </View>

                <View style={styles.infoContainer}>
                  <View style={[styles.marginTop, styles.infoBorder]}>
                    <Text style={styles.title}>Recording</Text>
                    <Text style={styles.pickerInfoText}>
                      Recording of session.
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setVideoModalVisible(true);
                      }}
                    >
                      <Text style={[styles.details, { color: green }]}>
                        {videoLoaded
                          ? videoURL
                            ? "View Recording"
                            : "No Recording"
                          : "Loading..."}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>

                {!isTutor ? (
                  <>
                    {previousReviews.length > 0 ? (
                      <View>
                        {previousReviews.map((review, i) => {
                          return (
                            <PreviousReviewCard
                              key={i}
                              review={review}
                            ></PreviousReviewCard>
                          );
                        })}
                      </View>
                    ) : (
                      <View>
                        <Text
                          style={[
                            styles.title,
                            { marginTop: 20 },
                          ]}
                        >
                          Enjoyed your session? Leave a review!
                        </Text>
                        <Text style={{ marginTop: 15 }}>
                          {[...Array(selectedRating)].map((e, i) => {
                            return (
                              <FontAwesome
                                key={i}
                                style={{ marginRight: 5 }}
                                name="star"
                                size={18}
                                color={yellow}
                              />
                            );
                          })}
                        </Text>
                        <Slider
                          style={{
                            width: "100%",
                            height: 40,
                          }}
                          step={1}
                          value={selectedRating}
                          minimumValue={0}
                          maximumValue={5}
                          onValueChange={(val) => setSelectedRating(val)}
                          minimumTrackTintColor={primary}
                          maximumTrackTintColor={border}
                          thumbTintColor={primary}
                        />
                        <TextInput
                          multiline={true}
                          style={[styles.reviewInput]}
                          placeholder="Write a review"
                          placeholderTextColor={textVariant}
                          value={review}
                          onChangeText={(text) => setReview(text)}
                        />
                        <TouchableOpacity
                          style={[
                            styles.confirmButton,
                            { width: "100%" },
                          ]}
                          onPress={handleReviewSubmit}
                        >
                          <Text style={styles.confirmButtonText}>
                            Submit
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </>
                ) : null}
              </ScrollView>
            </View>
          </View>
        ) : (
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={styles.modalHeader}>
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => {
                    setPastSessionModal(!pastSessionModal);
                  }}
                >
                  <CloseOutlined style={{ fontSize: 22, color: text }} />
                </TouchableOpacity>

                <Text style={styles.modalTitle}>Past Session</Text>
              </View>

              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{
                  paddingHorizontal: 30,
                  paddingVertical: 30,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Image
                    source={{
                      uri: image,
                    }}
                    style={styles.img}
                  />
                  <View
                    style={{
                      flexDirection: "column",
                      marginLeft: 15,
                    }}
                  >
                    <View>
                      {isTutor ? (
                        <Link
                          onPress={() => {
                            setPastSessionModal(!pastSessionModal);
                          }}
                          to={`/students/${studentURL}`}
                        >
                          <Text
                            style={[
                              styles.nameTitle,
                              {
                                fontSize: 22,
                                fontFamily: defaultStyles.boldFont,
                              },
                            ]}
                          >
                            {session.user.name.split(" ").length > 1
                              ? session.user.name.split(" ")[0] +
                              " " +
                              session.user.name.split(" ")[1][0]
                              : session.user.name}
                          </Text>
                        </Link>
                      ) : (
                        <Link
                          onPress={() => {
                            setPastSessionModal(!pastSessionModal);
                          }}
                          to={`/tutors/${tutorURL}`}
                        >
                          <Text
                            style={[
                              styles.nameTitle,
                              {
                                fontSize: 22,
                                fontFamily: defaultStyles.boldFont,
                              },
                            ]}
                          >
                            {session.tutorProfile.name.split(" ").length > 1
                              ? session.tutorProfile.name.split(" ")[0] +
                              " " +
                              session.tutorProfile.name.split(" ")[1][0]
                              : session.tutorProfile.name}
                          </Text>
                        </Link>
                      )}

                      <Text
                        style={{
                          color: redVariant,
                          paddingTop: 5,
                          fontFamily: defaultStyles.boldFont,
                        }}
                      >
                        Cancelled
                      </Text>
                      <TouchableOpacity
                        style={{
                          backgroundColor: primary,
                          padding: 15,
                          height: 35,
                          borderRadius:
                            defaultStyles.buttonRadius,
                          marginTop: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onPress={() => {
                          const chatsRef = collection(db, "chats");
                          const q = query(
                            chatsRef,
                            where(
                              "tutor.uid",
                              "==",
                              session.tutorProfile.uid
                            ),
                            where("user.uid", "==", session.user.uid)
                          );
                          getDocs(q).then((querySnapshot) => {
                            if (querySnapshot.docs.length > 0) {
                              var hoursSinceOnline = null;
                              navigation.navigate("Chats", {
                                screen: "Chats",
                                params: {
                                  chat: querySnapshot.docs[0].id,
                                  lo: hoursSinceOnline,
                                },
                              });
                              setPastSessionModal(!pastSessionModal);
                              return;
                            } else {
                              const chatsRef = collection(
                                db,
                                "chats"
                              );
                              var hoursSinceOnline = null;

                              addDoc(chatsRef, {
                                tutor: {
                                  name: session.tutorProfile.name,
                                  image: session.tutorProfile.picture
                                    ? session.tutorProfile.picture
                                    : null,
                                  uid: session.tutorProfile.uid,
                                  id: session.tutorProfile.uid,
                                },
                                user: {
                                  name: name,
                                  image: session.user.image,
                                  uid: session.user.uid,
                                  id: session.user.uid,
                                },
                                created: new Date(),
                                lastUpdated: new Date(),
                                lastMessage: {},
                              }).then((docRef) => {
                                setPastSessionModal(!pastSessionModal);
                                navigation.navigate("Chats", {
                                  screen: "Chats",
                                  params: {
                                    chat: docRef.id,
                                    lo: hoursSinceOnline,
                                  },
                                });
                                return;
                              });
                            }
                          });
                        }}
                      >
                        <Text
                          style={{
                            color: "#fff",
                            fontSize: 16,
                            fontFamily:
                              defaultStyles.boldFont,
                          }}
                        >
                          Message
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>

                <View style={styles.infoContainer}>
                  <View style={[styles.marginTop, styles.infoBorder]}>
                    <Text style={styles.title}>Session date</Text>
                    <Text style={styles.pickerInfoText}>
                      Requested date of session.
                    </Text>
                    <Text style={styles.details}>{session.date}</Text>
                  </View>

                  <View style={[styles.marginTop, styles.infoBorder]}>
                    <Text style={styles.title}>{isTutor ? "Earned" : "Cost"}</Text>
                    <Text style={styles.pickerInfoText}>
                      {isTutor ? "Total session earnings." : "Total session amount."}
                    </Text>
                    <Text style={styles.details}>$0.00</Text>
                  </View>
                </View>

                <View style={styles.infoContainer}>
                  <View style={[styles.marginTop, styles.infoBorder]}>
                    <Text style={styles.title}>Cancelled on</Text>
                    <Text style={styles.pickerInfoText}>
                      Date the session was cancelled.
                    </Text>
                    <Text
                      style={[
                        styles.details,
                        { color: redVariant },
                      ]}
                    >
                      {session.canceledAt
                        ? session.canceledAt
                          .toDate()
                          .toLocaleDateString()
                        : "N/A"}
                    </Text>
                  </View>

                  <View style={[styles.marginTop, styles.infoBorder]}>
                    <Text style={styles.title}>Cancelled by</Text>
                    <Text style={styles.pickerInfoText}>
                      This session was cancelled by.
                    </Text>
                    <Text style={styles.details}>
                      {session.user.name.split(" ").length > 1
                        ? session.user.name.split(" ")[0] +
                        " " +
                        session.user.name.split(" ")[1][0]
                        : session.user.name}{" "}
                    </Text>
                  </View>
                </View>

                <View style={styles.infoContainer}>
                  <View style={[styles.marginTop, styles.infoBorder]}>
                    <Text style={styles.title}>Recording</Text>
                    <Text style={styles.pickerInfoText}>
                      Recording of session.
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setVideoModalVisible(true);
                      }}
                    >
                      <Text
                        style={[
                          styles.details,
                          { color: green },
                        ]}
                      >
                        {videoURL
                          ? "View Recording"
                          : "Not available"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </ScrollView>
            </View>
          </View>
        )}
      </Modal>

      {videoModalVisible && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={videoModalVisible}
          onRequestClose={() => {
            setVideoModalVisible(!videoModalVisible);
          }}
        >
          <View
            style={styles.videoModalView}
            onMouseEnter={() => setControlsVisible(true)}
            onMouseLeave={() => setControlsVisible(true)}
          >
            <Image source={tutredLogo} style={styles.logo} />

            <Video
              source={{ uri: videoURL }}
              rate={1.0}
              volume={1.0}
              isMuted={false}
              resizeMode="contain"
              shouldPlay
              useNativeControls={controlsVisible}
              style={styles.videoPlayer}
            />
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setVideoModalVisible(false)}
            >
              <Text style={styles.buttonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </Modal>
      )}
    </View>
  );
};
