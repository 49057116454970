import * as React from 'react'
import Box from '@mui/material/Box'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useUserContext } from '@contexts/userContext'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { FilterType } from 'dataTypes'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { FlatList } from 'react-native'
import { TutorListCard } from '@components/TutorListCard'
import useNavigateToChat from '@hooks/useNavigateToChat'
import { specialties, subjects } from '@config/subjects'
import { provinces, statesUSA } from '@config/locations'
import { levels } from '@config/levels'
import { exams } from '@config/exams'
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useColorsContext } from '@utils/styles'
import EmptyTutorList from './EmptyTutorList'
import { TutorListLargeDrawer } from './TutorListLargeDrawer'
import { LoginDialog } from '@components/LoginDialog'
import { useDialog } from '@contexts/dialogContext'

const locationOptions = [...provinces, ...statesUSA]
const examOptions = exams.map((exam) => {
    return {
        id: exam.value,
        label: exam.label,
    }
})

const levelOptions = levels.map((level) => {
    return {
        id: level.value,
        label: level.label,
    }
})

const specialtyOptions = Object.keys(specialties)
    .map((key) => {
        return specialties[key].map((specialty) => specialty)
    })
    .flat()
    .sort()

const defaultFilterState = {
    subjects: {
        type: 'array',
        value: [],
        label: 'Subjects',
        options: subjects,
        optionType: 'string',
        valueType: 'keyIndex',
        query: `array-contains-any`,
    },
    ['location.province']: {
        type: 'array',
        value: [],
        label: 'Location',
        query: 'in',
        options: [...locationOptions],
        optionType: 'string',
    },
    rate: {
        type: 'range',
        value: [0, 75],
        label: 'Price',
        maxValue: 75,
        minValue: 0,
        query: 'range',
    },
    educationLevels: {
        type: 'array',
        value: [],
        label: 'Level',
        options: [...levelOptions],
        optionType: 'object',
        query: 'array-contains-any',
    },
    availability: {
        type: 'array',
        value: [],
        label: 'Availability',
        options: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ],
        optionType: 'string',
        query: 'availability',
    },
    skills: {
        type: 'array',
        value: [],
        label: 'Specialties',
        options: specialtyOptions,
        optionType: 'string',
        query: 'array-contains-any',
    },
    rating: {
        type: 'range',
        value: [0, 5],
        maxValue: 5,
        minValue: 0,
        label: 'Rating',
        query: 'rating',
        marks: [
            {
                value: 0,
                label: '0',
            },
            {
                value: 1,
                label: '1',
            },
            {
                value: 2,
                label: '2',
            },
            {
                value: 3,
                label: '3',
            },
            {
                value: 4,
                label: '4',
            },
            {
                value: 5,
                label: '5',
            },
        ],
    },
    isVerified: {
        type: 'boolean',
        value: null,
        label: 'Verified',
        query: '==',
    },
    examTutor: {
        type: 'array',
        value: [],
        label: 'Exams',
        options: [...examOptions],
        optionType: 'object',
        query: 'array-contains-any',
    },
    instaBook: {
        type: 'boolean',
        value: null,
        label: 'InstaBook',
        query: '==',
    },
}

export default function ClippedDrawer() {
    const navigation: any = useNavigation()
    const { user } = useUserContext()
    const { background, text } = useColorsContext()
    const [tutors, setTutors] = React.useState([])
    const [sortBy, setSortBy] = React.useState('online')
    const [loading, setLoading] = React.useState(true)
    const [filters, setFilters] =
        React.useState<Record<string, FilterType>>(defaultFilterState)
    const { db } = useFirebaseContext()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const route = useRoute()
    const { openLoginDialog } = useDialog()
    React.useEffect(() => {
        console.log('route.params', route)
        if (route.params?.subject) {
            setFilters({
                ...filters,
                subjects: {
                    ...filters.subjects,
                    value: [route.params.subject],
                },
            })
        }
    }, [route])

    const fetchTutors = async () => {
        setLoading(true)
        let tutorsRef = collection(db, 'users')
        let q = query(tutorsRef)
        q = query(q, where('isTutor', '==', true))
        // q = query(q, where('isOnboarded', '==', true))
        q = query(q, where('isSignedUp', '==', true))
        const queryResults = {}
        Object.keys(filters).forEach((key) => {
            if (
                filters[key].value === null ||
                filters[key].value.length === 0 ||
                filters[key].value === false
            )
                return
            // Firebase only supports "array-contains-any" filter per query and it also cant be combined with 'in' for the same query,
            // If we run querys on data stored like "Key": true, and use "==" queries" it requires a very large amount of indexes to work.
            // This isnt ideal so we have to handle these locally.
            if (
                filters[key].query === 'array-contains-any' ||
                filters[key].query === 'availability' ||
                filters[key].query === 'rating'
            ) {
                return
            }

            if (filters[key].query === 'range') {
                if (
                    filters[key].value[0] === filters[key].minValue &&
                    filters[key].value[1] === filters[key].maxValue
                )
                    return
                q = query(
                    q,
                    where(key, '>=', filters[key].value[0]),
                    where(key, '<=', filters[key].value[1])
                )
            } else {
                let filterValue = filters[key].value

                if (filters[key].optionType === 'object') {
                    filterValue = filters[key].value.map((val) => val.id)
                }

                q = query(q, where(key, filters[key].query, filterValue))
            }
        })

        onSnapshot(q, async (snapshot) => {
            let results = snapshot.docs.map((doc) => doc.data())
            Object.keys(filters).forEach((key) => {
                if (
                    filters[key].value === null ||
                    filters[key].value.length === 0
                )
                    return
                if (filters[key].query === 'array-contains-any') {
                    let filterValue = filters[key].value

                    if (filters[key].optionType === 'object') {
                        filterValue = filters[key].value.map((val) => val.id)
                    }

                    results = results.filter((result) => {
                        let tutorVals = result[key]
                        if (
                            filters[key].valueType === 'keyIndex' &&
                            tutorVals
                        ) {
                            tutorVals = Object.keys(tutorVals).map((key) => key)
                        }
                        let filtersVals = filterValue
                        if (tutorVals?.length > 0) {
                            return filtersVals.every((val) =>
                                tutorVals.includes(val)
                            )
                        }
                    })
                }
                if (
                    filters[key].query === 'availability' &&
                    filters[key].value.length > 0
                ) {
                    results = results.filter((result) => {
                        let availableDates = result.availability || {}
                        let isAvailable = filters[key].value.some(
                            (available) => {
                                const day = available.toLowerCase()
                                return (
                                    availableDates[day] &&
                                    availableDates[day].start !== null &&
                                    availableDates[day].end !== null
                                )
                            }
                        )
                        return result?.alwaysAvailable || isAvailable
                    })
                }
            })
            setTutors(results)
            setLoading(false)
        })
    }

    React.useEffect(() => {
        fetchTutors()
    }, [filters])

    const tutorResults = React.useMemo(() => {
        if (sortBy === 'online') {
            return tutors.sort((a, b) => b.lastOnline - a.lastOnline)
        } else if (sortBy === 'low-rate') {
            return tutors.sort((a, b) => a.rate - b.rate)
        } else if (sortBy === 'high-rate') {
            return tutors.sort((a, b) => b.rate - a.rate)
        }
    }, [tutors, sortBy])

    const { navigateToChat } = useNavigateToChat()

    const renderItem = React.useCallback(
        ({ item }) => {
            return (
                <TutorListCard
                    maxRating={filters.rating.value[1]}
                    minRating={filters.rating.value[0]}
                    navigation={navigation}
                    tutor={item}
                    navigateToChat={(tutor, user, profile) => {
                        if (!user) {
                            openLoginDialog('Please login to send a message')
                            return
                        }

                        navigateToChat(tutor, user, profile)
                    }}
                />
            )
        },
        [navigation, user, navigateToChat, filters]
    )

    const keyExtractor = React.useCallback((item) => item.id, [])

    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    overflow: 'auto',
                    backgroundColor: background,
                }}
            >
                <Box
                    sx={{
                        zIndex: 9999,
                        position: 'fixed',
                        width: '100%',
                    }}
                ></Box>
                {isMd && (
                    <TutorListLargeDrawer
                        filters={filters}
                        setFilters={setFilters}
                    />
                )}
                <Box
                    component="main"
                    sx={{
                        p: isMd ? 3 : 0,
                        overflow: 'scroll',
                        flexDirection: 'column',
                        flex: 1,
                        display: 'flex',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small-label">
                                Sort By
                            </InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label="Sort By"
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                            >
                                <MenuItem value="online">Online</MenuItem>
                                <MenuItem value="low-rate">
                                    Lowest price
                                </MenuItem>
                                <MenuItem value="high-rate">
                                    Highest price
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <FlatList
                        style={{ display: 'flex' }}
                        contentContainerStyle={{
                            padding: 16,
                            flex: 1,
                        }}
                        ListEmptyComponent={
                            <EmptyTutorList
                                resetFilters={() => {
                                    setFilters(defaultFilterState)
                                }}
                                loading={loading}
                            />
                        }
                        keyExtractor={keyExtractor}
                        data={tutorResults}
                        renderItem={renderItem}
                    />
                </Box>
            </Box>
        </>
    )
}
