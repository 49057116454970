import { TutorReview } from '@components/TutorReview'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { Box, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { collection, getDocs, query, where } from 'firebase/firestore'
import React from 'react'
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native'

interface Props {
    tutor: any
}

const Reviews: React.FC<Props> = ({ tutor }) => {
    const { db } = useFirebaseContext()

    const { data, isLoading } = useQuery({
        queryKey: ['Tutor Reviews', tutor.id],
        queryFn: async () => {
            const reviewsRef = collection(db, 'reviews')
            const q = query(reviewsRef, where('tutor', '==', tutor.uid))

            const querySnapshot = await getDocs(q)
            const reviews = querySnapshot.docs.map((doc) => doc.data())
            return reviews
        },
        enabled: !!tutor && !!db,
    })

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <ActivityIndicator size={'large'} />
                <Typography
                    variant="h6"
                    sx={{ textAlign: 'center', marginTop: 4 }}
                >
                    Loading Reviews
                </Typography>
            </Box>
        )
    }
    if (!isLoading && !data?.length) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                    No one has left a review for this tutor yet.
                </Typography>
            </Box>
        )
    }
    return (
        <Box sx={{ marginTop: 3 }}>
            {data?.length &&
                data.map((review, index) => {
                    return (
                        <TutorReview
                            key={review.sessionId || `review-${index}`}
                            user={review.user}
                            review={review}
                            tutor={tutor}
                        ></TutorReview>
                    )
                })}
        </Box>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default Reviews
