// This is a modal that will be shown when the user clicks on the "Create Listing" button
import React, { useCallback, useEffect, useRef, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import {
	Platform,
	Appearance,
	View,
	Text,
	StyleSheet,
	TouchableOpacity,
	ScrollView,
	Modal,
	Image,
	TextInput,
	Alert,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import { FontAwesome, FontAwesome5, AntDesign } from "@expo/vector-icons";
import {
	collection,
	updateDoc,
	doc,
	addDoc,
	onSnapshot,
	where,
	query,
	getDocs,
	serverTimestamp,
} from "firebase/firestore";
import { useMediaQuery } from "react-responsive";
import { CloseOutlined } from "@mui/icons-material";
import { useAlert } from "react-alert";

// File imports
import { useColorsContext } from "../utils/styles";
import { useStyles } from "../utils/styles";
import { useUserContext } from "../contexts/userContext";
import { useFirebaseContext } from "../contexts/firebaseContext";

// import { cities, provinces, countries } from '../config/locations';
// import { subjects } from '../config/subjects';
// import { schools } from '../config/schools';
// import { languages } from '../config/languages';

import { subjects } from "../config/subjects";
import { schools } from "../config/schools";
import { languages as languageValues } from "../config/languages";

export default function CreateListingModal({ visible, setVisible }) {
	// State
	const [showModal, setShowModal] = useState(visible);
	const [description, setDescription] = useState("");
	const [title, setTitle] = useState("");
	const [subject, setSubject] = useState("Select subject");
	const [skills, setSkills] = useState("Select skill");

	// DATA
	const [cityValues, setCityValues] = useState([]);
	const [provinceValues, setProvinceValues] = useState([]);
	const [countryValues, setCountryValues] = useState([]);
	const [subjectValues, setSubjectValues] = useState([]);
	const [skillValues, setSkillValues] = useState([]);
	const [languages, setLanguages] = useState(languageValues[0]);

	useEffect(() => {
		// Subjects
		if (subjects) {
			var subjectValues = [];
			let subjectKeys = Object.keys(subjects);
			subjectKeys.map((subject) => {
				subjectValues.push({
					subject: subject,
					enabled: true,
				});
			});

			// Sort alphabetically
			subjectValues.sort((a, b) => {
				if (a.subject < b.subject) {
					return -1;
				}
				if (a.subject > b.subject) {
					return 1;
				}
				return 0;
			});

			// Set to top of array
			subjectValues.unshift({
				subject: "Select subject",
				enabled: false,
			});
			setSubjectValues(subjectValues);
		}
	}, []);

	useEffect(() => {
		// Get skills for selected subjects
		if (subjects) {
			var skillValues = [];
			var allSkills = [];
			let subjectKeys = Object.keys(subjects);
			subjectKeys.map((subjectKey) => {
				if (subjectKey == subject) {
					let skills = subjects[subjectKey];
					skills.map((skill) => {
						skillValues.push({
							skill: skill,
							enabled: true,
						});
					});
				} else {
					let skills = subjects[subjectKey];
					skills.map((skill) => {
						allSkills.push({
							skill: skill,
							enabled: true,
						});
					});
				}
			});
			skillValues.sort((a, b) => {
				if (a.skill < b.skill) {
					return -1;
				}
				if (a.skill > b.skill) {
					return 1;
				}
				return 0;
			});

			allSkills.sort((a, b) => {
				if (a.skill < b.skill) {
					return -1;
				}
				if (a.skill > b.skill) {
					return 1;
				}
				return 0;
			});

			if (subject) {
				// Set to top of array
				skillValues.unshift({ skill: "Select skill", enabled: false });
				setSkillValues(skillValues);
			} else {
				allSkills.unshift({ skill: "Select skill", enabled: false });
				setSkillValues(allSkills);
			}
		}
	}, [subject, subjects]);

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Init alert
	const customAlert = useAlert();

	// Init firebase
	const { db } = useFirebaseContext();

	// Init user
	const { user, name, profile } = useUserContext();

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	const handleSubmit = async () => {
		// Validate inputs
		if (!title) {
			setVisible(false);
			setTimeout(() => {
				customAlert.error("Please enter a title.");
			}, 200);
			return;
		}
		if (!description) {
			setVisible(false);
			setTimeout(() => {
				customAlert.error("Please enter a description.");
			}, 200);
			return;
		}
		if (!subject) {
			setVisible(false);
			setTimeout(() => {
				customAlert.error("Please select a subject.");
			}, 200);
			return;
		}
		var skillsArr = [];
		if (!skills || skills == "" || skills == "Select skill") {
			skillsArr = [];
		} else {
			// Check if skills is an array
			if (Array.isArray(skills)) {
				skillsArr = skills;
			} else {
				skillsArr = [skills];
			}
		}

		// Submit post
		addDoc(collection(db, "listings"), {
			title: title,
			description: description,
			subject: subject,
			skills: skillsArr,
			languages: [languages],
			user: {
				uid: user.uid,
				name: name,
				profile: profile,
			},

			createdAt: new Date(),
		})
			.then(() => {
				customAlert.success("Post submitted, thank you!");
				setVisible(false);
				// Reset state
				setTitle("");
				setDescription("");
				setSubject("");
				setSkills([]);
				// setShowModal(false);
				setVisible(false);

				return;
			})
			.catch((error) => {
				customAlert.error(
					"There was an error, please try again later."
				);
				return;
			});
	};

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			paddingTop: 30,
			paddingBottom: 30,
			backgroundColor: background,
			display: "flex",
			padding: Platform.OS === "web" ? 30 : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			marginTop: 30,
			flexDirection: "column",
			display: "flex",
		},

		title: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
		},
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: background,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "40%"
				: isLg
				? "50%"
				: isMd
				? "60%"
				: isSm
				? "70%"
				: "90%",
			height: isMd ? "70%" : "95%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			alignItems: "center",
			justifyContent: " space-between",
			marginBottom: 5,
		},
		closeButton: {
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		title: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: text,
			marginTop: 15,
		},

		marginTop: { marginVertical: 20 },
		infoContainer: {
			flex: 1,
			display: "flex",
			flexDirection: !isMd ? "column" : "row",
			justifyContent: "space-between",
		},
		mainContainer: {
			flex: 1,
			padding: 30,
		},

		button: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			borderRadius: defaultStyles.buttonRadius,
			padding: 15,
			alignItems: "center",
		},
		buttonText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		descriptionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 275,
		},
		sessionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 50,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
		details: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
	});

	// Skills / subject / language component
	const WordCard = ({ word, array }) => {
		return (
			<View
				style={{
					marginRight: 5,
					marginTop: 10,
					backgroundColor: backgroundVariant,
					borderRadius: defaultStyles.buttonRadius,
					padding: 5,
					border: `.5px solid ${border}`,
					display: "flex",
					flexDirection: "row",
					alignContent: "center",
					alignItems: "center",
					justifyContent: "space-evenly",
					// maxWidth: 150,
					marginBottom: 10,
					height: 50,
				}}
			>
				<Text
					style={{
						fontSize: 14,
						color: textVariant,
						fontFamily: defaultStyles.regularFont,
						padding: 5,
					}}
				>
					{word}
				</Text>
				<TouchableOpacity
					style={{ paddingHorizontal: 5 }}
					onPress={() => {
						// Remove from subjects array
						if (array == "subject") {
							let newArray = [...subject];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSubject(newArray);
						}
						// Remove from skills array
						else if (array == "skill") {
							let newArray = [...skills];
							newArray = newArray.filter(function (item) {
								return item !== word;
							});
							// Set state to mutated array
							setSkills(newArray);
						}
					}}
				>
					<CloseOutlined
						style={{ fontSize: 14, color: text }}
					></CloseOutlined>
				</TouchableOpacity>
			</View>
		);
	};

	return (
		<View>
			<Modal
				key={3}
				animationType="fade"
				transparent={true}
				visible={visible}
				onRequestClose={() => {
					//   setContactModal(!showModal);
					setVisible(false);
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={styles.modalHeader}>
							<View style={{ flexDirection: "row" }}>
								{/* Close filters button */}
								<TouchableOpacity
									style={styles.closeButton}
									onPress={() => {
										// setShowModal(!showModal);
										setVisible(false);
									}}
								>
									<CloseOutlined
										style={{ fontSize: 22, color: text }}
									></CloseOutlined>
								</TouchableOpacity>

								{/* Title */}
								<Text style={styles.modalTitle}>
									Create a listing
								</Text>
							</View>
							<TouchableOpacity
								style={{
									backgroundColor: primary,
									padding: 10,
									borderRadius: defaultStyles.buttonRadius,
								}}
								onPress={() => {
									handleSubmit();
									// setOptions(reasons[0].label);
									// setDescription('');
									// setSessionId('');
								}}
							>
								<Text
									style={{
										color: "#fff",
										fontSize: 14,
										fontFamily: defaultStyles.boldFont,
									}}
								>
									Post Listing
								</Text>
							</TouchableOpacity>
						</View>
						<View style={styles.mainContainer}>
							{/* Title  */}
							<Text style={[styles.title, { marginTop: -15 }]}>
								Title
							</Text>
							<TextInput
								placeholderTextColor="#999999"
								style={styles.sessionInput}
								placeholder="Title"
								value={title}
								onChange={(e) => {
									setTitle(e.target.value);
								}}
								maxLength={50}
							></TextInput>
							{/* Subject  */}
							{/* {subject.map((doc, index) => {
                return (
                  <WordCard key={index} word={subject[index]} array='subject'>
                    
                  </WordCard>
                );
              })} */}
							<Text style={styles.title}>Subject</Text>
							<Picker
								selectedValue={subject}
								style={styles.picker}
								onValueChange={(itemValue, itemIndex) =>
									setSubject(itemValue)
								}
							>
								{subjectValues.map((subject, index) => (
									<Picker.Item
										key={index}
										label={subject.subject}
										value={subject.subject}
										enabled={subject.enabled}
									/>
								))}
							</Picker>
							{/* Skills  */}
							{/* {skills.map((doc, index) => {
                return (
                  <WordCard key={index} word={skills[index]} array='skill'>
                    
                  </WordCard>
                );
              })} */}
							<Text style={styles.title}>Skills</Text>
							<Picker
								selectedValue={skills}
								style={styles.picker}
								onValueChange={(itemValue, itemIndex) =>
									setSkills([itemValue])
								}
							>
								{skillValues.map((skill, index) => (
									<Picker.Item
										key={index}
										label={skill.skill}
										// value={skill.skill}
										value={skill.skill}
										enabled={skill.enabled}
									/>
								))}
							</Picker>

							{/* Language */}
							<Text style={styles.title}>Language</Text>
							<Picker
								selectedValue={languages}
								style={styles.picker}
								onValueChange={(itemValue, itemIndex) =>
									setLanguages(itemValue)
								}
							>
								{languageValues.map((language, index) => (
									<Picker.Item
										key={index}
										label={language}
										value={language}
									/>
								))}
							</Picker>

							{/* Description  */}
							<TextInput
								placeholderTextColor="#999999"
								style={styles.descriptionInput}
								placeholder="I need help with...."
								multiline={true}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								maxLength={1000}
							></TextInput>
							<Text style={[styles.details, { padding: 5 }]}>
								{description.length} / 1000
							</Text>
						</View>
					</View>
				</View>
			</Modal>
		</View>
	);
}
