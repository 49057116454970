import { CloseOutlined } from '@mui/icons-material'
import { Divider, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'
import { View, Text, StyleSheet, Modal, TouchableOpacity } from 'react-native'

interface TutredModalProps {
    visible: boolean
    onClose: () => void
    children: React.ReactNode
    title: string
}

const TutredModal: React.FC<TutredModalProps> = ({
    visible,
    onClose,
    children,
    title,
}) => {
    const { background, text, border } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isXl = useMediaQuery(theme.breakpoints.up('xl'))
    const isLg = useMediaQuery(theme.breakpoints.up('lg'))
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    const styles = StyleSheet.create({
        centeredView: {
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalView: {
            backgroundColor: background,
            borderRadius: defaultStyles.radius,
            border: `1px solid ${border}`,
            width: isXl
                ? '40%'
                : isLg
                  ? '50%'
                  : isMd
                    ? '60%'
                    : isSm
                      ? '70%'
                      : '90%',
            padding: 15,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
        },
        modalHeader: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: ' space-between',
        },
        closeButton: {
            width: 30,
        },
        modalTitle: {
            fontSize: isMd ? 32 : 26,
            fontFamily: defaultStyles.titleFont,
            color: text,
        },
    })

    return (
        <Modal
            visible={visible}
            animationType="fade"
            onDismiss={onClose}
            transparent={true}
            style={{
                zIndex: 999,
            }}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View style={styles.modalHeader}>
                        <TouchableOpacity
                            style={styles.closeButton}
                            onPress={onClose}
                        >
                            <CloseOutlined
                                style={{ fontSize: 22, color: text }}
                            ></CloseOutlined>
                        </TouchableOpacity>

                        <View
                            style={{
                                flex: 1,
                                paddingTop: 15,
                                alignItems: 'center',
                            }}
                        >
                            <Text style={styles.modalTitle}>{title}</Text>
                        </View>

                        <View style={styles.closeButton}></View>
                    </View>
                    {children}
                </View>
            </View>
        </Modal>
    )
}

export default TutredModal
