import StarIcon from '@icons/StarIcon'
import { Box, Typography } from '@mui/material'
import { useColorsContext } from '@utils/styles'

const Rating = ({ reviewCount, small, rating }) => {
    const { text, textSecondary } = useColorsContext()
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mt: small ? 1 : 0,
            }}
        >
            <StarIcon size={small ? 24 : 32} />
            <Typography
                variant={
                    reviewCount > 0
                        ? small
                            ? 'body1'
                            : 'h5'
                        : small
                          ? 'body2'
                          : 'h6'
                }
                fontFamily={
                    reviewCount > 0 ? 'Inter_700Bold' : 'Inter_600SemiBold'
                }
                sx={{
                    color: reviewCount > 0 ? text : textSecondary,
                    ml: 0.5,
                }}
            >
                {reviewCount > 0 ? rating : 'Unrated'}
            </Typography>
            {reviewCount > 0 && (
                <Typography
                    variant={small ? 'body1' : 'h6'}
                    fontFamily="Inter_400Regular"
                    sx={{
                        color: textSecondary,
                        marginLeft: 0.25,
                    }}
                >
                    ({reviewCount})
                </Typography>
            )}
        </Box>
    )
}

export default Rating
