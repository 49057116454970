import { CloseOutlined } from "@mui/icons-material";
import * as ImagePicker from "expo-image-picker";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import {
  Alert,
  Modal,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { CheckBox } from 'react-native-elements';

import { useUserContext } from "../contexts/userContext";
import { useColorsContext, useStyles } from "../utils/styles";


const hostname = process.env.HOSTNAME

export const VerifyModal = ({ verifyModal, setVerifyModal }) => {
  const [imageUri, setImageUri] = useState(null);
  const [description, setDescription] = useState("");
  const [id, setId] = useState(null);
  const [backid, setBackId] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [degree, setDegree] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isColor, setIsColor] = useState(false); // Add this state
  const [hasLegalNames, setHasLegalNames] = useState(false); // Add this state

  const customAlert = useAlert();
  const {
    primary,
    primaryVariant,
    red,
    redVariant,
    background,
    backgroundVariant,
    text,
    textVariant,
    border,
    yellow,
    green,
  } = useColorsContext();
  const defaultStyles = useStyles();

  const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
  const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  const isSm = useMediaQuery({ query: "(min-width: 640px)" });

  const { user } = useUserContext();

  const handleSubmit = async () => {
    if (!id || !transcript || !degree || !isColor || !hasLegalNames) {
      setError("Please upload the required documents and check all the boxes.");
      return;
    }

    try {
      console.log("Submitting verification request with the following data:");
      console.log("Photo ID URL:", id);
      console.log("Photo ID URL:", backid);
      console.log("Transcript URL:", transcript);
      console.log("Degree URL:", degree);
      console.log("Description:", description);

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      };

      const response = await fetch(`${hostname}/api/verify-tutor`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          photoURL: id,
          backphotoURL: backid,
          transcriptURL: transcript,
          degreeURL: degree,
          message: description,
          email: user.email,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Server response:", data);

      if (localStorage) {
        localStorage.setItem("verificationRequest", "true");
      }
      setSuccess(true);
    } catch (error) {
      console.error("Error submitting verification request:", error);
      setError("Something went wrong, please try again later");
    }
  };

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
    return result;
  };

  const uploadImage = async (uri, setImageCallback) => {
    if (!uri) {
      console.error("Image URI is undefined or null.");
      return;
    }

    const storage = getStorage();
    console.log(`Starting image upload for URI: ${uri}`);

    try {
      let imageFile = await fetch(uri);
      if (!imageFile.ok) {
        throw new Error(`Failed to fetch the image file from URI: ${uri}`);
      }
      console.log(`Fetched image file from URI: ${uri}`);

      let name = makeid(12);
      let imageBlob = await imageFile.blob();
      console.log(`Blob size: ${imageBlob.size}`);
      const storageRef = ref(storage, `images/${name}`);
      console.log(`Uploading file: ${name}`);

      return new Promise((resolve, reject) => {
        let task = uploadBytesResumable(storageRef, imageBlob, {
          contentType: "image/jpeg",
        });

        task.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            console.error("Error during upload:", error);
            Platform.OS === "web"
              ? customAlert.error(error)
              : Alert.alert("Error uploading image!", error.message, [
                {
                  text: "OK",
                  onPress: () => console.log("OK Pressed"),
                },
              ]);
            reject(error);
          },
          () => {
            getDownloadURL(task.snapshot.ref).then((downloadURL) => {
              console.log(`File uploaded successfully: ${downloadURL}`);
              setImageCallback(downloadURL);
              resolve(downloadURL);
            });
          }
        );
      });
    } catch (error) {
      console.error("Upload Image Error:", error);
    }
  };

  const handleImagePick = async (setImageCallback) => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      console.log("Image picked:", result.assets[0].uri);
      setImageUri(result.assets[0].uri);
      await uploadImage(result.assets[0].uri, setImageCallback);
    }
  };

  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      flex: 1,
    },
    maxWidth: {
      flex: 1,
      paddingTop: 30,
      paddingBottom: 30,
      backgroundColor: background,
      display: "flex",
      padding: Platform.OS === "web" ? 30 : null,
      width: "100%",
      margin: Platform.OS === "web" ? "auto" : null,
    },
    header: {
      marginTop: 30,
      flexDirection: "column",
      display: "flex",
    },
    title: {
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
    centeredView: {
      flex: 1,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      backgroundColor: background,
      borderRadius: defaultStyles.radius,
      border: `1px solid ${border}`,
      width: isXl
        ? "40%"
        : isLg
          ? "50%"
          : isMd
            ? "60%"
            : isSm
              ? "70%"
              : "90%",
      height: isMd ? "70%" : "95%",
      padding: 15,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    modalHeader: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: " space-between",
      marginBottom: 5,
    },
    closeButton: {
      marginRight: 15,
    },
    modalTitle: {
      fontSize: 18,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    title: {
      fontSize: 16,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    marginTop: { marginVertical: 20 },
    infoContainer: {
      flex: 1,
      display: "flex",
      flexDirection: !isMd ? "column" : "row",
      justifyContent: "space-between",
    },
    mainContainer: {
      flex: 1,
      padding: 30,
    },
    button: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: backgroundVariant,
      border: "1px solid " + border,
      borderRadius: defaultStyles.buttonRadius,
      padding: 15,
      alignItems: "center",
    },
    buttonText: {
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
    descriptionInput: {
      backgroundColor: backgroundVariant,
      padding: 15,
      fontSize: 16,
      marginTop: 15,
      borderRadius: defaultStyles.radius,
      border: "1px solid " + border,
      fontFamily: defaultStyles.regularFont,
      color: text,
      height: 225,
    },
    details: {
      fontSize: 14,
      color: textVariant,
      fontFamily: defaultStyles.regularFont,
    },
  });

  return (
    <View>
      <Modal
        key={3}
        animationType="fade"
        transparent={true}
        visible={verifyModal}
        onRequestClose={() => {
          setVerifyModal(!verifyModal);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalHeader}>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => {
                    setVerifyModal(!verifyModal);
                  }}
                >
                  <CloseOutlined
                    style={{ fontSize: 22, color: text }}
                  ></CloseOutlined>
                </TouchableOpacity>
                <Text style={styles.modalTitle}>Verify your account</Text>
              </View>
              {!isMd || success ? null : (
                <TouchableOpacity
                  style={{
                    backgroundColor: primary,
                    padding: 10,
                    borderRadius: defaultStyles.buttonRadius,
                  }}
                  onPress={() => {
                    handleSubmit();
                  }}
                >
                  <Text
                    style={{
                      color: "#fff",
                      fontSize: 14,
                      fontFamily: defaultStyles.boldFont,
                    }}
                  >
                    Submit Request
                  </Text>
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.mainContainer}>
              <Text style={styles.title}>
                Please upload the required documents to verify your account. All
                your information is kept private and secure.
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: defaultStyles.regularFont,
                  color: text,
                  marginVertical: 10,
                }}
              >
                Please allow our team to review your request.
              </Text>
              <TouchableOpacity
                onPress={async () => {
                  await handleImagePick(setId);
                }}
                style={[
                  styles.button,
                  {
                    backgroundColor: id != null ? green : backgroundVariant,
                  },
                ]}
              >
                <Text style={[styles.buttonText]}>Photo ID</Text>
              </TouchableOpacity>
              <Text
                style={{
                  fontFamily: defaultStyles.regularFont,
                  fontSize: 12,
                }}
              >
                Any valid government issued photo ID.
              </Text>

              <TouchableOpacity
                onPress={async () => {
                  await handleImagePick(setBackId);
                }}
                style={[
                  styles.button,
                  {
                    backgroundColor: backid != null ? green : backgroundVariant,
                  },
                ]}
              >
                <Text style={[styles.buttonText]}>Set Back Photo ID</Text>
              </TouchableOpacity>
              <Text
                style={{
                  fontFamily: defaultStyles.regularFont,
                  fontSize: 12,
                }}
              >
                Any valid government issued photo ID, but the back of it.
              </Text>
              <View style={{ marginVertical: 15 }}>
                <TouchableOpacity
                  onPress={async () => {
                    await handleImagePick(setTranscript);
                  }}
                  style={[
                    styles.button,
                    {
                      backgroundColor:
                        transcript != null ? green : backgroundVariant,
                    },
                  ]}
                >
                  <Text style={[styles.buttonText]}>Official Transcript</Text>
                </TouchableOpacity>
                <Text
                  style={{
                    fontFamily: defaultStyles.regularFont,
                    fontSize: 12,
                  }}
                >
                  Official transcript issued from your university.
                </Text>
              </View>

              <View style={{ marginVertical: 15 }}>
                <TouchableOpacity
                  onPress={async () => {
                    await handleImagePick(setDegree);
                  }}
                  style={[
                    styles.button,
                    {
                      backgroundColor: degree != null ? green : backgroundVariant,
                    },
                  ]}
                >
                  <Text style={[styles.buttonText]}>Degree</Text>
                </TouchableOpacity>
                <Text
                  style={{
                    fontFamily: defaultStyles.regularFont,
                    fontSize: 12,
                  }}
                >
                  A photo of your degree / diploma.
                </Text>
              </View>

              <TextInput
                placeholderTextColor="#999999"
                style={styles.descriptionInput}
                placeholder="Any additional information..."
                multiline={true}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                maxLength={300}
              ></TextInput>
              <Text style={[styles.details, { padding: 5 }]}>
                {description.length} / 300
              </Text>

              <CheckBox
                title="The driver's license, both front and back are in color"
                checked={isColor}
                onPress={() => setIsColor(!isColor)}
                containerStyle={{ backgroundColor: background, borderColor: border }}
                textStyle={{ color: text }}
                checkedColor="blue" // Custom style for checked color
                uncheckedColor="black" // Custom style for unchecked color
              />
              <CheckBox
                title="All items have legal names on them"
                checked={hasLegalNames}
                onPress={() => setHasLegalNames(!hasLegalNames)}
                containerStyle={{ backgroundColor: background, borderColor: border }}
                textStyle={{ color: text }}
                checkedColor="blue" // Custom style for checked color
                uncheckedColor="black" // Custom style for unchecked color
              />
            </View>

            {error && (
              <Text
                style={{
                  color: "#FF0000",
                  fontFamily: defaultStyles.boldFont,
                  fontSize: 14,
                  textAlign: "center",
                  marginBottom: 30,
                }}
              >
                {error}
              </Text>
            )}

            {success && (
              <Text
                style={{
                  color: "green",
                  fontFamily: defaultStyles.boldFont,
                  fontSize: 14,
                  textAlign: "center",
                  marginBottom: 30,
                }}
              >
                Your verification request has been sent to our team. You will be
                notified when your request has been reviewed. Please allow 7-10
                business days for your request to be processed. Thank you!
              </Text>
            )}

            {isMd || success ? null : (
              <TouchableOpacity
                style={{
                  backgroundColor: primary,
                  padding: 10,
                  borderRadius: defaultStyles.buttonRadius,
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onPress={() => {
                  handleSubmit();
                }}
              >
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 14,
                    fontFamily: defaultStyles.boldFont,
                  }}
                >
                  Submit Request
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </Modal>
    </View>
  );
};