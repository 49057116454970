import { Box, Typography } from '@mui/material'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useColorsContext, useStyles } from '@utils/styles'
import FontAwesome6 from '@expo/vector-icons/FontAwesome6'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'

interface Props {
    tutor: any
}

const Instabook: React.FC<Props> = ({ tutor }) => {
    const defaultStyles = useStyles()
    const { primaryVariant, text, darkMode } = useColorsContext()

    return (
        <Box sx={{ marginTop: 3 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <FontAwesome6
                    name="dollar-sign"
                    size={24}
                    color={text}
                    style={{ marginLeft: 4 }}
                />
                <Typography
                    variant="h5"
                    sx={{
                        marginRight: 2,
                        marginLeft: '12px',
                        color: text,
                    }}
                >
                    Minimum Fee:
                </Typography>
                <Typography variant="h6" sx={{ color: text }}>
                    {tutor.instaBookMinimum
                        ? '$' + tutor.instaBookMinimum.toFixed(2)
                        : 'not set'}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 2,
                }}
            >
                <MaterialCommunityIcons
                    name="clock-end"
                    size={24}
                    color={text}
                />
                <Typography
                    variant="h5"
                    sx={{
                        marginRight: 2,
                        marginLeft: 1,
                        color: text,
                    }}
                >
                    Debrief Window:
                </Typography>
                <Typography variant="h6" sx={{ color: text }}>
                    {tutor.instaBookBufferPeriod
                        ? tutor.instaBookBufferPeriod + ' mins'
                        : 'not set'}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 2,
                }}
            >
                <FontAwesome6 name="clock" size={24} color={text} />
                <Typography
                    variant="h5"
                    sx={{
                        marginRight: 2,
                        marginLeft: 1,
                        color: text,
                    }}
                >
                    Session Length:
                </Typography>
                <Typography variant="h6" sx={{ color: text }}>
                    {tutor.instaBookLength
                        ? tutor.instaBookLength + ' mins'
                        : 'not set'}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 2,
                }}
            >
                <Box sx={{ position: 'relative' }}>
                    <FontAwesome6 name="clock" size={24} color={text} />
                    <FontAwesome6
                        name="plus"
                        size={16}
                        color={text}
                        style={{
                            position: 'absolute',
                            bottom: -2,
                            left: -2,
                            backgroundColor: darkMode ? '#000000' : '#ffffff',
                            borderRadius: 8,
                        }}
                    />
                </Box>
                <Typography
                    variant="h5"
                    sx={{
                        marginRight: 2,
                        marginLeft: 1,
                        color: text,
                    }}
                >
                    Session Extensions:
                </Typography>
                <Typography variant="h6" sx={{ color: text }}>
                    {tutor.instaBookExtension
                        ? tutor.instaBookExtension + ' mins'
                        : 'not set'}
                </Typography>
            </Box>
        </Box>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: 20,
    },
})

export default Instabook
