import {
    Autocomplete,
    Box,
    Button,
    Chip,
    TextField,
    Typography,
} from '@mui/material'
import React, { useMemo } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import DegreeCard from './DegreeCard'
import AddNewDegreeModal from './AddNewDegreeModal'
import { useColorsContext, useStyles } from '@utils/styles'
import { levels } from '@config/levels'
import TutredBaseChip from '@components/Chips/TutredBaseChip'

interface EducationProps {
    degreeArray: any
    setDegreeArray: (any) => void
    selectedEducationLevels: any[]
    setSelectedEducationLevels: (any) => void
}

{
}

const Education: React.FC<EducationProps> = ({
    degreeArray,
    setDegreeArray,
    selectedEducationLevels,
    setSelectedEducationLevels,
}) => {
    const [showModal, setShowModal] = React.useState(false)
    const { primary, backgroundVariant, text, border } = useColorsContext()
    const defaultStyles = useStyles()

    const educationLevels = useMemo(() => {
        const options = []
        selectedEducationLevels.forEach((level) => {
            const found = levels.find((l) => l.value === level)
            if (found) {
                options.push(found)
            }
        })
        return options
    }, [selectedEducationLevels])

    return (
        <Box sx={{}}>
            <AddNewDegreeModal
                visible={showModal}
                onClose={() => setShowModal(false)}
                handleAddDegree={(degree) => {
                    setDegreeArray([...degreeArray, degree])
                }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h5">Degrees</Typography>
                <Button variant="contained" onClick={() => setShowModal(true)}>
                    Add New Degree
                </Button>
            </Box>
            <Box
                sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
                {degreeArray.map((doc, index) => {
                    return (
                        <DegreeCard
                            key={`degree-card-${index}`}
                            school={doc.school}
                            year={doc.year}
                            degree={doc.name}
                        ></DegreeCard>
                    )
                })}
            </Box>
            <Box sx={{ marginTop: 3 }}>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    Levels
                </Typography>
                <Autocomplete
                    multiple
                    id="fixed-tags-demo"
                    value={educationLevels}
                    onChange={(event, newValue) => {
                        setSelectedEducationLevels([
                            ...newValue.map((option) => option.value),
                        ])
                    }}
                    options={levels}
                    getOptionLabel={(option) => option.label}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({
                                index,
                            })
                            return (
                                <TutredBaseChip
                                    key={key}
                                    label={option.label}
                                    {...tagProps}
                                />
                            )
                        })
                    }
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Levels"
                            placeholder="Select Levels"
                        />
                    )}
                />
            </Box>
        </Box>
    )

    // <View style={{ marginTop: 15 }}>
    //     <Text style={styles.subtitle}>Level</Text>
    //     <View
    //         style={{ flexDirection: 'row', flexWrap: 'wrap' }}
    //     >
    //         {educationLevels.map((level, index) => (
    //             <View
    //                 key={index}
    //                 style={{
    //                     flexDirection: 'row',
    //                     alignItems: 'center',
    //                     marginRight: 10,
    //                     marginBottom: 10,
    //                 }}
    //             >
    //                 <CheckBox
    //                     value={selectedEducationLevels.includes(
    //                         level.value
    //                     )}
    //                     onValueChange={() =>
    //                         handleCheckboxChange(level.value)
    //                     }
    //                 />
    //                 <Text style={{ marginLeft: 10 }}>
    //                     {level.label}
    //                 </Text>
    //             </View>
    //         ))}
    //     </View>
    // </View>
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default Education
