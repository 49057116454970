import { Link } from '@react-navigation/native'
import { useColorsContext, useStyles } from '@utils/styles'
import React, { useState } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { MenuOption } from 'react-native-popup-menu'
import { AntDesign } from '@expo/vector-icons'
import { Box } from '@mui/material'

const NavigationOption = ({ name, icon, menuNavigation, link }) => {
    const defaultStyles = useStyles()
    const { border, backgroundVariant, textVariant, primary, text } =
        useColorsContext()
    const [isHovered, setIsHovered] = useState(false)

    const handlePressIn = () => {
        setIsHovered(true)
    }

    const handlePressOut = () => {
        setIsHovered(false)
    }
    return (
        <MenuOption
            onSelect={() => {
                if (menuNavigation) {
                    menuNavigation()
                }
            }}
            style={{
                backgroundColor: isHovered ? border : backgroundVariant,
                borderRadius: defaultStyles.radius,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 10,
            }}
        >
            <Link
                onMouseEnter={handlePressIn}
                onMouseLeave={handlePressOut}
                to={link}
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10,
                    borderRadius: defaultStyles.radius,
                }}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 25,
                        height: 25,
                        display: 'flex',
                        margin: 5,
                    }}
                >
                    <AntDesign
                        size={19}
                        name={icon}
                        color={textVariant}
                    ></AntDesign>
                </View>
                <Text
                    style={{
                        color: text,
                        fontFamily: defaultStyles.regularFont,
                        fontSize: 16,
                    }}
                >
                    {name}{' '}
                </Text>
            </Link>
        </MenuOption>
    )
}

export default NavigationOption
