import { SignUpFormData } from '@components/SignUpDialog'
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'
import { useMutation } from '@tanstack/react-query'
import { setDoc, doc, getFirestore } from 'firebase/firestore'
import { useAxiosWithAuth } from '@contexts/axiosContext'
import { useAlert } from 'react-alert'

const useSignUp = () => {
    const auth = getAuth()
    const db = getFirestore()
    const { authenticatedAxios } = useAxiosWithAuth()
    const alert = useAlert()

    const {
        mutate: signup,
        isPending,
        isError,
        error,
        isSuccess,
    } = useMutation({
        mutationFn: ({
            email,
            password,
            accountType,
            firstName,
            lastName,
        }: SignUpFormData) => {
            return createUserWithEmailAndPassword(auth, email, password).then(
                async (userCredential) => {
                    // Signed in
                    const user = userCredential.user
                    let createCustomerData = null
                    if (accountType == 'student') {
                        createCustomerData = await authenticatedAxios.post(
                            '/api/create-customer',
                            {
                                email,
                            }
                        )
                    }

                    let urlId = Math.floor(100000 + Math.random() * 900000)

                    let userData: any = {
                        created: new Date(),
                        uid: user.uid,
                        id: user.uid,
                        email: email,
                        name: firstName + ' ' + lastName,
                        lastOnline: new Date(),
                        isTutor: accountType === 'tutor',
                        customerId: createCustomerData?.data?.customerId,
                        URL: urlId,
                    }

                    setDoc(doc(db, 'users', user.uid), userData)
                        .then(() => {
                            authenticatedAxios.post('/api/email/welcome', {
                                email,
                                name: firstName + ' ' + lastName,
                                isTutor: accountType === 'tutor',
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            )
        },
        onError: ({ code, message }: any) => {
            if (code === 'auth/email-already-in-use') {
                alert.error('[Sign up] Email already in use!')
            } else {
                alert.error('[Sign up] Something went wrong!')
            }
        },
        onSuccess: () => {
            console.log('Success')
            alert.success('[Sign up] Thank you for signing up!')
        },
    })

    return { signup, isPending, isError, isSuccess, error }
}

export default useSignUp
