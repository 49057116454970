import { Box, Typography } from '@mui/material'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import { useColorsContext } from '@utils/styles'
import { schoolLogos } from '@config/schools'

interface Props {
    degrees: Degree[]
}

interface Degree {
    school: string
    year: string
    type: string
    name: string
}

const Education: React.FC<Props> = ({ degrees }) => {
    const { text } = useColorsContext()

    if (!degrees?.length) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: 'center',
                        color: text,
                    }}
                >
                    This tutor hasnt added any education to their profile yet.
                </Typography>
            </Box>
        )
    }

    return (
        <Box sx={{ marginTop: 3 }}>
            {degrees.map((degree, index) => {
                return (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 3,
                        }}
                    >
                        {/* <MaterialCommunityIcons
                            name="book-education-outline"
                            size={48}
                            color={text}
                        /> */}
                        <img
                            src={schoolLogos[degree.school]}
                            style={{
                                width: 40,
                                height: 40,
                                marginRight: 16,
                                objectFit: 'contain',
                            }}
                        />
                        <Box sx={{ marginLeft: 3 }}>
                            <Typography variant="h5" sx={{ color: text }}>
                                {degree.school}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="body1">
                                    {degree.name} -{' '}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        marginLeft: 1,
                                    }}
                                >
                                    {degree.year}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}

export default Education
