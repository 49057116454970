import { useColorsContext } from '@utils/styles';
import React from 'react';

const LocationIcon = ({
    size = 32,

}) => {
    const { text, textInverse } = useColorsContext();
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8_44)">
                <path d="M16 27C15.77 27 15.539 26.921 15.352 26.762C15.11 26.556 9.41 21.662 7.032 16.324C6.807 15.82 7.034 15.228 7.539 15.004C8.042 14.78 8.634 15.006 8.859 15.511C10.598 19.416 14.436 23.21 15.983 24.641C18.713 21.991 24 16.069 24 12.23C24 7.692 20.411 4 16 4C11.589 4 8 7.692 8 12.23C8 12.782 7.552 13.23 7 13.23C6.448 13.23 6 12.782 6 12.23C6 6.589 10.486 2 16 2C21.514 2 26 6.589 26 12.23C26 18.142 17.056 26.389 16.675 26.738C16.484 26.912 16.242 27 16 27Z" fill={text} />
                <path d="M16 16C13.794 16 12 14.206 12 12C12 9.794 13.794 8 16 8C18.206 8 20 9.794 20 12C20 14.206 18.206 16 16 16ZM16 10C14.897 10 14 10.897 14 12C14 13.103 14.897 14 16 14C17.103 14 18 13.103 18 12C18 10.897 17.103 10 16 10Z" fill={text} />
                <path d="M29 32.0007H3C2.644 32.0007 2.314 31.8117 2.135 31.5027C1.956 31.1937 1.955 30.8147 2.132 30.5047L6.132 23.5047C6.406 23.0257 7.017 22.8577 7.496 23.1327C7.975 23.4067 8.142 24.0177 7.868 24.4967L4.723 30.0007H27.276L24.131 24.4967C23.857 24.0177 24.024 23.4067 24.503 23.1327C24.982 22.8567 25.593 23.0257 25.867 23.5047L29.867 30.5047C30.044 30.8147 30.043 31.1937 29.864 31.5027C29.686 31.8117 29.356 32.0007 29 32.0007Z" fill={text} />
            </g>
            <defs>
                <clipPath id="clip0_8_44">
                    <rect width="32" height="32" fill={textInverse} />
                </clipPath>
            </defs>
        </svg>
    )

}


export default LocationIcon;
