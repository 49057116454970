import { useContext, createContext, useEffect, useState } from "react";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore, } from "firebase/firestore";
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Environment variables to check if we are in development or production
// const dev = process.env.mode !== 'production';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const dev = process.env.mode === 'development';
const firebaseConfig = dev
	? {
		apiKey: "AIzaSyCZ8WLxHNw6XTaqKAWROoGunCtekTVGoY4",
		authDomain: "tutred-test.firebaseapp.com",
		projectId: "tutred-test",
		storageBucket: "tutred-test.appspot.com",
		messagingSenderId: "713705133770",
		appId: "1:713705133770:web:b2f05a210418eba8e78633",
		measurementId: "G-DBKB93HMLZ",
	}
	: {
		apiKey: "AIzaSyAtBsxapCL-Lheqcfn_Mb3x6SdUTF_oV9o",
		authDomain: "welearn-f94aa.firebaseapp.com",
		projectId: "welearn-f94aa",
		storageBucket: "welearn-f94aa.appspot.com",
		messagingSenderId: "185278024506",
		appId: "1:185278024506:web:0a9ca35846d3b9c2301e03",
		measurementId: "G-HHJ8B9WB46",
	};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseContext = createContext();

export const FirebaseContextProvider = ({ children }) => {
	const auth = getAuth();
	const [db, setDb] = useState(null);
	// useEffect(() => {
	// 	if (auth) {
	// 		connectAuthEmulator(auth, "http://127.0.0.1:9099");
	// 	}
	// }, [auth])

	useEffect(() => {
		// Setup firestore emulator	
		const db = getFirestore(app)
		// connectFirestoreEmulator(db, "localhost", 8080);
		setDb(db);
	}, []);

	return (
		<firebaseContext.Provider
			value={{
				auth,
				app,
				db,
			}}
		>
			{children}
		</firebaseContext.Provider>
	);
};

export const useFirebaseContext = () => {
	return useContext(firebaseContext);
};
