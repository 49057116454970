import React from 'react';

const MinusCircleIcon = ({ size = 12, color = "#006EFF" }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 6H8" stroke={color} strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
        </svg>
    )
}


export default MinusCircleIcon;