import { MultiSwitchToggle } from '@components/MultiSwitchToggle'
import { useUserContext } from '@contexts/userContext'
import formatName from '@helpers/formatName'
import useFinalizeInstaBookSession from '@hooks/useFinalizeInstaBookSession'
import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useMemo, useState } from 'react'
import { ActivityIndicator, Text, TouchableOpacity } from 'react-native'
import { decode } from 'html-entities'
import { HelpOutline } from '@mui/icons-material'
import {
    Link,
    useNavigation,
    ParamListBase,
    NavigationProp,
} from '@react-navigation/native'
import paymentMethodIcon from '@icons/payment-method.png'
import sessionRecordingIcon from '@icons/session-recording.png'
import serviceFeeIcon from '@icons/service-fee.png'
import totalIcon from '@icons/total.png'
import sessionLengthIcon from '@icons/session-length.png'
import sessionFeeIcon from '@icons/session-fee.png'
import PaymentMethod from '@components/PaymentMethod'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useAxiosWithAuth } from '@contexts/axiosContext'
import { AxiosResponse } from 'axios'

export const InstaBookConfirmModal = ({
    showModal,
    setShowModal,
    tutor,
    setButtonLoading,
}) => {
    if (!tutor?.instaBookPricing) {
        return null
    }

    const stripe = useStripe()
    const elements = useElements()
    const [loading, setLoading] = useState(false)
    const [enableRecording, setEnableRecording] = useState(false)
    const [showPaymentChange, setShowPaymentChange] = useState(false)
    const [newDefaultPaymentMethod, setNewDefaultPaymentMethod] =
        useState(false)
    const { finalizeInstaBookSession } = useFinalizeInstaBookSession()
    const navigation: NavigationProp<ParamListBase> = useNavigation()

    const { background, border, text, green, redVariant, primary } =
        useColorsContext()
    const { customerId } = useUserContext()
    const { authenticatedAxios } = useAxiosWithAuth()
    const defaultStyles = useStyles()
    const { paymentMethods } = useUserContext()
    const theme = useTheme()
    const isXl = useMediaQuery(theme.breakpoints.up('xl'))
    const isLg = useMediaQuery(theme.breakpoints.up('lg'))
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const pricingOptions = useMemo(() => {
        return Object.keys(tutor?.instaBookPricing).map((key) => {
            const pricing = tutor?.instaBookPricing[key]
            return {
                label: `${pricing.length} mins`,
                contextLabel: ` ${decode('\u00b7')} CA$${pricing.rate}`,
                value: pricing,
            }
        })
    }, [tutor?.instaBookPricing])

    const [selectedPricingOption, setSelectedPricingOption] = useState(
        pricingOptions[0].value || { length: 15, rate: 15 }
    )

    const totalSessionFee = selectedPricingOption.rate
    const totalRecordingFee = enableRecording ? tutor.sessionRecordingFee : 0
    const taxesAndFees = useMemo(() => {
        return ((totalSessionFee + totalRecordingFee) * 0.1).toFixed(2)
    }, [totalSessionFee, totalRecordingFee])

    const totalCost = useMemo(() => {
        return (
            totalSessionFee +
            totalRecordingFee +
            Number(taxesAndFees)
        ).toFixed(2)
    }, [totalSessionFee, totalRecordingFee, taxesAndFees])

    const onSubmitPayment = async () => {
        setLoading(true)
        const cardElement = elements.getElement(CardElement)

        if (!cardElement) {
            console.error('CardElement not found')
            // setError('Card details not entered')
            return
        }

        console.log('Creating payment method...')
        const { error: paymentMethodError, paymentMethod } =
            await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            })

        if (paymentMethodError) {
            console.error(
                'Error creating payment method:',
                paymentMethodError.message
            )
            // setError(paymentMethodError.message)
            return
        }

        console.log('Payment method created:', paymentMethod.id)

        console.log('Calling backend to attach PaymentMethod...')
        let newPaymentMethodId = null

        authenticatedAxios
            .post(`api/${customerId}/payment-method`, {
                paymentMethodId: paymentMethod.id,
                setAsDefault: newDefaultPaymentMethod,
            })
            .then(
                (
                    response: AxiosResponse<{
                        success: boolean
                        paymentMethodId: string
                    }>
                ) => {
                    newPaymentMethodId = response.data.paymentMethodId
                    const tutorPayout =
                        (totalSessionFee + totalRecordingFee) * 0.9
                    setLoading(true)
                    finalizeInstaBookSession(
                        totalCost,
                        tutor,
                        enableRecording,
                        selectedPricingOption.length,
                        tutorPayout,
                        response.data.paymentMethodId
                    ).finally(() => {
                        setButtonLoading(false)
                        setLoading(false)
                        setShowModal(false)
                    })
                }
            )
            .catch((error) => {
                console.log('error', error)
            })
    }

    return (
        <Dialog
            key={3}
            open={showModal}
            scroll="paper"
            onClose={() => {
                setShowModal(false)
            }}
        >
            <DialogTitle sx={{ textAlign: 'center' }}>
                {' '}
                Start an Instabook session with {formatName(
                    tutor.name,
                    false
                )}{' '}
                ?
            </DialogTitle>
            <DialogContent>
                <Typography
                    variant={isMd ? 'subtitle1' : 'subtitle2'}
                    sx={{
                        fontFamily: defaultStyles.titleFont,
                        marginBottom: isMd ? 2 : 1,
                        textAlign: 'center',
                        color: '#8c8c8c',
                    }}
                >
                    Session Details
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: isMd ? 3 : 2,
                    }}
                >
                    <MultiSwitchToggle
                        options={pricingOptions || []}
                        onSelect={setSelectedPricingOption}
                        selected={selectedPricingOption}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={sessionFeeIcon}
                            width={26}
                            height={26}
                            style={{
                                marginRight: 12,
                            }}
                        />
                        <Typography
                            variant={isMd ? 'body1' : 'body2'}
                            sx={{
                                fontFamily: defaultStyles.titleFont,
                            }}
                        >
                            Session Fee:{' '}
                        </Typography>
                    </Box>
                    <Typography
                        variant={isMd ? 'body1' : 'body2'}
                        sx={{
                            fontFamily: defaultStyles.regularFont,
                        }}
                    >
                        ${(selectedPricingOption.rate || 0.0).toFixed(2)}
                    </Typography>
                </Box>

                {tutor.allowSessionRecording && (
                    <Box
                        sx={{
                            marginBottom: isMd ? 2 : 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={sessionRecordingIcon}
                                width={28}
                                height={28}
                                style={{
                                    marginRight: 12,
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    variant={isMd ? 'body1' : 'body2'}
                                    sx={{
                                        fontFamily: defaultStyles.titleFont,
                                    }}
                                >
                                    Would you like to enable session recording?
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    {' '}
                                    <Typography
                                        variant={isMd ? 'body2' : 'caption'}
                                        sx={{
                                            fontFamily:
                                                defaultStyles.regularFont,
                                            marginRight: 0.5,
                                        }}
                                    >
                                        You can add a session recording for
                                    </Typography>
                                    <Typography
                                        variant={isMd ? 'body2' : 'caption'}
                                        sx={{
                                            fontFamily: defaultStyles.titleFont,
                                        }}
                                    >
                                        {tutor.sessionRecordingFee > 0
                                            ? `$${tutor.sessionRecordingFee.toFixed(2)}`
                                            : 'FREE'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Checkbox
                            value={enableRecording}
                            onChange={() =>
                                setEnableRecording(!enableRecording)
                            }
                        />
                    </Box>
                )}

                <Divider
                    variant="middle"
                    sx={{
                        backgroundColor: border,
                        marginTop: isMd ? 2 : 1,
                        marginBottom: isMd ? 2 : 1,
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 3,
                        justifyContent: 'space-between',
                        marginBottom: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={sessionLengthIcon}
                            width={24}
                            height={24}
                            style={{
                                marginLeft: -2,
                                marginRight: 12,
                            }}
                        />
                        <Typography
                            variant={isMd ? 'body1' : 'body2'}
                            sx={{
                                fontFamily: defaultStyles.titleFont,
                            }}
                        >
                            {selectedPricingOption.length} min session:{' '}
                        </Typography>
                    </Box>

                    <Typography
                        variant={isMd ? 'body1' : 'body2'}
                        sx={{
                            fontFamily: defaultStyles.regularFont,
                        }}
                    >
                        $ {(selectedPricingOption.rate || 0.0).toFixed(2)}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={serviceFeeIcon}
                            width={26}
                            height={26}
                            style={{
                                marginRight: 12,
                            }}
                        />
                        <Typography
                            variant={isMd ? 'body1' : 'body2'}
                            sx={{
                                fontFamily: defaultStyles.titleFont,
                            }}
                        >
                            Service Fee:{' '}
                        </Typography>
                        <Tooltip title="Includes taxes and our service fee.">
                            <HelpOutline color={text} sx={{ marginLeft: 1 }} />
                        </Tooltip>
                    </Box>

                    <Typography
                        variant={isMd ? 'body1' : 'body2'}
                        sx={{
                            fontFamily: defaultStyles.regularFont,
                        }}
                    >
                        ${taxesAndFees}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={totalIcon}
                            width={32}
                            height={32}
                            style={{
                                marginLeft: -2,
                                marginRight: 12,
                            }}
                        />
                        <Typography
                            variant={isMd ? 'body1' : 'body2'}
                            sx={{
                                fontFamily: defaultStyles.titleFont,
                            }}
                        >
                            Total:{' '}
                        </Typography>
                    </Box>

                    <Typography
                        variant={isMd ? 'body1' : 'body2'}
                        sx={{
                            fontFamily: defaultStyles.regularFont,
                        }}
                    >
                        ${totalCost}
                    </Typography>
                </Box>
                {paymentMethods?.length > 0 && (
                    <Box>
                        {showPaymentChange ? (
                            <Box sx={{ marginBottom: isMd ? 2 : 1 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        src={paymentMethodIcon}
                                        style={{
                                            marginLeft: -4,
                                            width: 36,
                                            height: 36,
                                            marginRight: 12,
                                        }}
                                    />
                                    <Typography
                                        variant={isMd ? 'body1' : 'body2'}
                                        sx={{
                                            fontFamily: defaultStyles.titleFont,
                                        }}
                                    >
                                        Payment Method:
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        border: '1px solid #000',
                                        padding: 2,
                                        marginTop: 1,
                                        borderRadius: '4px',
                                    }}
                                >
                                    <CardElement />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginTop: 1,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Checkbox
                                            checked={newDefaultPaymentMethod}
                                            onChange={() => {
                                                setNewDefaultPaymentMethod(
                                                    !newDefaultPaymentMethod
                                                )
                                            }}
                                            size="small"
                                        />
                                        <Typography variant="body2">
                                            Set as default?
                                        </Typography>
                                    </Box>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setShowPaymentChange(false)
                                        }}
                                    >
                                        <Typography
                                            variant={isMd ? 'body2' : 'caption'}
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.boldFont,
                                                textAlign: 'right',
                                                color: primary,
                                            }}
                                        >
                                            Cancel
                                        </Typography>
                                    </TouchableOpacity>
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ marginBottom: isMd ? 2 : 1 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            src={paymentMethodIcon}
                                            style={{
                                                marginLeft: -4,
                                                width: 36,
                                                height: 36,
                                                marginRight: 12,
                                            }}
                                        />
                                        <Typography
                                            variant={isMd ? 'body1' : 'body2'}
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.titleFont,
                                            }}
                                        >
                                            Payment Method:
                                        </Typography>
                                    </Box>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setShowPaymentChange(true)
                                        }}
                                    >
                                        <Typography
                                            variant={isMd ? 'body2' : 'caption'}
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.boldFont,
                                                textAlign: 'right',
                                                color: primary,
                                            }}
                                        >
                                            Change payment method
                                        </Typography>
                                    </TouchableOpacity>
                                </Box>
                                <PaymentMethod
                                    brand={paymentMethods[0].card.brand}
                                    endingIn={paymentMethods[0].card.last4}
                                />
                            </Box>
                        )}
                    </Box>
                )}

                <Box
                    sx={{
                        marginBottom: isMd ? 4 : 2,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Typography
                        variant={isMd ? 'body2' : 'caption'}
                        sx={{
                            fontFamily: defaultStyles.regularFont,
                            width: '80%',
                            textAlign: 'center',
                        }}
                    >
                        The session will start after you both discuss the
                        details in the {tutor.instaBookBufferPeriod} minute
                        window provided by your tutor.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <TouchableOpacity
                    disabled={loading}
                    style={{
                        backgroundColor: green,
                        width: 100,
                        height: 40,
                        marginLeft: 5,
                        borderRadius: defaultStyles.buttonRadius,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onPress={async () => {
                        if (showPaymentChange) {
                            onSubmitPayment()
                        } else {
                            const tutorPayout =
                                (totalSessionFee + totalRecordingFee) * 0.9
                            setLoading(true)
                            finalizeInstaBookSession(
                                totalCost,
                                tutor,
                                enableRecording,
                                selectedPricingOption.length,
                                tutorPayout
                            ).finally(() => {
                                setButtonLoading(false)
                                setLoading(false)
                                setShowModal(false)
                            })
                        }
                    }}
                >
                    {loading ? (
                        <ActivityIndicator size="small" />
                    ) : (
                        <Text
                            style={{
                                color: '#fff',
                                fontSize: 16,
                                fontFamily: defaultStyles.boldFont,
                            }}
                        >
                            Start
                        </Text>
                    )}
                </TouchableOpacity>

                <TouchableOpacity
                    style={{
                        backgroundColor: redVariant,
                        width: 100,
                        height: 40,
                        marginLeft: 5,
                        borderRadius: defaultStyles.buttonRadius,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    disabled={loading}
                    onPress={() => {
                        setButtonLoading(false)
                        setShowModal(false)
                    }}
                >
                    <Text
                        style={{
                            color: '#fff',
                            fontSize: 16,
                            fontFamily: defaultStyles.boldFont,
                        }}
                    >
                        Cancel
                    </Text>
                </TouchableOpacity>
            </DialogActions>
        </Dialog>
    )
}
