import React from 'react';

const CheckIcon = ({ size = 26, color = "#000" }) => {
    const aspectRatio = 26 / 22;
    const width = size;
    const height = size / aspectRatio;

    return (
        <svg width={width} height={height} viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.28847" y="7.90161" width="7.82126" height="6.06481" transform="rotate(45 4.28847 7.90161)" fill={color} />
            <rect x="4.93739" y="17.1422" width="23" height="6" transform="rotate(-45 4.93739 17.1422)" fill={color} />
        </svg>
    );
}


export default CheckIcon;