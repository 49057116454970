// In config/levels.js
const levels = [
    { value: 'elementary', label: 'Elementary School' },
    { value: 'middle', label: 'Middle School' },
    { value: 'high', label: 'High School' },
    { value: 'college', label: 'College/University' },
    { value: 'graduate', label: 'Graduate Level' },
    { value: 'professional', label: 'Professional' },
    { value: 'test_prep', label: 'Test Preparation' },
];

const educationLevels = [
    { id: 'elementary', label: 'Elementary School' },
    { id: 'middle', label: 'Middle School' },
    { id: 'high', label: 'High School' },
    { id: 'college', label: 'College/University' },
    { id: 'graduate', label: 'Graduate Level' },
    { id: 'professional', label: 'Professional' },
    { id: 'test_prep', label: 'Test Preparation' },
]

module.exports = {
    levels,
    educationLevels
};
