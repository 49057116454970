import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import { Favourites } from '@screens/Favourites'
import { TutorProfile } from '@screens/loggedIn/Tutors/TutorProfile'

const Stack = createNativeStackNavigator()

export const FavouritesStack = () => {
    return (
        <Stack.Navigator initialRouteName="Favourites">
            <Stack.Screen
                options={{ headerShown: false }}
                name="Favourites"
                component={Favourites}
            />
            <Stack.Screen
                options={{ headerShown: false }}
                name="TutorProfile"
                component={TutorProfile}
            />
        </Stack.Navigator>
    )
}
