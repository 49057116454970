import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Notifications } from '@components/Notifications'
import { Listings } from '@screens/loggedIn/Listings/Listings'
import { StudentProfile } from '@screens/loggedIn/StudentProfile'
const Stack = createNativeStackNavigator()

export const StudentsStack = () => {
    return (
        <SafeAreaProvider>
            <Stack.Navigator initialRouteName="Listings">
                <Stack.Screen
                    options={{ headerShown: false }}
                    name="Listings"
                    component={Listings}
                />

                <Stack.Screen
                    options={{ headerShown: false }}
                    name="StudentProfile"
                    component={StudentProfile}
                />
            </Stack.Navigator>
        </SafeAreaProvider>
    )
}
