import { useColorsContext } from '@utils/styles';
import Svg, { Path } from 'react-native-svg';

const BackgroundIcon = ({
    size = 32,
}) => {
    const { text } = useColorsContext();
    return (

        <Svg viewBox="0 0 20 20" height={size} width={size}>
            <Path
                d="M0 1v3h4V1H0zm6 0v1h12V1H6zM1 2h2v1H1V2zm5 1v1h6V3H6zm0 2v1h14V5H6zm0 2v1h10V7H6zm-6 5v3h4v-3H0zm6 0v1h12v-1H6zm-5 1h2v1H1v-1zm5 1v1h6v-1H6zm0 2v1h14v-1H6zm0 2v1h10v-1H6z"
                fill={text}
                fillOpacity={1}
                stroke="none"
                strokeWidth={0}
            />
        </Svg>
    )
}


export default BackgroundIcon;