// Imports
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'

const Stack = createNativeStackNavigator()

// Import screens
import { Account } from '../../screens/loggedIn/Profile/Account'
import { EditProfile } from '../../screens/loggedIn/Profile/EditProfile'
import { InstaBook } from '../../screens/loggedIn/Profile/InstaBook'
import { Notifications } from '../../screens/loggedIn/Profile/Notifications'
import { PaymentsNew } from '../../screens/loggedIn/Profile/PaymentsNew'
import { Performance } from '../../screens/loggedIn/Profile/Performance'

import { SessionSettings } from '../../screens/loggedIn/Profile/SessionSettings'
import { Settings } from '@screens/loggedIn/Settings'

// Components
export const SettingsStack = ({ navigation, route, params }) => {
    return (
        <Stack.Navigator
            initialRouteName="Settings"
            screenOptions={{ headerShown: false }}
        >
            <Stack.Screen name="Settings" component={Settings} />
            <Stack.Screen name="Performance" component={Performance} />
            <Stack.Screen name="PaymentProfile" component={PaymentsNew} />
            <Stack.Screen name="Account" component={Account} />
            <Stack.Screen name="Notifications" component={Notifications} />
            <Stack.Screen name="InstaBook" component={InstaBook} />
            <Stack.Screen name="SessionSettings" component={SessionSettings} />
        </Stack.Navigator>
    )
}
