import React from 'react'
import { Dialog } from '@mui/material'
import { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import SendResetEmail from './SendResetEmail'
import ValidateCode from './ValidateCode'
import ResetPassword from './ResetPassword'

interface Props {
    open: boolean
    setOpen: (open: boolean) => void
    loginEmail: string
}

const ResetPasswordDialog: React.FC<Props> = ({
    open,
    setOpen,
    loginEmail,
}) => {
    const [step, setStep] = useState<
        'sendResetEmail' | 'validateCode' | 'resetPassword'
    >('sendResetEmail')

    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    useEffect(() => {
        setEmail(loginEmail)
    }, [loginEmail])

    const onClose = () => {
        setStep('sendResetEmail')
        setOpen(false)
        setCode('')
        setEmail('')
    }

    return (
        <Dialog open={open} maxWidth="sm" onClose={onClose}>
            {step === 'sendResetEmail' && (
                <SendResetEmail
                    onClose={onClose}
                    next={(email) => {
                        setEmail(email)
                        setStep('validateCode')
                    }}
                    email={email}
                />
            )}
            {step === 'validateCode' && (
                <ValidateCode
                    next={(code) => {
                        setCode(code)
                        setStep('resetPassword')
                    }}
                    onClose={onClose}
                    email={email}
                />
            )}
            {step === 'resetPassword' && (
                <ResetPassword
                    next={() => {
                        setStep('resetPassword')
                    }}
                    onClose={onClose}
                    code={code}
                    email={email}
                />
            )}
        </Dialog>
    )
}

const styles = StyleSheet.create({
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        width: '100%',
        marginTop: 10,
        marginBottom: 10,
    },
})

export default ResetPasswordDialog
