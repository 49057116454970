import React, { useEffect, useMemo, useState } from 'react'
import { A } from '@expo/html-elements'
import { AntDesign, FontAwesome, FontAwesome5 } from '@expo/vector-icons'
import * as Clipboard from 'expo-clipboard'
import * as Sharing from 'expo-sharing'
import { getAuth } from 'firebase/auth'
import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    getDocs,
    limit,
    onSnapshot,
    query,
    updateDoc,
    where,
} from 'firebase/firestore'

import { useAlert } from 'react-alert'
import {
    ActivityIndicator,
    Alert,
    Image,
    NativeEventEmitter,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useMediaQuery } from 'react-responsive'
import { Header as AuthHeader } from '@components/AuthHeader'
import { Header } from '@components/Header'
import { useColorsContext, useStyles } from '@utils/styles'

import Footer from '@components/Footer'
import { EditProfileNew } from '../../Profile/EditProfileNew'
// Import paper plane icon
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import SendIcon from '@mui/icons-material/Send'

import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import { LoadingPage } from '@components/LoadingPage'
import { ReportModal } from '@components/ReportModal'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { useSessionContext } from '@contexts/sessionContext'
import { useUserContext } from '@contexts/userContext'
import moment from 'moment-timezone'
import { provinceToTimeZone } from '@config/provinceToTimeZone'
import { stateToTimeZone } from '@config/stateToTimeZone'
import {
    Box,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from '@mui/material'
import { languageIcons, languages as languagesData } from '@config/languages'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import FontAwesome6 from '@expo/vector-icons/FontAwesome6'
import formatName from '@helpers/formatName'
import Subjects from '@components/Subjects'
import Specialties from '@components/Specialties'
import Sections from './Sections'
import ReadMore from 'react-native-read-more-text'
import useNavigateToChat from '@hooks/useNavigateToChat'
import { InstaBookConfirmModal } from '@components/InstaBookConfirmModal'
import { LoginDialog } from '@components/LoginDialog'
import CancelModal from '@components/CancelModal'
import BookSessionModal from './BookSessionModal'
import AboutMeIcon from '@icons/AboutMeIcon'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import Ionicons from '@expo/vector-icons/Ionicons'
import Favourite from '@components/Favourite'
import { decode } from 'html-entities'
import { MultiSwitchToggleOption } from '@components/MultiSwitchToggle/MutliSwitchToggleOption'
import { useDialog } from '@contexts/dialogContext'

const shareLink = window.location.origin

export const TutorProfile = ({ navigation, route }) => {
    // Firebase
    const { db } = useFirebaseContext()
    const { isTutor, user, profile, paymentMethods, extendedUser } =
        useUserContext()
    const [loaded, setLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const eventEmitter = new NativeEventEmitter()

    const [showInstaBookConfirmModal, setShowInstaBookConfirmModal] =
        useState(false)
    // Alert
    const customAlert = useAlert()

    // Responsive Breakpoints
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
    const isMd = useMediaQuery({ query: '(min-width: 768px)' })
    const isSm = useMediaQuery({ query: '(min-width: 640px)' })

    const { navigateToChat } = useNavigateToChat()

    // Appearance
    const {
        primary,
        primaryVariant,
        background,
        backgroundVariant,
        text,
        textVariant,
        border,
        green,
        textSecondary,
        grey,
        darkMode,
    } = useColorsContext()
    const defaultStyles = useStyles()

    // States and variables

    const urlId =
        route?.name === 'Profile' ? extendedUser?.URL : route?.params?.id

    const [tutorId, setTutorId] = useState<string | null>(null)
    const [totalSessions, setTotalSessions] = useState<number>(0)
    const [sessions, setSessions] = useState<any[]>([])
    const [reviews, setReviews] = useState<any[]>([])
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [shareAvailable, setShareAvailable] = useState<boolean>(false)
    const [hoursSinceOnline, setHoursSinceOnline] = useState<number>(null)
    const [tutor, setTutor] = useState<any>(null)
    const [bookable, setBookable] = useState<boolean>(false)
    const [selectedPricingIndex, setSelectedPricingIndex] = useState<number>(0)
    const { openLoginDialog } = useDialog()

    const pricingOptions = useMemo<MultiSwitchToggleOption[]>(() => {
        if (tutor?.sessionPricing) {
            return Object.keys(tutor?.sessionPricing).map((key) => {
                const pricing = tutor?.sessionPricing[key]
                return {
                    label: `${key} mins`,
                    contextLabel: ` ${decode('\u00b7')} CA$${pricing.rate || 0}`,
                    value: pricing,
                    error: undefined,
                }
            })
        } else {
            return []
        }
    }, [tutor?.sessionPricing?.[30]?.rate, tutor?.sessionPricing?.[60]?.rate])

    const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: darkMode
                ? theme.palette.common.white
                : theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: darkMode
                ? theme.palette.common.white
                : theme.palette.common.black,
            color: darkMode
                ? theme.palette.common.black
                : theme.palette.common.white,
        },
    }))

    const languages = useMemo(() => {
        console.log('tutor', tutor)
        if (!tutor?.languages) {
            return []
        }
        const spokenLanguages = []
        languagesData.forEach((language) => {
            if (tutor.languages.hasOwnProperty(language)) {
                spokenLanguages.push(language)
            }
        })
        return spokenLanguages
    }, [tutor?.languages])

    useEffect(() => {
        if (tutor?.name) {
            const tutorName =
                tutor.name.split(' ').length > 1
                    ? `${tutor.name.split(' ')[0]} ${
                          tutor.name.split(' ')[1][0]
                      }.`
                    : tutor.name
            navigation.setOptions({ title: `${tutorName} | Tutor Profile` })
        }
    }, [tutor?.name])

    const getCurrentTimeInLocation = (location) => {
        const timeZone =
            provinceToTimeZone[location] || stateToTimeZone[location] || 'UTC' // Default to UTC if the location is not found
        return moment().tz(timeZone).format('h:mm A')
    }

    // FOR TESTING

    // Stylesheet
    const styles = StyleSheet.create({
        container: {
            backgroundColor: background,
            flex: 1,
        },
        menuText: {
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
        },
        menuTextSmall: {
            color: text,
            fontSize: 14,
            fontFamily: defaultStyles.regularFont,
            marginBottom: 15,
        },

        currentTime: {
            fontSize: 16,
            color: '#555',
            marginTop: 5,
        },

        maxWidth: {
            flex: 1,
            backgroundColor: background,
            // display: 'flex',
            // flexDirection: Platform.OS === 'web' ? 'row' : null,
            // flexDirection: 'row',
            paddingRight: 0, // Padding right is for the cover photo to stretch
            width: '100%',
            // maxWidth: Platform.OS === "web" ? 1350 : null,
            margin: Platform.OS === 'web' ? 'auto' : null,
        },

        mainSection: {
            flex: 1,
            position: 'relative',
        },
        profileImg: {
            marginBottom: 5,
            borderRadius: 100,
            width: 130,
            height: 130,
            opacity: 1,
        },
        title: {
            fontSize: 32,
            fontFamily: defaultStyles.titleFont,
            color: text,
        },
        online: {
            marginLeft: 15,
            fontSize: 14,
            color: '#00AA54',
            fontFamily: defaultStyles.boldFont,
        },
        banner: {
            shadowColor: '#171717',
            shadowOffset: { width: -2, height: 4 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 50,
            backgroundColor: backgroundVariant,
        },
        verified: {
            fontSize: 14,
            color: green,
            fontFamily: defaultStyles.boldFont,
        },
        description: {
            marginTop: 5,
            fontSize: 16,
            color: text,
            fontFamily: defaultStyles.regularFont,
            maxWidth: 925,
            // paddingHorizontal: 15,
        },
        circleButton: {
            borderRadius: defaultStyles.buttonRadius,
            height: 45,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            border: '1px solid ' + primary,
            backgroundColor: 'transparent',
            width: 245,
        },
        button: {
            borderRadius: defaultStyles.buttonRadius,
            height: 45,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: primary,
            width: 245,
        },
        disabledButton: {
            opacity: 0.5,
        },
        buttonText: {
            color: '#fff',
            fontSize: 19,
            fontFamily: defaultStyles.titleFont,
        },
        tutorInfo: {
            fontSize: 16,
            color: textVariant,
            fontFamily: defaultStyles.regularFont,
        },
        tutorDescriptionTitles: {
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.titleFont,
        },
        showMore: {
            color: textVariant,
            fontFamily: defaultStyles.boldFont,
            fontSize: 16,
        },
        actionText: {
            color: textVariant,
            fontFamily: defaultStyles.regularFont,
            fontSize: 14,
            marginLeft: 5,
            textDecorationColor: textVariant,
            textDecorationLine: 'underline',
        },
    })

    // Get reviews & tutor
    useEffect(() => {
        // Set sharing
        Sharing.isAvailableAsync().then((result) => {
            setShareAvailable(result)
        })
        // Get tutor data
        const q = query(
            collection(db, 'users'),
            where('URL', '==', parseInt(urlId)),
            where('isTutor', '==', true),
            where('isSignedUp', '==', true),
            limit(1)
        )
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let tutor = doc.data()
                let today = new Date()
                var hoursSinceOnline
                if (tutor.lastOnline == null) {
                    hoursSinceOnline = null
                } else {
                    let lastOnline = new Date(tutor.lastOnline.toDate())
                    hoursSinceOnline = Math.floor(
                        (today - lastOnline) / (1000 * 60 * 60)
                    )
                }
                // console.log(tutor, 'tutor');
                setTutorId(tutor.uid)
                setTutor(tutor)
                setHoursSinceOnline(hoursSinceOnline)
                if (!tutor.unavailable) {
                    setBookable(true)
                }
                // if (tutor.stripe && tutor.stripe.confirmed) {
                //   setBookable(true);
                // }
            })
        })
        return () => {
            unsubscribe()
            setTutorId(null)
            setTutor(null)
            setBookable(false)
            setHoursSinceOnline(null)
        }
    }, [urlId])

    useEffect(() => {
        if (tutorId) {
            setLoaded(true)
            // Snapshot listener for total tutor sessions
            const unsubscribefromTutor = onSnapshot(
                query(
                    collection(db, 'sessions'),
                    where('tutor', '==', tutorId)
                ),
                where('status', '==', 'completed'),
                (querySnapshot) => {
                    let sessions = []
                    querySnapshot.forEach((doc) => {
                        let session = doc.data()
                        if (
                            session.status == 'completed' &&
                            session.lengthInMinutes > 0
                        ) {
                            sessions.push(session)
                        }
                    })
                    setSessions(sessions)
                    setTotalSessions(sessions.length)
                }
            )
            // Snapshot listener for tutor reviews
            const unsubscribe = onSnapshot(
                query(collection(db, 'reviews'), where('tutor', '==', tutorId)),
                (querySnapshot) => {
                    let reviews = []
                    querySnapshot.forEach((doc) => {
                        let review = doc.data()
                        reviews.push(review)
                    })
                    setReviews(reviews)
                }
            )
            return () => {
                setReviews([])
                setLoaded(false)
                setSessions([])
                setTotalSessions(0)
                unsubscribe()
                unsubscribefromTutor()
            }
        }
    }, [tutorId])

    const rating = useMemo(() => {
        if (reviews.length === 0) {
            return 0
        }
        let total = 0
        reviews.forEach((review) => {
            total += review.rating
        })
        return (total / reviews.length).toFixed(1)
    }, [reviews])

    if (!loaded) {
        return <LoadingPage></LoadingPage>
    }
    // Return 404
    if (!tutor && loaded) {
        return (
            <SafeAreaView style={styles.container}>
                <View style={styles.maxWidth}>
                    <View
                        style={[
                            styles.mainSection,
                            {
                                alignItems: 'center',
                                justifyContent: 'center',
                            },
                        ]}
                    >
                        <Text style={styles.title}>404 Tutor not found</Text>
                    </View>
                </View>
            </SafeAreaView>
        )
    }

    // Sort the ratings from highest rating to lowest
    reviews.sort((a, b) => {
        return b.rating - a.rating
    })

    // Render
    return (
        <SafeAreaView style={styles.container}>
            <InstaBookConfirmModal
                tutor={tutor}
                showModal={showInstaBookConfirmModal}
                setShowModal={setShowInstaBookConfirmModal}
                setButtonLoading={setLoading}
            />
            {/* If viewing your own profile show banner */}

            {user && user.uid === tutor.uid ? (
                <View style={[styles.banner]}>
                    <Text
                        style={{
                            color: text,
                            fontFamily: defaultStyles.boldFont,
                            fontSize: 16,
                        }}
                    >
                        You are viewing your profile as a student
                        {/* Public icon */}
                        <FontAwesome5
                            name="globe-americas"
                            size={16}
                            color={primaryVariant}
                            style={{ marginLeft: 5 }}
                        ></FontAwesome5>
                    </Text>
                </View>
            ) : null}

            <View style={styles.maxWidth}>
                {/* Tutor info section  */}
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={styles.mainSection}
                >
                    {/* MODAL  */}
                    <BookSessionModal
                        visible={modalVisible}
                        setVisible={setModalVisible}
                        tutor={tutor}
                        pricingOptions={pricingOptions}
                        selectedPricingIndex={selectedPricingIndex}
                        setSelectedPricingIndex={setSelectedPricingIndex}
                    />

                    {/* REPORT MODAL  */}
                    <ReportModal
                        modalVisible={reportModal}
                        setModalVisible={setReportModal}
                        tutor={tutor}
                        navigation={navigation}
                    />

                    <View
                        style={{
                            display: 'flex',
                            // LAYOUT FLEX
                            flexDirection: isLg ? 'row' : 'column',
                            width: '100%',
                            paddingHorizontal: isMd ? 24 : 0,
                            paddingTop: 16,
                        }}
                    >
                        <View
                            style={{
                                // Set width for all breakpoints
                                borderRightColor: border,
                                borderRightWidth: !isLg ? 0 : 1,
                                maxWidth: isLg ? 300 : '100%',
                                minWidth: isLg ? 300 : '100%',
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    paddingHorizontal: isMd ? 16 : 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: !isSm && '100%',
                                    borderBottomColor: border,
                                    borderBottomWidth: !isLg ? 1 : 0,
                                }}
                            >
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 30,
                                    }}
                                >
                                    <TouchableOpacity
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                        onPress={() => {
                                            navigation.navigate('Tutors')
                                        }}
                                    >
                                        <NavigateBeforeIcon
                                            style={{
                                                fontSize: 30,
                                                color: text,
                                                cursor: 'pointer',
                                                marginLeft: -10,
                                            }}
                                        ></NavigateBeforeIcon>

                                        <Text
                                            style={{
                                                color: text,
                                                fontSize: 19,
                                                fontFamily:
                                                    defaultStyles.boldFont,
                                            }}
                                        >
                                            {!isTutor
                                                ? 'More tutors'
                                                : 'Sessions'}
                                        </Text>
                                    </TouchableOpacity>

                                    {/* Report and Favourite */}
                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* Report */}
                                        <TouchableOpacity
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                            onPress={() => {
                                                if (
                                                    user &&
                                                    user.uid == tutor.uid
                                                ) {
                                                    customAlert.error(
                                                        "[Error] You can't report yourself"
                                                    )
                                                } else {
                                                    if (!user) {
                                                        customAlert.error(
                                                            '[Error] Please sign in to report a tutor'
                                                        )
                                                    }
                                                    setReportModal(true)
                                                }
                                            }}
                                        >
                                            <Ionicons
                                                name="alert-circle-outline"
                                                size={24}
                                                color={text}
                                            />
                                        </TouchableOpacity>
                                    </View>
                                </View>

                                <Box>
                                    <Favourite
                                        tutor={tutor}
                                        containerStyle={{
                                            position: 'absolute',
                                            right: 0,
                                        }}
                                        iconStyle={{
                                            position: 'relative',
                                            top: 0,
                                            right: 18,
                                        }}
                                    />

                                    <Image
                                        style={styles.profileImg}
                                        source={{
                                            uri: tutor.picture
                                                ? tutor.picture
                                                : 'https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e',
                                        }}
                                    ></Image>
                                </Box>
                                {/* Name and online badge */}
                                <View>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginTop: 2,
                                        }}
                                    >
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                color: text,
                                            }}
                                        >
                                            {formatName(tutor.name)}
                                        </Typography>
                                        {tutor.isVerified && (
                                            <BootstrapTooltip
                                                title="Verified ID"
                                                placement="top-end"
                                            >
                                                <View>
                                                    <AntDesign
                                                        name="checkcircle"
                                                        size={20}
                                                        color={primary}
                                                        style={{
                                                            marginLeft: 8,
                                                        }}
                                                    />
                                                </View>
                                            </BootstrapTooltip>
                                        )}
                                    </Box>
                                    {/* Tutor info -Location, Rating, Sessions, Join date */}
                                    <View
                                        style={{
                                            marginTop: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {/* Languages */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'flex-start',
                                                }}
                                            >
                                                <MaterialIcons
                                                    name="translate"
                                                    size={20}
                                                    color={primary}
                                                    style={{
                                                        marginRight: 4,
                                                        marginTop: 6,
                                                    }}
                                                />

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'flex-end',
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <Typography
                                                        fontFamily="Poppins_500Medium"
                                                        sx={{
                                                            marginRight: 0.75,
                                                        }}
                                                    >
                                                        I Speak
                                                    </Typography>
                                                    {languages.map(
                                                        (language) => {
                                                            return (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                        alignItems:
                                                                            'flex-end',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        fontFamily="Poppins_500Medium"
                                                                        sx={{
                                                                            marginRight: 0.75,
                                                                            marginLeft: 0.5,
                                                                        }}
                                                                    >
                                                                        {
                                                                            language
                                                                        }
                                                                    </Typography>
                                                                    <img
                                                                        src={
                                                                            languageIcons[
                                                                                language
                                                                            ]
                                                                        }
                                                                        alt={
                                                                            language
                                                                        }
                                                                        width={
                                                                            28
                                                                        }
                                                                        height={
                                                                            28
                                                                        }
                                                                        style={{
                                                                            objectFit:
                                                                                'contain',
                                                                            marginBottom: 2,
                                                                        }}
                                                                    />
                                                                </Box>
                                                            )
                                                        }
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                        {/* Location */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                marginTop: 1,
                                            }}
                                        >
                                            <FontAwesome6
                                                name="location-dot"
                                                size={20}
                                                color="#EA4335"
                                                style={{ marginRight: 8 }}
                                            />
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color: text,
                                                }}
                                            >
                                                {tutor.location.province}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    marginLeft: 4,
                                                }}
                                            >
                                                {getCurrentTimeInLocation(
                                                    tutor.location.province ||
                                                        tutor.location.state
                                                )}
                                            </Typography>
                                        </Box>

                                        <View
                                            style={{
                                                marginTop: 15,
                                                flexDirection: isSm
                                                    ? 'row'
                                                    : 'column',
                                            }}
                                        >
                                            <View
                                                style={{ flexDirection: 'row' }}
                                            >
                                                {!isTutor &&
                                                    user?.uid !==
                                                        tutor?.uid && (
                                                        <TouchableOpacity
                                                            disabled={
                                                                user?.uid ==
                                                                    tutor.uid ||
                                                                isTutor ||
                                                                !bookable
                                                            }
                                                            onPress={() => {
                                                                if (!user) {
                                                                    openLoginDialog(
                                                                        'Please log in to send a message'
                                                                    )
                                                                }
                                                                navigateToChat(
                                                                    tutor,
                                                                    user,
                                                                    profile
                                                                )
                                                            }}
                                                            style={[
                                                                styles.circleButton,
                                                                {
                                                                    marginTop:
                                                                        isSm
                                                                            ? 0
                                                                            : 0,
                                                                    marginRight:
                                                                        isSm
                                                                            ? 20
                                                                            : 0,
                                                                    opacity:
                                                                        bookable
                                                                            ? 1
                                                                            : 0.7,
                                                                },
                                                            ]}
                                                        >
                                                            <Text
                                                                style={[
                                                                    styles.buttonText,
                                                                    {
                                                                        color: primary,
                                                                        marginRight: 10,
                                                                    },
                                                                ]}
                                                            >
                                                                Message
                                                            </Text>

                                                            <SendIcon
                                                                style={{
                                                                    fontSize: 20,
                                                                    color: primary,
                                                                }}
                                                            ></SendIcon>
                                                        </TouchableOpacity>
                                                    )}
                                            </View>
                                        </View>

                                        {/* Book buttons */}
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                alignItems: 'center',
                                                gap: 8,
                                                width: '100%',
                                                marginTop: 10,
                                            }}
                                        >
                                            {!user && (
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        if (user) {
                                                            if (
                                                                extendedUser.isTutor
                                                            ) {
                                                                customAlert.error(
                                                                    '[Booking Error] Only students can book sessions!'
                                                                )
                                                            } else {
                                                                setModalVisible(
                                                                    !modalVisible
                                                                )
                                                            }
                                                        } else {
                                                            openLoginDialog(
                                                                'Please log in to book a session'
                                                            )
                                                            eventEmitter.addListener(
                                                                'login-success',
                                                                ({
                                                                    extendedUser,
                                                                }) => {
                                                                    if (
                                                                        extendedUser.isTutor
                                                                    ) {
                                                                        navigation.navigate(
                                                                            'Sessions'
                                                                        )
                                                                    } else {
                                                                        setModalVisible(
                                                                            !modalVisible
                                                                        )
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    }}
                                                    style={[
                                                        styles.button,
                                                        {
                                                            marginTop: isSm
                                                                ? 0
                                                                : 15,
                                                        },
                                                    ]}
                                                >
                                                    <Text
                                                        style={
                                                            styles.buttonText
                                                        }
                                                    >
                                                        Book ${tutor.rate}/hr
                                                    </Text>
                                                </TouchableOpacity>
                                            )}

                                            {tutor &&
                                                user?.uid !== tutor.uid && (
                                                    <BootstrapTooltip
                                                        placement="top"
                                                        title={
                                                            !tutor.instaBook ||
                                                            !bookable
                                                                ? 'This tutor is not available right now'
                                                                : ''
                                                        }
                                                    >
                                                        <View>
                                                            <TouchableOpacity
                                                                disabled={
                                                                    !tutor.instaBook ||
                                                                    !bookable ||
                                                                    loading
                                                                }
                                                                onPress={() => {
                                                                    if (
                                                                        !tutor?.instaBookPricing
                                                                    ) {
                                                                        customAlert.error(
                                                                            '[Error Booking] This tutor hasnt set up their pricing yet, please contact support'
                                                                        )
                                                                    } else if (
                                                                        !user
                                                                    ) {
                                                                        openLoginDialog(
                                                                            'Please log in to start an instabook session'
                                                                        )

                                                                        eventEmitter.addListener(
                                                                            'login-success',
                                                                            () => {
                                                                                setLoading(
                                                                                    true
                                                                                )
                                                                                setShowInstaBookConfirmModal(
                                                                                    true
                                                                                )
                                                                            }
                                                                        )
                                                                    } else {
                                                                        if (
                                                                            paymentMethods.length ===
                                                                            0
                                                                        ) {
                                                                            confirmAlert(
                                                                                {
                                                                                    customUI:
                                                                                        ({
                                                                                            onClose,
                                                                                        }) => {
                                                                                            return (
                                                                                                <CancelModal
                                                                                                    onClose={() => {
                                                                                                        onClose()
                                                                                                    }}
                                                                                                    title="No Payment Methods Found"
                                                                                                    message={
                                                                                                        'In order to book a tutor, you must add a payment method to your account, please click the button below to add a payment method you will be redirected to the settings page. Once you have added a payment method, please try booking the tutor again.'
                                                                                                    }
                                                                                                    onAccept={() => {
                                                                                                        onClose()
                                                                                                        navigation.navigate(
                                                                                                            'Home',
                                                                                                            {
                                                                                                                screen: 'Profile',
                                                                                                            }
                                                                                                        )
                                                                                                    }}
                                                                                                    colors={{
                                                                                                        primary,
                                                                                                        text,
                                                                                                        background,
                                                                                                        border,
                                                                                                    }}
                                                                                                    defaultStyles={
                                                                                                        defaultStyles
                                                                                                    }
                                                                                                ></CancelModal>
                                                                                            )
                                                                                        },
                                                                                }
                                                                            )
                                                                        } else {
                                                                            setLoading(
                                                                                true
                                                                            )
                                                                            // Confirm alert
                                                                            setShowInstaBookConfirmModal(
                                                                                true
                                                                            )
                                                                        }
                                                                    }
                                                                }}
                                                                style={[
                                                                    styles.button,
                                                                    {
                                                                        opacity:
                                                                            loading
                                                                                ? 0.5
                                                                                : 1,
                                                                        backgroundColor:
                                                                            !tutor.instaBook ||
                                                                            !bookable
                                                                                ? grey
                                                                                : primary,
                                                                        marginRight:
                                                                            isSm
                                                                                ? 15
                                                                                : 0,
                                                                        marginTop:
                                                                            isSm
                                                                                ? 0
                                                                                : 15,
                                                                    },
                                                                ]}
                                                            >
                                                                <Text
                                                                    style={
                                                                        styles.buttonText
                                                                    }
                                                                >
                                                                    {loading ? (
                                                                        <>
                                                                            <ActivityIndicator
                                                                                size="small"
                                                                                color="#fff"
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            Instabook
                                                                            <MaterialCommunityIcons
                                                                                name="lightning-bolt-outline"
                                                                                size={
                                                                                    24
                                                                                }
                                                                                color="#fff"
                                                                                style={{
                                                                                    marginRight: 8,
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </BootstrapTooltip>
                                                )}

                                            {user &&
                                                tutor &&
                                                user.uid !== tutor.uid && (
                                                    <TouchableOpacity
                                                        disabled={!bookable}
                                                        onPress={() => {
                                                            if (user) {
                                                                setModalVisible(
                                                                    !modalVisible
                                                                )
                                                            } else {
                                                                if (
                                                                    Platform.OS ===
                                                                    'web'
                                                                ) {
                                                                    customAlert.error(
                                                                        '[Error Booking] Please sign in to book a tutor'
                                                                    )
                                                                } else {
                                                                    Alert.alert(
                                                                        'Error booking tutor',
                                                                        'Please sign in to book a tutor',
                                                                        [
                                                                            {
                                                                                text: 'OK',
                                                                                onPress:
                                                                                    () =>
                                                                                        console.log(
                                                                                            'OK Pressed'
                                                                                        ),
                                                                            },
                                                                        ]
                                                                    )
                                                                }
                                                            }
                                                        }}
                                                        style={[
                                                            styles.button,
                                                            {
                                                                marginTop: isSm
                                                                    ? 0
                                                                    : 15,
                                                                opacity:
                                                                    bookable
                                                                        ? 1
                                                                        : 0.7,
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={
                                                                styles.buttonText
                                                            }
                                                        >
                                                            {bookable
                                                                ? `Book $${tutor.rate}/hr`
                                                                : 'Tutor unavailable'}
                                                        </Text>
                                                    </TouchableOpacity>
                                                )}

                                            {isTutor &&
                                                user &&
                                                user.uid === tutor.uid && (
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            navigation.navigate(
                                                                'EditProfile'
                                                            )
                                                        }}
                                                        style={[
                                                            styles.button,
                                                            {
                                                                marginTop: isSm
                                                                    ? 0
                                                                    : 15,
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={
                                                                styles.buttonText
                                                            }
                                                        >
                                                            Edit profile
                                                        </Text>
                                                    </TouchableOpacity>
                                                )}
                                        </View>

                                        {/* Share buttons */}
                                        <View
                                            style={{
                                                maxWidth: 245,
                                                marginTop: 15,
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <TouchableOpacity
                                                style={{
                                                    width: 35,
                                                    height: 35,
                                                    borderRadius: 100,
                                                    borderColor: text,
                                                    borderWidth: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                onPress={() => {
                                                    Clipboard.setString(
                                                        `${shareLink}/tutors/` +
                                                            urlId
                                                    )
                                                    customAlert.success(
                                                        '[Share Tutor] Copied tutor profile link to your clipboard.'
                                                    )
                                                }}
                                            >
                                                <FontAwesome5
                                                    style={{
                                                        fontSize: 16,
                                                        color: text,
                                                    }}
                                                    name="clipboard"
                                                ></FontAwesome5>
                                            </TouchableOpacity>

                                            <A
                                                style={{
                                                    width: 35,
                                                    height: 35,
                                                    borderRadius: 100,
                                                    borderColor: text,
                                                    borderWidth: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginLeft: 10,
                                                }}
                                                target="_blank"
                                                href={
                                                    `sms:&body=Check out this tutor on Tutred! ${shareLink}/tutors/` +
                                                    urlId
                                                }
                                            >
                                                <AntDesign
                                                    style={{
                                                        fontSize: 16,
                                                        color: text,
                                                    }}
                                                    name="message1"
                                                ></AntDesign>
                                            </A>

                                            <A
                                                style={{
                                                    width: 35,
                                                    height: 35,
                                                    borderRadius: 100,
                                                    borderColor: text,
                                                    borderWidth: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginLeft: 10,
                                                }}
                                                target="_blank"
                                                href={
                                                    `https://twitter.com/intent/tweet?text=Check out this tutor on Tutred! ${shareLink}/tutors/` +
                                                    urlId
                                                }
                                            >
                                                <FontAwesome5
                                                    style={{
                                                        fontSize: 16,
                                                        color: text,
                                                    }}
                                                    name="twitter"
                                                ></FontAwesome5>
                                            </A>

                                            <A
                                                style={{
                                                    width: 35,
                                                    height: 35,
                                                    borderRadius: 100,
                                                    borderColor: text,
                                                    borderWidth: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginLeft: 10,
                                                }}
                                                target="_blank"
                                                href={
                                                    `https://www.facebook.com/sharer/sharer.php?u=${shareLink}/tutors/` +
                                                    urlId
                                                }
                                            >
                                                <FontAwesome5
                                                    style={{
                                                        fontSize: 16,
                                                        color: text,
                                                    }}
                                                    name="facebook"
                                                ></FontAwesome5>
                                            </A>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* Right Section  */}

                        <View
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignContent: 'flex-start',
                                justifyContent: 'flex-start',
                                padding: isMd ? 30 : 0,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FontAwesome6
                                        name="dollar-sign"
                                        size={20}
                                        color={text}
                                        style={{
                                            marginLeft: 16,
                                            marginRight: 2,
                                        }}
                                    />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: text,
                                        }}
                                    >{`${tutor.rate}/hr`}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FontAwesome
                                        name="star"
                                        size={20}
                                        color="#FDCC0D"
                                        style={{
                                            marginLeft: 12,
                                            marginRight: 2,
                                        }}
                                    />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color:
                                                reviews.length > 0
                                                    ? text
                                                    : textSecondary,
                                        }}
                                    >
                                        {reviews.length > 0
                                            ? rating
                                            : 'Unrated'}
                                    </Typography>
                                    {reviews.length > 0 && (
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: textSecondary,
                                                marginLeft: 0.25,
                                            }}
                                        >
                                            ({reviews.length})
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginBottom: 2,
                                    color: text,
                                }}
                            >
                                {tutor.headline}
                            </Typography>
                            {tutor.description && (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 2,
                                        }}
                                    >
                                        <AboutMeIcon
                                            style={{
                                                margin: -7,
                                                marginRight: 7,
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: text,
                                            }}
                                        >
                                            About me
                                        </Typography>
                                    </Box>
                                    <ReadMore numberOfLines={3}>
                                        <Typography
                                            sx={{
                                                color: text,
                                            }}
                                            variant="body1"
                                        >
                                            {tutor.description}
                                        </Typography>
                                    </ReadMore>
                                </>
                            )}
                            <Subjects subjects={tutor.subjects} />
                            <Specialties specialties={tutor.skills} />

                            {/* Availabilty */}

                            <Sections tutor={tutor} />

                            {/* Reviews  */}
                            {/* <View
								style={{
									display: "flex",
									flexDirection: !isMd ? "column" : "row",
									justifyContent: "space-between",
									marginTop: 15,
								}}
							>
								<Text
									style={{
										color: textVariant,
										fontFamily: defaultStyles.boldFont,
										fontSize: 16,
									}}
								>
									Reviews
								</Text>
								<View
									style={{
										width: "85%",
									}}
								>
									{reviews.length < 1 ? (
										<Text
											style={{
												color: text,
												fontSize: 16,
												fontFamily:
													defaultStyles.regularFont,
											}}
										>
											No reviews yet...
										</Text>
									) : (
										<ScrollView
											style={{
												maxHeight: 350,
											}}
										>
											{reviews.map((review, index) => {
												return (
													<TutorReview
														key={`review-${index}`}
														user={review.user}
														review={review}
													></TutorReview>
												);
											})}
										</ScrollView>
									)}
								</View>
							</View> */}
                        </View>
                    </View>

                    {/* Footer */}
                    {isSm ? (
                        <View style={{ marginTop: 100, width: '100%' }}>
                            <Footer></Footer>{' '}
                        </View>
                    ) : null}
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}
