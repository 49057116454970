import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";

import { useUserContext } from "../contexts/userContext";

import { TutorProfile } from "../screens/loggedIn/Tutors/TutorProfile";
import TutorList from "@screens/TutorList";

const Stack = createNativeStackNavigator();

export const TutorsStack = () => {

	return (
		<Stack.Navigator initialRouteName="Tutors">
			<Stack.Screen
				options={{ headerShown: false }}
				name="TutorList"
				component={TutorList}
			/>
			<Stack.Screen
				options={{ headerShown: false }}
				name="TutorProfile"
				component={TutorProfile}
			/>
		</Stack.Navigator>
	);
};
