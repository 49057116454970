import React from 'react'
import { Box, Divider, Typography, Button } from '@mui/material'
import { useNotificationsContext } from '../../../contexts/notificationsContext'
import NotificationItem from '../NotificationItem'
import { FlatList } from 'react-native'
import EmptyNotifications from '../EmptyNotifications'
import UnreadMailIcon from '@icons/unreadMailIcon'
import { useColorsContext, useStyles } from '@utils/styles'
import NotificationListHeader from '../NotificationListHeader'

const AllNotifications = () => {
    const { notifications, markAllAsRead } = useNotificationsContext()

    return (
        <Box sx={{ mt: 4 }}>
            <FlatList
                ListHeaderComponent={
                    <NotificationListHeader showMarkAllAsRead={true} />
                }
                data={notifications}
                renderItem={({ item, index }) => (
                    <NotificationItem
                        notification={item}
                        lastNotification={notifications[index - 1]}
                        index={index}
                        type="all"
                    />
                )}
                ListEmptyComponent={
                    <EmptyNotifications title="Nothing to see..." />
                }
            />
        </Box>
    )
}

export default AllNotifications
