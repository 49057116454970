import { Box, Typography } from '@mui/material'
import { useColorsContext } from '@utils/styles'
import { format, getDate, isPast, isSameDay, isToday } from 'date-fns'
import React, { useCallback } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

interface Props {
    setSelectedDay: React.Dispatch<React.SetStateAction<Date>>
    selectedDay: Date
    days: Date[]
}

const AgendaDaysOfTheWeek: React.FC<Props> = ({
    setSelectedDay,
    selectedDay,
    days,
}) => {
    const { primaryLight, primary, text } = useColorsContext()

    return (
        <Box sx={styles.container}>
            {days.map((day, index) => {
                const isSelected = isSameDay(day, selectedDay)
                const isPastDay = isPast(day) && !isToday(day)
                return (
                    <TouchableOpacity
                        key={`${day}-${index}`}
                        style={[
                            styles.day,
                            isSelected && {
                                backgroundColor: primaryLight,
                            },
                            isPastDay && {
                                opacity: 0.5,
                            },
                        ]}
                        disabled={isPastDay}
                        onPress={() => setSelectedDay(day)}
                    >
                        <Typography
                            variant="body2"
                            sx={[
                                {
                                    color: isSelected ? primary : text,
                                },
                            ]}
                        >
                            {format(day, 'eee')}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ color: isSelected ? primary : text }}
                        >
                            {getDate(day)}
                        </Typography>
                    </TouchableOpacity>
                )
            })}
        </Box>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 1,
    },
    day: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 16,
    },
    selected: {
        backgroundColor: '#f5f5f5',
        borderRadius: 16,
    },
    dayText: {
        fontSize: 16,
    },
})

export default AgendaDaysOfTheWeek
