import { useColorsContext } from '@utils/styles';
import Svg, { Path } from 'react-native-svg';

const ExamPrepIcon = ({
    size = 20,
    style,
}) => {

    const { text, textInverse } = useColorsContext();
    return (
        <Svg viewBox="0 0 24 24" width={size} height={size} style={style}>
            <Path
                d="M18.5 21.5h-13a1 1 0 0 1-1-1v-17a1 1 0 0 1 1-1h9.023a3.983 3.983 0 0 1 2.386.784 9.261 9.261 0 0 1 1.807 1.807 3.983 3.983 0 0 1 .784 2.386V20.5a1 1 0 0 1-1 1Z"
                stroke={text}
                fill={textInverse}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            />
            <Path
                d="M15.5 4.5v2h2a2 2 0 0 1 2 2v1M7.5 16.5s2-5 2-8l3 7"
                stroke={text}
                fill={textInverse}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            />
            <Path
                d="M8.5 14.5a6.194 6.194 0 0 0 4-2M14.834 11.698l.531 2.867M13.666 13.4a6.056 6.056 0 0 0 2.867-.531"
                stroke={text}
                fill={textInverse}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            />
        </Svg>
    );
};

export default ExamPrepIcon;