import { useColorsContext } from '@utils/styles';
import Svg, { Path, G } from 'react-native-svg';

const TakePhotoIcon = ({ size = 32 }) => {
    const { text } = useColorsContext();
    return (
        <Svg width={size} height={size - 2} viewBox="0 0 75 75" fill="none">
            <Path
                d="M71.875 59.375C71.875 61.0326 71.2165 62.6223 70.0444 63.7944C68.8723 64.9665 67.2826 65.625 65.625 65.625H9.375C7.7174 65.625 6.12769 64.9665 4.95558 63.7944C3.78348 62.6223 3.125 61.0326 3.125 59.375V25C3.125 23.3424 3.78348 21.7527 4.95558 20.5806C6.12769 19.4085 7.7174 18.75 9.375 18.75H21.875L28.125 9.375H46.875L53.125 18.75H65.625C67.2826 18.75 68.8723 19.4085 70.0444 20.5806C71.2165 21.7527 71.875 23.3424 71.875 25V59.375Z"
                stroke={text}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M37.5 53.125C44.4036 53.125 50 47.5286 50 40.625C50 33.7214 44.4036 28.125 37.5 28.125C30.5964 28.125 25 33.7214 25 40.625C25 47.5286 30.5964 53.125 37.5 53.125Z"
                stroke={text}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>

    );
};

export default TakePhotoIcon;
