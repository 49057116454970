export default function formatName(fullName, trailingPeriod = true) {
    if (!fullName) return "";
    const name = fullName.split(" ");

    const firstName = name[0];
    const lastName = name[1];

    return `${capitalize(firstName)} ${lastName && capitalize(lastName)[0]}${trailingPeriod ? "." : ""}`;
}


const capitalize = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}