import { useColorsContext } from '@utils/styles';
import React from 'react';

const SessionRateIcon = ({ size = 48 }) => {
    const { text } = useColorsContext()
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 9V39H48V9H0ZM44 35H15V30H11V35H4V13H11V18H15V13H44V35Z"
                fill={text}
            />
            <rect x="11" y="21" width="4" height="6" fill={text} />
            <path
                d="M30.9 22.711C29.677 22.153 28.927 21.769 28.652 21.558C28.376 21.348 28.24 21.108 28.24 20.842C28.24 20.594 28.347 20.386 28.565 20.219C28.782 20.052 29.128 19.968 29.607 19.968C30.531 19.968 31.628 20.26 32.899 20.842L34.025 18.005C32.889 17.5 31.771 17.196 30.67 17.083V15.016H27.701V17.268C27.027 17.443 26.443 17.708 25.961 18.08C25.049 18.78 24.593 19.757 24.593 21.01C24.593 21.68 24.699 22.26 24.912 22.749C25.127 23.239 25.455 23.673 25.898 24.051C26.341 24.43 27.008 24.82 27.895 25.223C28.874 25.663 29.476 25.954 29.703 26.097C29.929 26.241 30.094 26.381 30.196 26.52C30.298 26.66 30.35 26.823 30.35 27.009C30.35 27.306 30.223 27.549 29.969 27.738C29.714 27.927 29.315 28.022 28.769 28.022C28.136 28.022 27.442 27.92 26.686 27.719C25.929 27.518 25.201 27.237 24.499 26.877V30.151C25.164 30.467 25.802 30.689 26.416 30.816C26.786 30.892 27.226 30.938 27.701 30.968V32.983H30.67V30.76C30.971 30.679 31.263 30.586 31.532 30.464C32.338 30.102 32.955 29.592 33.383 28.939C33.812 28.285 34.026 27.536 34.026 26.693C34.026 25.775 33.797 25.013 33.34 24.409C32.884 23.804 32.071 23.238 30.9 22.711Z"
                fill={text}
            />
        </svg>
    )
}

export default SessionRateIcon
