import { subjectIcons, subjects } from '@config/subjects'
import SearchIcon from '@icons/SearchIcon'
import {
    Autocomplete,
    Box,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'

const SearchTutors = () => {
    const defaultStyles = useStyles()
    const { primary } = useColorsContext()
    const theme = useTheme()
    const [search, setSearch] = React.useState('')
    const navigation: NavigationProp<ParamListBase> = useNavigation()

    return (
        <Box
            sx={{
                width: '100%',
                padding: 1,
                mt: 6,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography
                variant="h4"
                fontFamily={defaultStyles.boldFont}
                sx={{ mb: { sm: 2, md: 4, lg: 8 } }}
                fontSize={{ xs: '1.5rem', sm: '2rem', md: '2.5rem' }}
            >
                Browse <span style={{ color: primary }}>tutors</span> by subject
            </Typography>
            <Box
                sx={{
                    minHeight: 200,
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="img"
                    src={require('@assets/images/landingpage/search-tutors1.svg')}
                    alt="Search Tutors"
                    sx={{ width: '100%', position: 'absolute' }}
                />
                <Box sx={{ width: '80%', margin: 'auto' }}>
                    <Autocomplete
                        value={search}
                        options={subjects}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => {
                            setSearch(value)
                            if (value) {
                                navigation.navigate('Tutors', {
                                    screen: 'TutorList',
                                    params: { subject: value },
                                })
                            }
                        }}
                        sx={{
                            '& .Mui-Autocomplete-root': {
                                zIndex: 9999,
                            },
                        }}
                        renderOption={(props, option) => {
                            return (
                                <Box
                                    {...props}
                                    key={option}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        p: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: 28,
                                            height: 28,
                                            backgroundColor:
                                                theme.palette.grey[800],
                                            WebkitMask: `url(${subjectIcons[option]}) no-repeat center `,
                                            mask: `url(${subjectIcons[option]}) no-repeat center `,
                                            maskSize: 'contain',
                                            WebkitMaskSize: 'contain',
                                            mr: 2,
                                        }}
                                    />
                                    <Typography>{option}</Typography>
                                </Box>
                            )
                        }}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                placeholder="Search for a subject"
                                slotProps={{
                                    input: {
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                                sx={{ ml: 1 }}
                                            >
                                                <SearchIcon size={24} />
                                            </InputAdornment>
                                        ),
                                        style: {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                                {...params}
                            ></TextField>
                        )}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default SearchTutors
