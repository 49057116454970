import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useColorsContext } from '@utils/styles';

const PlainBackground = ({ size = 31 }) => {
    const { text } = useColorsContext();

    return (
        <Svg width={size} height={size * (25 / 31)} viewBox="0 0 31 25" fill="none">
            <Path
                d="M6.75001 19.7014V22.7945L5.37718 24.1668H2.28551L6.75001 19.7014ZM30.0857 16.0512V19.1458L25.0661 24.1668H24.2407L24.2413 21.8962L30.0857 16.0512ZM10.7604 15.4168H20.2319C21.5709 15.4168 22.669 16.448 22.7755 17.7596L22.7839 17.9689V24.1668H20.5964V17.9689C20.5964 17.7964 20.4766 17.6517 20.3154 17.614L20.2319 17.6043H10.7604C10.5878 17.6043 10.4433 17.7242 10.4055 17.8854L10.3958 17.9689V24.1668H8.20834V17.9689C8.20834 16.6299 9.23956 15.5317 10.5511 15.4253L10.7604 15.4168ZM9.69774 10.1899C9.7848 11.0338 10.0518 11.8245 10.4598 12.5226L0.916138 22.0668V18.9722L9.69774 10.1899ZM30.0857 9.48433V12.5804L24.2413 18.4283L24.2423 17.9689L24.235 17.7246C24.1952 17.0618 23.9944 16.4425 23.6712 15.9051L30.0857 9.48433ZM15.5826 0.833496L0.916138 15.5043V12.4112L12.4895 0.833496H15.5826ZM30.0857 2.92183V6.01787L21.8214 14.2859C21.4316 14.1173 21.0096 14.009 20.5673 13.9724L20.2319 13.9585L19.3585 13.9592C19.8568 13.5194 20.2794 12.9957 20.6043 12.4103L30.0857 2.92183ZM15.5 5.20917C17.9163 5.20917 19.875 7.16793 19.875 9.58422C19.875 12.0004 17.9163 13.9592 15.5 13.9592C13.0838 13.9592 11.125 12.0004 11.125 9.58422C11.125 7.16793 13.0838 5.20917 15.5 5.20917ZM15.5 7.39667C14.2919 7.39667 13.3125 8.37604 13.3125 9.58422C13.3125 10.7923 14.2919 11.7717 15.5 11.7717C16.7081 11.7717 17.6875 10.7923 17.6875 9.58422C17.6875 8.37604 16.7081 7.39667 15.5 7.39667ZM9.02301 0.833496L0.916138 8.94183V5.84725L5.92989 0.833496H9.02301ZM28.7076 0.833496L21.2009 8.34238C21.0306 7.55673 20.7019 6.83008 20.2501 6.19748L25.613 0.833496H28.7076ZM22.148 0.833496L18.4384 4.54389C17.7404 4.13608 16.9502 3.86912 16.1064 3.78197L19.0534 0.833496H22.148Z"
                fill={text}
            />
        </Svg>
    );
};

export default PlainBackground;
