import { CloseOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useAlert } from "react-alert";
import { Modal, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useUserContext } from "../contexts/userContext";
import { useColorsContext, useStyles } from "../utils/styles";
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const hostname = process.env.HOSTNAME

const stripePromise = loadStripe('pk_test_51PLWE7P5dtDg92MqH8V8CK7IureECRnLGStHiqja691B9iW2SHuk8U7uqDsjZVFerY0kjfsAzIxF5dv1G92uCKFK005KwJl7bE');

const TutorMethodModalComponenet = ({ modalVisible, setModalVisible }) => {
  // Responsive Breakpoints
  const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
  const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  const isSm = useMediaQuery({ query: "(min-width: 640px)" });

  // Appearance
  const { primary, backgroundVariant, text, textVariant, border, redVariant } = useColorsContext();
  const defaultStyles = useStyles();
  const customAlert = useAlert();

  const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      backgroundColor: backgroundVariant,
      borderRadius: defaultStyles.radius,
      border: `1px solid ${border}`,
      width: isXl ? "50%" : isLg ? "60%" : isMd ? "70%" : isSm ? "80%" : "90%",
      height: "80%",
      padding: 15,
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    modalHeader: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 5,
    },
    closeButton: {
      marginRight: 15,
    },
    modalTitle: {
      fontSize: 18,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    marginTop: {
      marginTop: 15,
    },
    pickerInfoText: {
      fontSize: 14,
      color: textVariant,
      fontFamily: defaultStyles.boldFont,
      marginBottom: 1,
    },
    input: {
      width: '100%',
      padding: 10,
      marginBottom: 20,
      borderColor: 'gray',
      borderWidth: 1,
      borderRadius: 5,
    },
    applyButton: {
      marginTop: 15,
      borderRadius: defaultStyles.buttonRadius,
      height: 40,
      paddingHorizontal: 15,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: primary,
    },
    buttonText: {
      color: "#fff",
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
    },
  });

  const { user, userId, getPaymentMethods } = useUserContext();
  const stripe = useStripe();
  const elements = useElements();

  // States
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    console.log('Handle submit triggered');
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.error('CardElement not found');
      setError('Card details not entered');
      return;
    }

    console.log('Creating payment method...');
    const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { name },
    });

    if (paymentMethodError) {
      console.error('Error creating payment method:', paymentMethodError.message);
      setError(paymentMethodError.message);
      return;
    }

    console.log('Payment method created:', paymentMethod.id);

    console.log('Calling backend to attach PaymentMethod...');
    const attachResponse = await fetch(`${hostname}/api/${userId}/tutor-payment-method`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.accessToken,
      },
      body: JSON.stringify({
        paymentMethodId: paymentMethod.id
      }),
    });

    if (!attachResponse.ok) {
      console.error('Error attaching PaymentMethod:', attachResponse.statusText);
      setError('Failed to attach PaymentMethod');
      return;
    }

    const attachData = await attachResponse.json();

    if (!attachData.success) {
      console.error('Error attaching PaymentMethod:', attachData.message);
      setError(attachData.message || 'Failed to attach PaymentMethod');
      return;
    }

    // Now call the backend to set this new payment method as the default
    const setDefaultResponse = await fetch(`${hostname}/api/${userId}/set-default-payment-method`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.accessToken,
      },
      body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
    });

    if (!setDefaultResponse.ok) {
      console.error('Error setting default payment method:', setDefaultResponse.statusText);
      setError('Failed to set default payment method');
      return;
    }

    const setDefaultData = await setDefaultResponse.json();

    if (!setDefaultData.success) {
      console.error('Error setting default payment method:', setDefaultData.message);
      setError(setDefaultData.message || 'Failed to set default payment method');
      return;
    }

    console.log('Default payment method set successfully');
    getPaymentMethods(); // Refresh payment methods
    setModalVisible(false);
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => setModalVisible(!modalVisible)}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View style={styles.modalHeader}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setModalVisible(!modalVisible)}
            >
              <CloseOutlined style={{ fontSize: 22, color: text }} />
            </TouchableOpacity>
            <Text style={styles.modalTitle}>Add a payment method</Text>
          </View>

          <ScrollView showsVerticalScrollIndicator={false}>
            {/* Form */}
            <View style={styles.marginTop}>
              <Text style={styles.pickerInfoText}>Name</Text>
              <TextInput
                style={styles.input}
                onChangeText={setName}
                value={name}
              />
            </View>

            <View style={styles.marginTop}>
              <Text style={styles.pickerInfoText}>
                Card Number
              </Text>
              <View style={styles.input}>
                <CardElement />
              </View>
            </View>

            <View style={styles.marginTop}>
              <Text style={styles.pickerInfoText}>Amount</Text>
              <TextInput
                style={styles.input}
                onChangeText={setAmount}
                value={amount}
                keyboardType="numeric"
              />
            </View>

            {error && (
              <Text
                style={{
                  color: redVariant,
                  fontSize: 16,
                  fontFamily: defaultStyles.boldFont,
                  marginBottom: 30,
                }}
              >
                {error}
              </Text>
            )}
            <TouchableOpacity
              style={[
                styles.applyButton,
                {
                  opacity: submit ? 0.5 : 1,
                },
              ]}
              disabled={submit}
              onPress={handleSubmit}
            >
              <Text style={styles.buttonText}>
                {submit ? "..." : "Submit"}
              </Text>
            </TouchableOpacity>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

const TutorMethodModal = ({ modalVisible, setModalVisible }) => (
  <Elements stripe={stripePromise}>
    <TutorMethodModalComponenet modalVisible={modalVisible} setModalVisible={setModalVisible} />
  </Elements>
);

export default TutorMethodModal;
