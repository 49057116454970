import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import {
    Badge,
    Box,
    Divider,
    IconButton,
    Popover,
    Typography,
    useTheme,
} from '@mui/material'
import NotificationsIcon from '@icons/NotificationsIcon'
import { MultiSwitchToggle } from '@components/MultiSwitchToggle'
import { useNotificationsContext } from '@contexts/notificationsContext'
import { AntDesign } from '@expo/vector-icons'
import { useColorsContext, useStyles } from '@utils/styles'
import PendingNotifications from '@components/Notifications/PendingNotifications'
import UnreadMailIcon from '@icons/unreadMailIcon'
import UnreadNotifications from '@components/Notifications/UnreadNotifications'
import { TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'

const NotificationsMenu = () => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const {
        unreadNotifications,
        pendingNotifications,
        markAllAsRead,
        badgeCount,
    } = useNotificationsContext()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const theme = useTheme()
    const defaultStyles = useStyles()
    const { text } = useColorsContext()
    const navigation = useNavigation()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popper' : undefined

    useEffect(() => {
        if (pendingNotifications.length) {
            setSelectedIndex(0)
        } else if (unreadNotifications.length) {
            setSelectedIndex(1)
        } else setSelectedIndex(null)
    }, [pendingNotifications, unreadNotifications])

    const canMarkAllAsRead =
        (selectedIndex === 0 &&
            pendingNotifications.some((notification) => {
                return !notification.read
            })) ||
        (selectedIndex === 1 && unreadNotifications.length)

    const handleNavigateToNotifications = () => {
        handleClose()
        navigation.navigate('Notifications')
    }

    return (
        <>
            <Badge badgeContent={badgeCount} color="primary" overlap="circular">
                <IconButton onClick={handleClick}>
                    <NotificationsIcon size={24} />
                </IconButton>
            </Badge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiPopover-paper': {
                        display: 'flex',
                        maxHeight: '70%',
                        width: { md: 400 },
                    },
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                        }}
                    >
                        <Box sx={{ flexShrink: 0 }}>
                            <Typography
                                variant="subtitle1"
                                fontFamily={defaultStyles.titleFont}
                                textAlign="center"
                            >
                                Notifications
                            </Typography>
                            <IconButton
                                onClick={handleClose}
                                sx={{ position: 'absolute', top: 4, right: 4 }}
                            >
                                <AntDesign
                                    name="close"
                                    size={24}
                                    color={theme.palette.grey[500]}
                                />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                                mt: 2,
                            }}
                        >
                            <MultiSwitchToggle
                                options={[
                                    {
                                        label: `${pendingNotifications.length ? `(${pendingNotifications.length})` : ''}Pending`,
                                        value: 'pending',
                                        disabled: !pendingNotifications.length,
                                    },
                                    {
                                        label: `${unreadNotifications.length ? `(${unreadNotifications.length})` : ''}Unread`,
                                        value: 'unread',
                                        disabled: !unreadNotifications.length,
                                    },
                                ]}
                                highlightColor={theme.palette.primary.main}
                                highlightTextColor={
                                    theme.palette.primary.contrastText
                                }
                                selectedIndex={selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    right: '0',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    fontFamily: 'Poppins_500Medium',
                                    color: text,
                                    cursor: 'pointer',
                                }}
                                onClick={handleNavigateToNotifications}
                            >
                                See all
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                overflowY: 'auto',
                                flex: 1,
                                pr: 1,
                            }}
                        >
                            {selectedIndex === 0 && (
                                <PendingNotifications
                                    showHeader={false}
                                    dense
                                    handleNavigateToNotifications={
                                        handleNavigateToNotifications
                                    }
                                />
                            )}
                            {selectedIndex === 1 ||
                                (selectedIndex === null && (
                                    <UnreadNotifications
                                        showHeader={false}
                                        dense
                                        handleNavigateToNotifications={
                                            handleNavigateToNotifications
                                        }
                                    />
                                ))}
                        </Box>
                        <Box sx={{ flexShrink: 0 }}>
                            <Divider sx={{ my: 2 }} flexItem />
                            <TouchableOpacity
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    color: canMarkAllAsRead
                                        ? text
                                        : theme.palette.text.disabled,
                                }}
                                onPress={() => {
                                    markAllAsRead()
                                }}
                                disabled={!canMarkAllAsRead}
                            >
                                <UnreadMailIcon
                                    color={
                                        canMarkAllAsRead
                                            ? text
                                            : theme.palette.text.disabled
                                    }
                                />
                                <Typography
                                    variant="body2"
                                    fontFamily={defaultStyles.titleFont}
                                    sx={{ lineHeight: 1, ml: 1 }}
                                    color={
                                        canMarkAllAsRead
                                            ? text
                                            : theme.palette.text.disabled
                                    }
                                >
                                    Mark all as read
                                </Typography>
                            </TouchableOpacity>
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popover>
        </>
    )
}

export default NotificationsMenu
