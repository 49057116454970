// Imports
import { AntDesign, Feather } from "@expo/vector-icons";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Link } from "@react-navigation/native";
import { getAuth } from "firebase/auth";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View, Alert, TextInput, FlatList } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../../utils/styles";
import { ReportModal } from "../ReportModal";
import * as ImagePicker from "expo-image-picker";
import * as DocumentPicker from 'expo-document-picker';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { Menu, MenuOption, MenuOptions, MenuTrigger } from "react-native-popup-menu";

// Firebase
import { useFirebaseContext } from "../../contexts/firebaseContext";
import { useUserContext } from "../../contexts/userContext";
import ChatInput from "./ChatInput";

const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};


export const Chat = ({ openChat, setOpenChat, navigation }) => {
  console.log("Chat component loaded with:", openChat);

  // Appearance
  const { text, background, primaryVariant, textVariant, backgroundVariant, border } = useColorsContext();
  const defaultStyles = useStyles();

  // Firebase
  const { db } = useFirebaseContext();
  const auth = getAuth();
  const { user, name, profile } = useUserContext();

  // State for messages and message input
  const [messages, setMessages] = useState([]);
  const [image, setImage] = useState(
    "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e"
  );
  const [reportModal, setReportModal] = useState(false);
  const [tutorURL, setTutorURL] = useState(null);

  // Styles
  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      height: 600,
    },
    messageContainer: {
      padding: 10,
      borderRadius: 10,
      marginVertical: 5,
      maxWidth: '80%',
    },
    messageTextContainer: {
      fontFamily: defaultStyles.regularFont,
    },
    sentMessageContainer: {
      backgroundColor: primaryVariant,
      alignSelf: 'flex-end',
    },
    sentMessageTextContainer: {
      color: "#fff",
    },
    receivedMessageContainer: {
      backgroundColor: '#f0f0f0',
      alignSelf: 'flex-start',
    },
    receivedMessageTextContainer: {
      color: '#000',
    },
    image: {
      width: 200,
      height: 200,
      borderRadius: 10,
    },
    mainSection: {
      flex: 1,
    },
    menuText: {
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.regularFont,
    },
    title: {
      fontSize: 22,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    tutorInfo: {
      fontSize: 14,
      color: textVariant,
      fontFamily: defaultStyles.boldFont,
    },
  });

  useEffect(() => {
    if (openChat) {
      const q = query(
        collection(db, "messages"),
        orderBy("createdAt", "asc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const allMessages = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        const filteredMessages = allMessages.filter(message => message.chat === openChat.chat.id);
        setMessages(filteredMessages);
      });
      return unsubscribe;
    }
  }, [openChat]);

  const makeid = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const onSend = async (newMessage, imageUrl = null, pdfUrl = null) => {
    console.log("onSend called with:", { newMessage, imageUrl, pdfUrl });

    if (newMessage.trim().length === 0 && !imageUrl && !pdfUrl) {
      console.log("Message, image, and PDF URL are empty. Aborting send.");
      return;
    }

    const messageData = {
      text: newMessage,
      image: imageUrl,
      pdf: pdfUrl,
      createdAt: new Date(),
      user: {
        _id: user.uid,
        name: name,
        avatar: profile
          ? profile
          : "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
      },
    };

    console.log("Adding message to Firestore:", messageData);
    await addDoc(collection(db, "messages"), {
      chat: openChat.chat.id,
      text: messageData.text,
      image: messageData.image,
      pdf: messageData.pdf,
      createdAt: serverTimestamp(),
      read: false,
      user: messageData.user,
    });

    const senderName = messageData.user.name;
    console.log(`Sender name: ${senderName}`);

    console.log("Updating chat with last message:");
    await updateDoc(doc(db, "chats", openChat.chat.id), {
      lastUpdated: serverTimestamp(),
      lastMessage: {
        text: imageUrl ? `${senderName} sent an image` : pdfUrl ? `${senderName} sent a PDF` : messageData.text,
        createdAt: serverTimestamp(),
        user: messageData.user,
      },
    });
  };

  const uploadFile = async (uri, fileType) => {
    if (!uri) {
      console.error(`${fileType} URI is undefined or null.`);
      return;
    }

    const storage = getStorage();
    console.log(`Starting ${fileType} upload for URI: ${uri}`);

    try {
      let fileBlob;
      if (uri.startsWith('data:')) {
        // Convert base64 to Blob
        const base64String = uri.split(',')[1];
        fileBlob = base64ToBlob(base64String, fileType === 'image' ? 'image/jpeg' : 'application/pdf');
      } else {
        // Fetch the file as Blob
        let file = await fetch(uri);
        if (!file.ok) {
          throw new Error(`Failed to fetch the ${fileType} file from URI: ${uri}`);
        }
        fileBlob = await file.blob();
      }

      console.log(`Blob size: ${fileBlob.size}`);
      let name = makeid(12);
      const storageRef = ref(storage, `${fileType}s/${name}`);
      console.log(`Uploading file: ${name}`);

      return new Promise((resolve, reject) => {
        let task = uploadBytesResumable(storageRef, fileBlob, {
          contentType: fileType === 'image' ? "image/jpeg" : "application/pdf",
        });

        task.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            console.error(`Error during ${fileType} upload:`, error);
            Alert.alert(`Error uploading ${fileType}!`, error.message, [
              {
                text: "OK",
                onPress: () => console.log("OK Pressed"),
              },
            ]);
            reject(error);
          },
          () => {
            getDownloadURL(task.snapshot.ref).then((downloadURL) => {
              console.log(`File uploaded successfully: ${downloadURL}`);
              resolve(downloadURL);
            });
          }
        );
      });
    } catch (error) {
      console.error(`Upload ${fileType} Error:`, error);
    }
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log("Image picker result:", result);

    if (!result.canceled && result.uri) {
      const imageUrl = await uploadFile(result.uri, 'image');
      console.log("Image URL:", imageUrl);
      onSend('', imageUrl);
    }
  };

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: 'application/pdf',
    });

    console.log("Document picker result:", result);

    if (result.type === 'success' && result.assets && result.assets.length > 0) {
      const pdfUrl = await uploadFile(result.assets[0].uri, 'pdf');
      console.log("PDF URL:", pdfUrl);
      onSend('', null, pdfUrl);
    }
  };

  const renderItem = ({ item }) => (
    <View style={[
      styles.messageContainer,
      item.user._id === user.uid ? styles.sentMessageContainer : styles.receivedMessageContainer
    ]}>
      {item.text ? (
        <Text style={[
          styles.messageTextContainer,
          item.user._id === user.uid ? styles.sentMessageTextContainer : styles.receivedMessageTextContainer
        ]}>
          {item.text}
        </Text>
      ) : item.image ? (
        <Image source={{ uri: item.image }} style={styles.image} />
      ) : item.pdf ? (
        <TouchableOpacity onPress={() => Alert.alert('Download', 'Do you want to download this PDF?', [
          { text: 'Cancel', style: 'cancel' },
          { text: 'Download', onPress: () => window.open(item.pdf, '_blank') }
        ])}>
          <Text style={styles.menuText}>Download PDF</Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );

  if (!user) {
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.maxWidth}>
          <View
            style={[
              styles.mainSection,
              {
                alignItems: "center",
                justifyContent: "center",
              },
            ]}
          >
            <Text style={styles.title}>404 Tutor not found</Text>
          </View>
        </View>
      </SafeAreaView>
    );
  }

  // Determine the name to display in the header
  const otherPerson = openChat.chat.user.uid === user.uid ? openChat.chat.tutor : openChat.chat.user;
  const otherPersonName = otherPerson.name.split(" ").length > 1
    ? `${otherPerson.name.split(" ")[0]} ${otherPerson.name.split(" ")[1][0]}.`
    : otherPerson.name;

  // Return chat screen
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.mainSection}>
        {/* Header */}
        <View
          style={{
            paddingVertical: 15,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                setOpenChat(null);
              }}
            >
              <NavigateBeforeIcon
                style={{
                  marginRight: 30,
                  marginLeft: -12,
                  fontSize: 32,
                  color: text,
                }}
              />
            </TouchableOpacity>

            <Image
              source={{ uri: image }}
              style={{
                width: 60,
                height: 60,
                borderRadius: defaultStyles.buttonRadius,
                marginRight: 15,
              }}
            />

            <View>
              <Text style={styles.title}>
                {otherPersonName}
              </Text>

              <Text style={styles.tutorInfo}>
                {/* Add any additional info you need here */}
              </Text>
            </View>
          </View>

          {/* Report icon in the top right */}
          {user && (
            <View
              style={{
                marginLeft: 15,
                display: "flex",
              }}
            >
              <Menu>
                <MenuTrigger
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <View
                    style={{
                      zIndex: 999,
                      padding: 15,
                    }}
                  >
                    <Feather
                      name="more-vertical"
                      size={24}
                      color={textVariant}
                    />
                  </View>
                </MenuTrigger>

                <MenuOptions
                  optionsContainerStyle={{
                    backgroundColor: backgroundVariant,
                    borderRadius: defaultStyles.radius,
                    position: "absolute",
                    paddingVertical: 10,
                    paddingHorizontal: 10,
                    position: "absolute",
                    top: 50,
                    right: -20,
                    zIndex: 999,
                    shadowColor: "rgba(0, 0, 0, 0.1)",
                    shadowOpacity: 0.8,
                    shadowOffset: { width: 2, height: 2 },
                    shadowRadius: 4,
                  }}
                  customStyles={{
                    optionText: {
                      fontSize: 16,
                      fontFamily: defaultStyles.regularFont,
                    },
                  }}
                >
                  <MenuOption
                    onSelect={() => {
                      setReportModal(true);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 5,
                      }}
                    >
                      <Text style={styles.menuText}>
                        Report User
                      </Text>

                      <Feather
                        style={{ marginRight: 10 }}
                        name="alert-circle"
                        size={20}
                        color={text}
                      />
                    </View>
                  </MenuOption>
                </MenuOptions>
              </Menu>
            </View>
          )}
        </View>

        <FlatList
          data={messages.reverse()}
          renderItem={renderItem}
          keyExtractor={item => item.id}
          style={{ padding: 15 }}
          inverted={true}
          contentContainerStyle={{
            flexGrow: 1, justifyContent: 'flex-end',
          }}
        />

        <ChatInput onSend={onSend} pickDocument={pickDocument} pickImage={pickImage} />

        {/* REPORT MODAL */}
        <ReportModal
          modalVisible={reportModal}
          setModalVisible={setReportModal}
          tutor={openChat.chat.tutor}
          chat={openChat.chat}
          navigation={navigation}
          isChatScreen={true}
          studentName={openChat.chat.user.name}
          studentID={openChat.chat.user.uid}
          studentEmail={openChat.chat.user.email}
        />
      </View>
    </SafeAreaView>
  );
};
