import { Box, Button } from '@mui/material'
import { useColorsContext } from '@utils/styles'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

interface OnboardingFooterProps {
    step: number
    onNext?: () => void
    onPrev?: () => void
    loading?: boolean
}

const OnboardingFooter: React.FC<OnboardingFooterProps> = ({
    step,
    onNext,
    onPrev,
    loading,
}) => {
    const { primary, disabled } = useColorsContext()
    return (
        <>
            {step > 0 && step !== 7 && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            columnGap: 1,
                            pb: 1,
                            pt: 1,
                        }}
                    >
                        {[0, 1, 2, 3, 4, 5].map((i) => (
                            <Box
                                sx={{
                                    width: 64,
                                    height: 8,
                                    borderRadius: '2px',
                                    marginBottom: 2,
                                    boxShadow:
                                        step > i
                                            ? `0px 0px 4px 0px ${primary}`
                                            : 'none',
                                    backgroundColor:
                                        step > i ? primary : disabled,
                                }}
                            ></Box>
                        ))}
                    </Box>
                </>
            )}
            {step > 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        columnGap: 2,
                    }}
                >
                    {step > 1 && (
                        <Button
                            variant="outlined"
                            disabled={loading}
                            onClick={onPrev}
                        >
                            Back
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        disabled={loading}
                        onClick={onNext}
                    >
                        {step === 7 ? 'Finish up' : 'Save & Continue'}
                    </Button>
                </Box>
            )}
        </>
    )
}

export default OnboardingFooter
