import React from 'react';
import Svg, { Path, Circle } from 'react-native-svg';
import { useColorsContext } from '@utils/styles';

const NaturalExpressionIcon = ({ size = 37 }) => {
    const { text } = useColorsContext();

    return (
        <Svg width={size} height={size * (36 / 37)} viewBox="0 0 37 36" fill="none">
            <Path d="M24.0282 29.5234V32.1918C24.0282 33.413 22.9572 34.403 21.6361 34.403H3.39203C2.07097 34.403 1 33.413 1 32.1918V14.4436C1 13.2223 2.07097 12.2324 3.39203 12.2324H7.03438" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M10.354 12.2324H21.6359C22.957 12.2324 24.028 13.2224 24.028 14.4436V26.8417" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M28.1535 29.342H9.90937C8.58823 29.342 7.51733 28.352 7.51733 27.1309V9.38256C7.51733 8.16131 8.5883 7.17139 9.90937 7.17139H28.1535C29.4746 7.17139 30.5455 8.16138 30.5455 9.38256V27.1309C30.5455 28.3521 29.4745 29.342 28.1535 29.342Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M7.5172 25.563C6.13799 26.1701 5.16455 27.6463 5.16455 29.3709V31.9693" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M13.1938 23.5033H11.8646C11.2273 23.5033 10.6578 23.1355 10.4386 22.5824L10.0922 21.7084C9.8111 20.9992 9.66724 20.2498 9.66724 19.4944V18.4058C9.66724 16.9447 10.9485 15.7603 12.5292 15.7603C14.1098 15.7603 15.3911 16.9447 15.3911 18.4058V19.4944C15.3911 20.2498 15.2472 20.9992 14.9661 21.7084L14.6197 22.5824C14.4005 23.1355 13.8311 23.5033 13.1938 23.5033Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M11.1556 23.5527C11.1556 24.3173 10.6142 24.965 9.86646 25.186" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M18.6242 26.931C17.9402 25.9216 16.8425 25.2672 15.6048 25.2672C14.5806 25.2672 13.7502 24.4996 13.7502 23.5527" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M19.3596 31.969V29.3706" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Circle cx="25.6418" cy="3.70168" r="0.88283" fill={text} />
            <Path d="M32.8103 29.501V30.1976" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M32.8103 33.1489V33.8455" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M30.4604 31.6738H31.2141" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M34.4067 31.6738H35.1604" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M33.6499 1V1.69666" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M33.6499 4.64795V5.34454" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M31.3 3.17188H32.0537" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M35.2463 3.17188H36" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M3.35181 2.48193V3.17859" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M3.35181 6.12988V6.82647" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M1.00195 4.6543H1.75559" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M4.94824 4.6543H5.70188" stroke={text} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    );
};

export default NaturalExpressionIcon;
