import { useColorsContext } from '@utils/styles';
import React from 'react';

const SearchIcon = ({
    size,
    color,
}) => {
    const { text } = useColorsContext();
    return (
        <svg width={size} height={size} viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 55C43.8071 55 55 43.8071 55 30C55 16.1929 43.8071 5 30 5C16.1929 5 5 16.1929 5 30C5 43.8071 16.1929 55 30 55Z" stroke="black" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M61.25 61.25L47.6562 47.6562" stroke={color || text} strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )

}

export default SearchIcon;