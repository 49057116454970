const { languages } = require("./languages");


const subjectData = {
  "Accounting": {
    specialities: ["Financial Accounting", "Managerial Accounting", "Auditing", "Taxation"],
    icon: require("@assets/images/subjects/Accounting.svg")
  },
  "Actuarial Science": {
    specialities: ["Life Insurance", "Property and Casualty Insurance", "Pension Plans", "Risk Management"],
    icon: require("@assets/images/subjects/Actuarial-Science.svg")
  },
  "Agriculture": {
    specialities: ["Crop Science", "Animal Science", "Soil Science", "Agricultural Economics"],
    icon: require("@assets/images/subjects/Agriculture.svg")
  },
  "Anthropology": {
    specialities: ["Cultural Anthropology", "Biological Anthropology", "Linguistic Anthropology", "Archaeology"],
    icon: require("@assets/images/subjects/Anthropology.svg")
  },
  "Architecture": {
    specialities: ["Architectural Design", "Urban Planning", "Landscape Architecture", "Interior Design"],
    icon: require("@assets/images/subjects/Architecture.svg")
  },
  "Art": {
    specialities: ["Drawing", "Painting", "Sculpture", "Ceramics", "Printmaking", "Graphic Design", "Digital Art", "Animation", "Photography"],
    icon: require("@assets/images/subjects/Art.svg")
  },
  "Biology": {
    specialities: ["Molecular Biology", "Genetics", "Ecology", "Evolutionary Biology"],
    icon: require("@assets/images/subjects/Biology.svg")
  },
  "Chemistry": {
    specialities: ["Organic Chemistry", "Inorganic Chemistry", "Physical Chemistry", "Analytical Chemistry"],
    icon: require("@assets/images/subjects/Chemistry.svg")
  },
  "Commerce": {
    specialities: ["Business Management", "Finance", "Marketing", "International Trade"],
    icon: require("@assets/images/subjects/Commerce.svg")
  },
  "Computer Science": {
    specialities: ["Programming", "Algorithms", "Data Structures", "Computer Networks", "Web Development", "Software Engineering", "Python", "Java", "C++", "C#", "JavaScript"],
    icon: require("@assets/images/subjects/Computer-Science.svg")
  },
  "Earth Science": {
    specialities: ["Geology", "Meteorology", "Oceanography", "Environmental Science"],
    icon: require("@assets/images/subjects/Earth-Science.svg")
  },
  "Economics": {
    specialities: ["Microeconomics", "Macroeconomics", "International Economics", "Financial Economics"],
    icon: require("@assets/images/subjects/Economics.svg")
  },
  "Education": {
    specialities: ["Early Childhood Education", "Special Education", "Curriculum Development", "Educational Psychology"],
    icon: require("@assets/images/subjects/Education.svg")
  },
  "Engineering": {
    specialities: ["Mechanical Engineering", "Electrical Engineering", "Civil Engineering", "Chemical Engineering"],
    icon: require("@assets/images/subjects/Engineering.svg")
  },
  "English ": {
    specialities: ["Reading", "Writing", "Grammar", "Literature", "Creative Writing", "Poetry", "Journalism", "Speech and Debate"],
    icon: require("@assets/images/subjects/English-Literature.svg")
  },
  "Geography": {
    specialities: ["Physical Geography", "Human Geography", "Cartography", "Geographic Information Systems"],
    icon: require("@assets/images/subjects/Geography.svg")
  },
  "History": {
    specialities: ["Ancient History", "Medieval History", "Modern History", "World History"],
    icon: require("@assets/images/subjects/History.svg")
  },
  "Kinesiology": {
    specialities: ["Exercise Physiology", "Biomechanics", "Motor Control and Learning", "Sports Psychology"],
    icon: require("@assets/images/subjects/Kinesiology.svg")
  },
  "Language": {
    specialities: languages,
    icon: require("@assets/images/subjects/Language.svg")
  },
  "Law": {
    specialities: ["Constitutional Law", "Criminal Law", "Civil Law", "International Law"],
    icon: require("@assets/images/subjects/Law.svg")
  },
  "Marketing": {
    specialities: ["Digital Marketing", "Brand Management", "Market Research", "Advertising"],
    icon: require("@assets/images/subjects/Marketing.svg")
  },
  "Mathematics": {
    specialities: ["Algebra", "Geometry", "Calculus", "Statistics", "Probability", "Trigonometry"],
    icon: require("@assets/images/subjects/Mathematics.svg")
  },
  "Medicine": {
    specialities: ["Anatomy", "Physiology", "Pathology", "Pharmacology"],
    icon: require("@assets/images/subjects/Medicine.svg")
  },
  "Music": {
    specialities: ["Music Theory", "Music History", "Music Composition", "Music Performance"],
    icon: require("@assets/images/subjects/Music.svg")
  },
  "Philosophy": {
    specialities: ["Ethics", "Epistemology", "Metaphysics", "Logic", "Political philosophy"],
    icon: require("@assets/images/subjects/Philosophy.svg")
  },
  "Physical Education": {
    specialities: ["Fitness", "Sports", "Team Games", "Individual Sports", "Dance", "Yoga", "Pilates"],
    icon: require("@assets/images/subjects/Physical-Education.svg")
  },
  "Physics": {
    specialities: ["Classical Mechanics", "Quantum Mechanics", "Electromagnetism", "Thermodynamics"],
    icon: require("@assets/images/subjects/Physics.svg")
  },
  "Political Science": {
    specialities: ["Comparative Politics", "International Relations", "Political Theory", "Public Policy"],
    icon: require("@assets/images/subjects/Political-Science.svg")
  },
  "Psychology": {
    specialities: ["Developmental Psychology", "Abnormal Psychology", "Social Psychology", "Cognitive Psychology"],
    icon: require("@assets/images/subjects/Psychology.svg")
  },
  "Real Estate": {
    specialities: ["Property Management", "Real Estate Finance", "Real Estate Law", "Real Estate Appraisal"],
    icon: require("@assets/images/subjects/Real-Estate.svg")
  },
  "Religious Studies": {
    specialities: ["Comparative Religion", "Religious History", "Theology", "Philosophy of Religion"],
    icon: require("@assets/images/subjects/Religous-studies.svg")
  },
  "Sociology": {
    specialities: ["Social Stratification", "Criminology", "Race and Ethnicity", "Gender Studies", "Social Movements", "Culture and Society"],
    icon: require("@assets/images/subjects/Sociology.svg")
  }
};


const subjectIcons = {}
Object.keys(subjectData).forEach((subject) => {
  subjectIcons[subject] = subjectData[subject].icon
})
const specialtyIcons = {}
Object.keys(subjectData).forEach((subject) => {
  subjectData[subject].specialities.forEach((specialty) => {
    specialtyIcons[specialty] = subjectData[subject].icon
  })
})
const subjects = Object.keys(subjectData)
const specialties = {}
Object.keys(subjectData).forEach((subject) => {
  specialties[subject] = subjectData[subject].specialities
})


module.exports = {
  subjects,
  specialties,
  subjectIcons,
  specialtyIcons,
  subjectData,
}