import React, { useState } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity } from 'react-native';
import { useColorsContext, useStyles } from '../../utils/styles';

const ChatInput = ({ onSend, pickImage, pickDocument }) => {
    const [message, setMessage] = useState('');

    const { text, primaryVariant } = useColorsContext();
    const defaultStyles = useStyles();


    return (
        <View style={styles.inputContainer}>
            <TextInput
                style={styles.input(text, defaultStyles)}
                value={message}
                onChangeText={setMessage}
                placeholder="Type a message..."
                placeholderTextColor={text}
            />
            <TouchableOpacity style={styles.sendButton(primaryVariant)} onPress={() => onSend(message).then(() => setMessage(''))}>
                <Text style={styles.sendButtonText(defaultStyles)}>Send</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.sendButton(primaryVariant)} onPress={pickImage}>
                <Text style={styles.sendButtonText(defaultStyles)}>Photo</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.sendButton(primaryVariant)} onPress={pickDocument}>
                <Text style={styles.sendButtonText(defaultStyles)}>PDF</Text>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    inputContainer: {
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        marginTop: 15,
    },
    input: (text, defaultStyles) => {
        return {
            flex: 1,
            borderColor: text,
            borderWidth: 1,
            borderRadius: 10,
            padding: 10,
            color: text,
            fontFamily: defaultStyles.regularFont,
        }
    },
    sendButton: (primaryVariant) => {
        return {
            backgroundColor: primaryVariant,
            padding: 10,
            borderRadius: 10,
            marginLeft: 10,
        }

    },
    sendButtonText: (defaultStyles) => {
        return {
            color: "#fff",
            fontFamily: defaultStyles.boldFont,
        }

    },
});

export default ChatInput;
