import { AntDesign, Feather } from "@expo/vector-icons";
import { Link } from "@react-navigation/native";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import Footer from "../../../components/Footer";
import { LoadingPage } from "../../../components/LoadingPage";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useNotificationsContext } from "../../../contexts/notificationsContext";
import { VerifyModal } from "../../../components/VerifyModal";
import { StripeAccountModal } from "../../../components/StripeAccountModal";

export const Notifications = () => {
  const {
    user,
    notificationPreferences,
    toggleNotificationPreferences,
  } = useUserContext();

  if (!notificationPreferences) {
    return <LoadingPage />;
  }

  const customAlert = useAlert();

  const { db } = useFirebaseContext();

  const [loading, setLoading] = useState(true);
  const [verifyModal, setVerifyModal] = useState(false);
  const [stripeAccountModal, setStripeAccountModal] = useState(false);
  const [view, setView] = useState("account");

  const {
    primary,
    background,
    text,
    textVariant,
    border,
  } = useColorsContext();
  const defaultStyles = useStyles();

  const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  const isSm = useMediaQuery({ query: "(min-width: 640px)" });

  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      flex: 1,
    },
    maxWidth: {
      flex: 1,
      backgroundColor: background,
      display: "flex",
      flexDirection: Platform.OS === "web" ? "row" : null,
      paddingRight: 0,
      width: "100%",
      margin: Platform.OS === "web" ? "auto" : null,
    },
    mainSection: {
      flex: 1,
      padding: isSm ? 45 : 15,
      paddingTop: 30,
    },
    title: {
      fontSize: 36,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    subtitle: {
      fontSize: 16,
      fontFamily: defaultStyles.boldFont,
      color: text,
    },
    description: {
      fontSize: 16,
      fontFamily: defaultStyles.regularFont,
      color: textVariant,
    },
    notificationItem: {
      padding: 10,
      borderBottomWidth: 1,
      borderBottomColor: border,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    notificationText: {
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.regularFont,
    },
    infoTitle: {
      textAlign: "left",
      fontSize: 22,
      fontFamily: defaultStyles.boldFont,
      color: text,
    },
    infoSubtitle: {
      fontSize: 19,
      fontFamily: defaultStyles.boldFont,
      color: textVariant,
    },
    infoDescription: {
      fontSize: 16,
      fontFamily: defaultStyles.regularFont,
      color: textVariant,
      marginTop: 5,
      display: "flex",
      flex: 1,
    },
    infoLink: {
      fontSize: 14,
      fontFamily: defaultStyles.regularFont,
      color: primary,
      marginTop: 10,
    },
  });

  useEffect(() => {
    if (user) {
      const fetchNotifications = async () => {
        const notificationsQuery = query(
          collection(db, "notifications"),
          where("userId", "==", user.uid)
        );
        const querySnapshot = await getDocs(notificationsQuery);
        const fetchedNotifications = querySnapshot.docs.map((doc) =>
          doc.data()
        );
        console.log("Fetched notifications:", fetchedNotifications);
      };

      fetchNotifications();
      setLoading(false);
    }
  }, [user, db]);



  return (
    <SafeAreaView style={styles.container}>

      {loading ? (
        <LoadingPage />
      ) : (
        <ScrollView showsVerticalScrollIndicator={false} style={{ backgroundColor: background }}>
          <View style={styles.maxWidth}>
            <View style={[styles.mainSection]}>
              <Text style={{ color: text, fontSize: 16, fontFamily: defaultStyles.regularFont, marginBottom: 5 }}>
                <Link to={"/settings"}>
                  <Text>Settings</Text>
                </Link>{" "}
                <Text style={{ fontFamily: defaultStyles.boldFont, color: primary }}>
                  <Feather name="chevron-right" size={15} color={text} /> Notifications
                </Text>
              </Text>

              <Text style={styles.title}>Notifications</Text>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={{ color: text, fontSize: 16, fontFamily: defaultStyles.regularFont }}>
                  Update the notification preferences for your account.
                </Text>
              </View>

              <View style={{ display: "flex", flexDirection: isLg ? "row" : "column", marginTop: 20, marginBottom: 20, gap: 30, borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, flexDirection: isMd ? "row" : "column", alignItems: isMd ? "center" : "flex-start" }}>
                <TouchableOpacity onPress={() => setView("account")}>
                  <Text style={[styles.subtitle, { color: view === "account" ? primary : textVariant, fontFamily: view === "account" ? defaultStyles.boldFont : defaultStyles.regularFont }]}>
                    Account activity
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => setView("sessions")}>
                  <Text style={[styles.subtitle, { color: view === "sessions" ? primary : textVariant, fontFamily: view === "sessions" ? defaultStyles.boldFont : defaultStyles.regularFont }]}>
                    Sessions
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => setView("messages")}>
                  <Text style={[styles.subtitle, { color: view === "messages" ? primary : textVariant, fontFamily: view === "messages" ? defaultStyles.boldFont : defaultStyles.regularFont }]}>
                    Messages
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => setView("offers")}>
                  <Text style={[styles.subtitle, { color: view === "offers" ? primary : textVariant, fontFamily: view === "offers" ? defaultStyles.boldFont : defaultStyles.regularFont }]}>
                    Offers and updates
                  </Text>
                </TouchableOpacity>
              </View>

              <View style={{ display: "flex", flexDirection: isLg ? "row" : "column", gap: 30 }}>
                <View style={{ display: "flex", flexDirection: "column", flex: 1, paddingRight: 30 }}>
                  {view === "account" && (
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, flexDirection: isMd ? "row" : "column", justifyContent: "space-between", alignItems: isMd ? "center" : "flex-start" }}>
                        <View>
                          <Text style={styles.subtitle}>Email</Text>
                          <Text style={styles.description}>Receive an email when there's an update to your account.</Text>
                        </View>
                        <Switch style={{ marginTop: !isMd && 15 }} trackColor={{ false: "#767577", true: primary }} thumbColor={primary} ios_backgroundColor={primary} value={notificationPreferences?.accountUpdates} onValueChange={() => {
                          toggleNotificationPreferences("accountUpdates");
                          customAlert.success("[Account] Account change preferences updated!");
                        }} />
                      </View>
                    </View>
                  )}

                  {view === "account" && (
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, flexDirection: isMd ? "row" : "column", justifyContent: "space-between", alignItems: isMd ? "center" : "flex-start" }}>
                        <View>
                          <Text style={styles.subtitle}>Text</Text>
                          <Text style={styles.description}>Receive a text when there's an update to your account.</Text>
                        </View>
                        <Switch style={{ marginTop: !isMd && 15 }} trackColor={{ false: "#767577", true: primary }} thumbColor={primary} ios_backgroundColor={primary} disabled={true} value={false} />
                      </View>
                    </View>
                  )}

                  {view === "sessions" && (
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, flexDirection: isMd ? "row" : "column", justifyContent: "space-between", alignItems: isMd ? "center" : "flex-start" }}>
                        <View>
                          <Text style={styles.subtitle}>Email</Text>
                          <Text style={styles.description}>Receive an email when you receive a session request</Text>
                        </View>
                        <Switch style={{ marginTop: !isMd && 15 }} trackColor={{ false: "#767577", true: primary }} thumbColor={primary} ios_backgroundColor={primary} value={notificationPreferences.bookingRequest} onValueChange={() => {
                          toggleNotificationPreferences("bookingRequest");
                          customAlert.success("[Account] Booking request preferences updated!");
                        }} />
                      </View>
                    </View>
                  )}

                  {view === "sessions" && (
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, flexDirection: isMd ? "row" : "column", justifyContent: "space-between", alignItems: isMd ? "center" : "flex-start" }}>
                        <View>
                          <Text style={styles.subtitle}>Text</Text>
                          <Text style={styles.description}>Receive a text when you receive a session request.</Text>
                        </View>
                        <Switch style={{ marginTop: !isMd && 15 }} trackColor={{ false: "#767577", true: primary }} thumbColor={primary} ios_backgroundColor={primary} disabled={true} value={false} />
                      </View>
                    </View>
                  )}

                  {view === "messages" && (
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, flexDirection: isMd ? "row" : "column", justifyContent: "space-between", alignItems: isMd ? "center" : "flex-start" }}>
                        <View>
                          <Text style={styles.subtitle}>Email</Text>
                          <Text style={styles.description}>Receive an email when you receive a new message.</Text>
                        </View>
                        <Switch style={{ marginTop: !isMd && 15 }} trackColor={{ false: "#767577", true: primary }} thumbColor={primary} ios_backgroundColor={primary} onValueChange={() => {
                          toggleNotificationPreferences("newMessage");
                          customAlert.success("[Account] New message preference updated!");
                        }} value={notificationPreferences.newMessage} />
                      </View>
                    </View>
                  )}

                  {view === "messages" && (
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, flexDirection: isMd ? "row" : "column", justifyContent: "space-between", alignItems: isMd ? "center" : "flex-start" }}>
                        <View>
                          <Text style={styles.subtitle}>Text</Text>
                          <Text style={styles.description}>Receive a text when you receive a new message.</Text>
                        </View>
                        <Switch style={{ marginTop: !isMd && 15 }} trackColor={{ false: "#767577", true: primary }} thumbColor={primary} ios_backgroundColor={primary} disabled={true} value={false} />
                      </View>
                    </View>
                  )}

                  {view === "offers" && (
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, flexDirection: isMd ? "row" : "column", justifyContent: "space-between", alignItems: isMd ? "center" : "flex-start" }}>
                        <View>
                          <Text style={styles.subtitle}>Email</Text>
                          <Text style={styles.description}>Receive an email when there's new offers or updates to the platform.</Text>
                        </View>
                        <Switch style={{ marginTop: !isMd && 15 }} trackColor={{ false: "#767577", true: primary }} thumbColor={primary} ios_backgroundColor={primary} value={notificationPreferences.offersAndUpdates} onValueChange={() => {
                          toggleNotificationPreferences("offersAndUpdates");
                          customAlert.success("[Account] Offers and updates preference updated!");
                        }} />
                      </View>
                    </View>
                  )}

                  {view === "offers" && (
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, flexDirection: isMd ? "row" : "column", justifyContent: "space-between", alignItems: isMd ? "center" : "flex-start" }}>
                        <View>
                          <Text style={styles.subtitle}>Text</Text>
                          <Text style={styles.description}>Receive a text when there's new offers or updates to the platform.</Text>
                        </View>
                        <Switch style={{ marginTop: !isMd && 15 }} trackColor={{ false: "#767577", true: primary }} thumbColor={primary} ios_backgroundColor={primary} disabled={true} value={false} />
                      </View>
                    </View>
                  )}
                </View>
                <View style={{ display: "flex", flexDirection: !isLg ? "row" : "column", width: isLg ? 330 : "100%" }}>
                  <View style={{ border: "1px solid " + border, borderRadius: defaultStyles.radius, padding: 15, width: isLg ? 330 : "100%" }}>
                    <AntDesign name="notification" size={32} color={text} style={{ marginBottom: 10 }} />

                    <Text style={styles.infoSubtitle}>Why turn on notifications?</Text>
                    <Text style={styles.infoDescription}>
                      By enabling notifications, you will receive timely alerts based on your preferred settings whenever you receive new messages, session bookings, and other important updates. To enhance your experience, remember to activate notifications.
                    </Text>

                    <View style={{ width: "100%", height: 1, backgroundColor: border, marginTop: 15, marginBottom: 15 }}></View>

                    <AntDesign name="mail" size={32} color={text} style={{ marginBottom: 10 }} />

                    <Text style={styles.infoSubtitle}>Where do the notifications go?</Text>
                    <Text style={styles.infoDescription}>
                      Notifications will be sent to the email address associated with your account. You can view your account settings in the account settings page.
                    </Text>

                    <Link to={`/settings/account`} style={styles.infoLink}>
                      Account settings
                    </Link>
                  </View>
                  {isMd && <View style={{ flex: 1 }}></View>}
                </View>
              </View>
            </View>
          </View>
          {isSm ? <Footer></Footer> : null}
        </ScrollView>
      )}
      <VerifyModal verifyModal={verifyModal} setVerifyModal={setVerifyModal}></VerifyModal>
      <StripeAccountModal display={stripeAccountModal} setDisplay={setStripeAccountModal}></StripeAccountModal>
    </SafeAreaView>
  );
};