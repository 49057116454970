import React from 'react';
import Svg, { Path } from 'react-native-svg';

const LightBulbIcon = ({ size = 35 }) => {
    return (
        <Svg width={size} height={size} viewBox="0 0 35 35" fill="none">
            <Path d="M21.0547 27.3438C21.0547 22.1485 23.5156 20.7813 23.5156 20.7813C26.9336 17.3633 27.1031 11.9957 23.6879 8.57778C22.047 6.93724 19.8217 6.01562 17.5014 6.01562C15.181 6.01562 12.9557 6.93724 11.3148 8.57778C7.89688 11.9957 8.06641 17.3633 11.4844 20.7813C11.4844 20.7813 13.9453 22.1485 13.9453 27.3438H21.0547Z" stroke="#F9006C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M19.1406 33.9062H15.8594" stroke="#F9006C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M20.2344 30.625H14.7656" stroke="#F9006C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M17.2266 1.09375V2.73437" stroke="#F9006C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M3.82812 14.4922H5.46875" stroke="#F9006C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M29.5312 14.4922H30.625" stroke="#F9006C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M7.75195 23.9667L8.91133 22.8074" stroke="#F9006C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M25.9273 5.79141L26.7012 5.01758" stroke="#F9006C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M7.75195 5.01758L8.91133 6.17695" stroke="#F9006C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M25.9273 23.1929L26.7012 23.9667" stroke="#F9006C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    );
};

export default LightBulbIcon;
