import { schoolLogos } from '@config/schools'
import {
    Box,
    Card,
    CardActions,
    CardContent,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material'
import AntDesign from '@expo/vector-icons/AntDesign'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import UploadVerificationDialog from '@components/OnboardingDialog/OnboardingProgress/OnboardingSteps/Background/UploadVerificationDialog'
import { useState } from 'react'

interface DegreeCard {
    degree: {
        id: string
        year: string
        name: string
        school: string
        verificationImg?: string
        isVerified?: boolean
    }
    showVerificationOption?: boolean
    remove?: () => void
}

const DegreeCard: React.FC<DegreeCard> = ({
    degree,
    showVerificationOption,
    remove,
}) => {
    const theme = useTheme()
    const [showUploadVerificationDialog, setShowUploadVerificationDialog] =
        useState(false)

    return (
        <Card sx={{ width: '100%', marginBottom: 2 }} variant="outlined">
            {showVerificationOption && (
                <UploadVerificationDialog
                    visible={showUploadVerificationDialog}
                    setVisible={setShowUploadVerificationDialog}
                    id={degree.id}
                />
            )}

            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                {!!remove && (
                    <IconButton
                        onClick={remove}
                        sx={{ position: 'absolute', top: 4, right: 4 }}
                    >
                        <AntDesign
                            name="close"
                            size={24}
                            color={theme.palette.grey[500]}
                        />
                    </IconButton>
                )}
                <img
                    src={schoolLogos[degree.school]}
                    style={{
                        width: 44,
                        height: 44,
                        marginRight: 16,
                        objectFit: 'contain',
                    }}
                />
                <Box>
                    <Typography variant="h6" fontFamily="Poppins_600SemiBold">
                        {degree.school}
                    </Typography>
                    <Typography
                        variant="body1"
                        fontFamily="Poppins_500Medium"
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                        }}
                    >
                        {degree.name} - {degree.year}
                    </Typography>
                    {degree.isVerified ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MaterialIcons
                                name="verified"
                                size={24}
                                color={theme.palette.primary.main}
                                style={{ marginRight: 8 }}
                            />
                            <Typography
                                variant="body1"
                                fontFamily="Poppins_500Medium"
                            >
                                Verified
                            </Typography>
                        </Box>
                    ) : degree.verificationImg ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MaterialIcons
                                name="verified"
                                size={24}
                                color={theme.palette.grey[500]}
                                style={{ marginRight: 8 }}
                            />
                            <Typography
                                variant="body1"
                                fontFamily="Poppins_500Medium"
                            >
                                Awaiting verification
                            </Typography>
                        </Box>
                    ) : showVerificationOption ? (
                        <Typography
                            variant="body1"
                            fontFamily="Poppins_500Medium"
                            color={theme.palette.primary.main}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                setShowUploadVerificationDialog(true)
                            }}
                        >
                            Verify Degree
                        </Typography>
                    ) : null}
                </Box>
            </CardContent>
        </Card>
    )
}

export default DegreeCard
