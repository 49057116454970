import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'

interface Props {
    title: string
    message: string
    onClose: () => void
    onAccept: () => void
    colors: any
    defaultStyles: any
}

const CancelModal: React.FC<Props> = ({
    title,
    message,
    onClose,
    onAccept,
    colors,
    defaultStyles,
}) => {
    return (
        <View
            style={{
                zIndex: 999,
                width: 450,
                backgroundColor: colors.background,
                borderRadius: defaultStyles.radius,
                padding: 15,
                border: '1px solid' + colors.border,
            }}
        >
            <Text
                style={{
                    color: colors.text,
                    fontSize: 19,
                    fontFamily: defaultStyles.titleFont,
                    textAlign: 'center',
                    marginTop: 15,
                }}
            >
                {title}
            </Text>

            <Text
                style={{
                    color: colors.text,
                    fontSize: 16,
                    fontFamily: defaultStyles.regularFont,
                    textAlign: 'center',
                    marginTop: 15,
                }}
            >
                {message}
            </Text>

            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: 30,
                }}
            >
                <TouchableOpacity
                    style={{
                        backgroundColor: colors.border,
                        width: 100,
                        height: 40,
                        marginLeft: 5,
                        borderRadius: defaultStyles.buttonRadius,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onPress={() => {
                        onClose()
                    }}
                >
                    <Text
                        style={{
                            color: colors.text,
                            fontSize: 16,
                            fontFamily: defaultStyles.boldFont,
                        }}
                    >
                        Dismiss
                    </Text>
                </TouchableOpacity>

                <TouchableOpacity
                    style={{
                        backgroundColor: colors.primary,
                        height: 40,
                        marginLeft: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: defaultStyles.buttonRadius,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onPress={() => {
                        onAccept()
                    }}
                >
                    <Text
                        style={{
                            color: '#fff',
                            fontSize: 16,
                            fontFamily: defaultStyles.boldFont,
                        }}
                    >
                        Take me there
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default CancelModal
