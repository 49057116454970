import OnboardingFooter from '@components/OnboardingDialog/OnboardingFooter'
import { useUserContext } from '@contexts/userContext'
import {
    Autocomplete,
    Box,
    Chip,
    Divider,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { StyleSheet } from 'react-native'
import { languageIcons, languages } from '@config/languages'
import {
    specialties,
    subjectIcons,
    subjects as subjectsList,
} from '@config/subjects'
import { exams } from '@config/exams'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import LanguageChip from '@components/Chips/LanguageChip'
import { useColorsContext, useStyles } from '@utils/styles'
import SubjectChip from '@components/Chips/SubjectChip'
import TutredBaseChip from '@components/Chips/TutredBaseChip'
import Feather from '@expo/vector-icons/Feather'
import SpecialtyIcon from '@icons/SpecialtyIcon'
import ExamPrepIcon from '@icons/ExamPrepIcon'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import OnboardingSectionHeader from '@components/OnboardingDialog/OnboardingSectionHeader'
import SpecialtyChip from '@components/Chips/SpecialtyChip'
import InputIconLabel from '@components/InputIconLabel'

interface SubjectsForm {
    subjects: string[]
    skills: string[]
    languages: string[]
    examTutor: string[]
}

const AddYourSubjects = ({ setStep }) => {
    const [loading, setLoading] = useState(false)
    const { extendedUser, user } = useUserContext()
    const { db } = useFirebaseContext()
    const { darkMode, text } = useColorsContext()

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<SubjectsForm>()
    const defaultStyles = useStyles()
    const { subjects, skills } = watch()
    const theme = useTheme()

    const skillOptions = useMemo(() => {
        const options = []
        let userSubjects = []
        if (subjects) {
            userSubjects = Object.keys(subjects).map((key) => key)
        }

        if (subjectsList) {
            userSubjects = subjects
        }
        userSubjects?.forEach((subject) => {
            specialties[subject].forEach((specialty) => {
                options.push(specialty)
            })
        })
        return options
    }, [subjectsList, subjects])

    const existingExams = useMemo(() => {
        let existing = []
        if (extendedUser?.examTutor) {
            extendedUser?.examTutor.forEach((userExam) => {
                console.log('userExam', userExam)
                const exam = exams.find((exam) => userExam === exam.value)
                if (exam) {
                    existing.push(exam)
                }
            })
        }
        return existing
    }, [extendedUser?.exams])

    const handleSave = (data) => {
        const languages = {}
        data.languages.forEach((language) => {
            languages[language] = true
        })
        const subjects = {}
        data.subjects.forEach((subject) => {
            subjects[subject] = true
        })
        const exams = []
        data.examTutor.forEach((exam) => {
            exams.push(exam.value)
        })
        updateDoc(doc(db, 'users', user.uid), {
            skills: data.skills,
            languages: languages,
            subjects: subjects,
            examTutor: exams,
            onboarding: arrayUnion('subjects'),
        })

        setStep(3)
    }

    // Remove any skills that don't belong to selected subjects
    useEffect(() => {
        const selectedSkills = skills || []
        const selectedSubjects = subjects || []

        // Get all valid specialties for selected subjects
        const validSpecialties = []
        selectedSubjects.forEach((subject) => {
            if (specialties[subject]) {
                validSpecialties.push(...specialties[subject])
            }
        })

        // Filter out skills that aren't in valid specialties
        const validSkills = selectedSkills.filter((skill) => {
            console.log('skill', skill)
            return validSpecialties.some((specialty) => specialty === skill)
        })

        // Update form if any skills were removed
        if (validSkills.length !== selectedSkills.length) {
            setValue('skills', validSkills)
        }
    }, [subjects, skills])

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            {/* header */}
            <OnboardingSectionHeader
                title="Subjects"
                subtitle="Tell students what you teach. List the subjects, and specialties you offer to help learners find the perfact match for their needs."
                icon={<Feather name="book" size={48} color={text} />}
            />
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    overflowY: 'auto',
                }}
            >
                <Box sx={styles.inputContainer}>
                    <InputIconLabel
                        icon={<Feather name="book" size={24} color={text} />}
                        label="Subjects"
                        description="Choose up to 6 subjects you want to teach."
                    />

                    <Controller
                        name="subjects"
                        control={control}
                        rules={{
                            required: true,
                            validate: (value) =>
                                !value ||
                                value.length <= 6 ||
                                'You can only select up to 6 subjects',
                        }}
                        defaultValue={
                            extendedUser?.subjects
                                ? Object.keys(extendedUser?.subjects).map(
                                      (key) => key
                                  )
                                : []
                        }
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                size="small"
                                multiple
                                options={subjectsList}
                                getOptionLabel={(option) => option}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => {
                                        const { key, ...tagProps } =
                                            getTagProps({
                                                index,
                                            })
                                        return (
                                            <SubjectChip
                                                key={key}
                                                subject={option}
                                                {...tagProps}
                                            />
                                        )
                                    })
                                }
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                {...field}
                                onChange={(_, value) => {
                                    field.onChange(value)
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <Box
                                            key={option}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                p: 1,
                                            }}
                                            {...props}
                                        >
                                            <Box
                                                sx={{
                                                    width: 28,
                                                    height: 28,
                                                    backgroundColor: darkMode
                                                        ? theme.palette
                                                              .grey[400]
                                                        : theme.palette
                                                              .grey[800],
                                                    WebkitMask: `url(${subjectIcons[option]}) no-repeat center `,
                                                    mask: `url(${subjectIcons[option]}) no-repeat center `,
                                                    maskSize: 'contain',
                                                    WebkitMaskSize: 'contain',
                                                    mr: 2,
                                                }}
                                            />
                                            <Typography>{option}</Typography>
                                        </Box>
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        {...params}
                                        error={!!errors?.subjects}
                                        placeholder="Select Subject(s)"
                                        helperText={errors?.subjects?.message}
                                    />
                                )}
                            />
                        )}
                    />
                </Box>
                <Divider
                    variant="middle"
                    color={text}
                    sx={{ mt: 1.5, mb: 3 }}
                />
                <Box sx={styles.inputContainer}>
                    <InputIconLabel
                        icon={
                            <MaterialIcons
                                name="translate"
                                size={24}
                                color={text}
                            />
                        }
                        label="Languages"
                        description="Choose the languages you can fluently teach in. You can choose up to 6."
                    />
                    <Controller
                        name="languages"
                        control={control}
                        rules={{
                            required: true,
                            validate: (value) =>
                                !value ||
                                value.length <= 6 ||
                                'You can only select up to 6 languages',
                        }}
                        defaultValue={
                            extendedUser?.languages
                                ? Object.keys(extendedUser?.languages).map(
                                      (key) => key
                                  )
                                : []
                        }
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                size="small"
                                multiple
                                value={field.value}
                                options={languages}
                                getOptionLabel={(option) => option}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => {
                                        const { key, ...tagProps } =
                                            getTagProps({
                                                index,
                                            })
                                        return (
                                            <LanguageChip
                                                key={key}
                                                language={option}
                                                {...tagProps}
                                            />
                                        )
                                    })
                                }
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                {...field}
                                onChange={(_, value) => {
                                    field.onChange(value)
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                p: 1,
                                            }}
                                            {...props}
                                        >
                                            <img
                                                src={languageIcons[option]}
                                                style={{
                                                    width: 32,
                                                    height: 32,
                                                    marginRight: 16,
                                                    objectFit: 'contain',
                                                }}
                                            />
                                            <Typography>{option}</Typography>
                                        </Box>
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        {...params}
                                        error={!!errors?.languages}
                                        placeholder="Select Language(s)"
                                    />
                                )}
                            />
                        )}
                    />
                </Box>
                <Divider
                    variant="middle"
                    color={text}
                    sx={{ mt: 1.5, mb: 3 }}
                />
                <Box sx={styles.inputContainer}>
                    <InputIconLabel
                        icon={<SpecialtyIcon size={20} />}
                        label="Specialty"
                        optional={true}
                        description="Choose the specialties for the subjects you teach."
                    />
                    <Controller
                        name="skills"
                        control={control}
                        defaultValue={extendedUser?.skills}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                size="small"
                                multiple
                                // value={Object.keys(extendedUser?.languages).map(
                                //     (key) => key
                                // )}

                                options={skillOptions}
                                getOptionLabel={(option) => option}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => {
                                        const { key, ...tagProps } =
                                            getTagProps({
                                                index,
                                            })
                                        return (
                                            <SpecialtyChip
                                                key={key}
                                                specialty={option}
                                                {...tagProps}
                                            />
                                        )
                                    })
                                }
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                {...field}
                                onChange={(_, value) => {
                                    field.onChange(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        {...params}
                                        error={!!errors?.skills}
                                        placeholder="Select Specialtie(s)"
                                    />
                                )}
                            />
                        )}
                    />
                </Box>
                <Divider
                    variant="middle"
                    color={text}
                    sx={{ mt: 1.5, mb: 3 }}
                />
                <Box sx={styles.inputContainer}>
                    <InputIconLabel
                        icon={<ExamPrepIcon size={26} style={{}} />}
                        label="Exam Prep"
                        description="Choose the standardized tests you are qualified to help students prepare for."
                        optional={true}
                    />
                    <Controller
                        name="examTutor"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={existingExams}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                {...field}
                                size="small"
                                multiple
                                options={exams}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.label}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => {
                                        const { key, ...tagProps } =
                                            getTagProps({
                                                index,
                                            })
                                        return (
                                            <TutredBaseChip
                                                color="info"
                                                key={key}
                                                label={option.label}
                                                {...tagProps}
                                            />
                                        )
                                    })
                                }
                                sx={{
                                    '& .Mui-Autocomplete-root': {
                                        zIndex: 9999,
                                    },
                                }}
                                onChange={(_, value) => {
                                    field.onChange(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        {...params}
                                        error={!!errors?.examTutor}
                                        placeholder="Select exam(s)"
                                    />
                                )}
                            />
                        )}
                    />
                </Box>
            </Box>

            <OnboardingFooter
                step={2}
                onNext={() => {
                    handleSubmit(handleSave)()
                }}
                onPrev={() => {
                    setStep(1)
                }}
                loading={loading}
            />
        </Box>
    )
}

const styles = StyleSheet.create({
    inputContainer: {
        marginBottom: 2,
    },
})

export default AddYourSubjects
