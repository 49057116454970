import React, { useEffect } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { useColorsContext, useStyles } from '@utils/styles'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { subjectIcons } from '@config/subjects'
import { Feather } from '@expo/vector-icons'
import MinusCircleIcon from '@icons/MinuCircleIcon'
import PlusCircleIcon from '@icons/PlusCircleIcon'

interface Props {
    subjects: string[]
    showTitle?: boolean
    showIcon?: boolean
}

const Subjects: React.FC<Props> = ({
    subjects,
    showTitle = true,
    showIcon = true,
}) => {
    const theme = useTheme()
    const { text } = useColorsContext()
    const defaultStyles = useStyles()

    const [height, setHeight] = React.useState(0)
    const [seeMore, setSeeMore] = React.useState(false)
    const [isOverflowing, setIsOverflowing] = React.useState(false)
    const [overflowingItems, setOverflowingItems] = React.useState<string[]>([])
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    useEffect(() => {
        if (height > (isMd ? 42 : 28)) {
            setIsOverflowing(true)
        }
    }, [height, isMd])

    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: isMd ? 22 : 8,
            }}
        >
            <View
                onLayout={({ nativeEvent }) => {
                    setHeight(nativeEvent.layout.height)
                }}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    rowGap: isMd ? 8 : 4,
                    columnGap: isMd ? 8 : 4,
                    maxHeight:
                        isOverflowing && !seeMore ? (isMd ? 42 : 28) : null,
                    overflow: seeMore ? 'visible' : 'hidden',
                    flex: 1,
                }}
            >
                {showIcon && (
                    <View
                        style={{
                            width: isMd ? 30 : 24,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <Feather
                            name="book"
                            size={isMd ? 26 : 20}
                            color={text}
                        />
                    </View>
                )}
                {showTitle && (
                    <Typography
                        variant="h6"
                        sx={{
                            marginRight: 2,
                            color: text,
                        }}
                    >
                        Subjects
                    </Typography>
                )}

                {Object.keys(subjects)
                    .sort()
                    .map((subject, index) => {
                        return (
                            <View
                                onLayout={({ nativeEvent }) => {
                                    if (nativeEvent.layout.y > 0) {
                                        setOverflowingItems((prev) => [
                                            ...prev,
                                            subject,
                                        ])
                                    }
                                }}
                                key={subject}
                                style={{
                                    paddingHorizontal: isMd ? 16 : 8,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#005FDC66',
                                    borderRadius: isMd ? 10 : 6,
                                    height: isMd ? 42 : 28,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: isMd ? 22 : 16,
                                        height: isMd ? 22 : 16,
                                        backgroundColor: '#FFF',
                                        WebkitMask: `url(${subjectIcons[subject]}) no-repeat center `,
                                        mask: `url(${subjectIcons[subject]}) no-repeat center `,
                                        maskSize: 'contain',
                                        WebkitMaskSize: 'contain',
                                        mr: 1,
                                    }}
                                />

                                <Text
                                    key={`tutor-subject-${index}`}
                                    style={{
                                        fontSize: isMd ? 16 : 12,
                                        color: '#005FDC',
                                        fontFamily: defaultStyles.titleFont,
                                    }}
                                >
                                    {subject}
                                </Text>
                            </View>
                        )
                    })}
            </View>
            {isOverflowing && (
                <TouchableOpacity
                    style={{
                        height: 24,
                        width: 112,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#006EFF1A',
                        borderRadius: 6,
                        borderWidth: 1,
                        borderColor: '#E0DDDD',
                        marginTop: 16,
                    }}
                >
                    <Typography
                        variant="caption"
                        fontFamily={defaultStyles.titleFont}
                        sx={{
                            color: theme.palette.primary.main,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={() => setSeeMore(!seeMore)}
                    >
                        {seeMore ? 'See Less' : 'See More'}
                        <span style={{ fontFamily: 'Poppins_500Medium' }}>
                            {!seeMore
                                ? `(${String(overflowingItems.length)})`
                                : ''}
                        </span>
                        <Box
                            sx={{
                                ml: 0.5,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            {seeMore ? <MinusCircleIcon /> : <PlusCircleIcon />}
                        </Box>
                    </Typography>
                </TouchableOpacity>
            )}
        </View>
    )
}

export default Subjects
