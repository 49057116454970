import React from 'react'
import { HelpStackParamList } from 'navigators/HelpStack'
import {
    ActivityIndicator,
    SafeAreaView,
    ScrollView,
    StyleSheet,
} from 'react-native'
import articles from '../data'
import {
    Box,
    Container,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useUserContext } from '@contexts/userContext'
import { Header as AuthHeader } from '@components/AuthHeader'
import { Header } from '@components/Header'
import { useColorsContext, useStyles } from '@utils/styles'
import { useEffect, useState } from 'react'
import Footer from '@components/Footer'

interface ArticleType {
    slug: string
    title: string
    sections: [
        {
            title?: string
            text?: string
            bullets?: { title: string; text: string }[]
        },
    ]
}

const HelpArticle: React.FC<HelpStackParamList['HelpArticle']> = ({
    route,
    navigation,
}) => {
    const { background } = useColorsContext()
    const theme = useTheme()
    const defaultStyles = useStyles()

    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: background,
            flexDirection: 'column',
        },
    })
    const { user } = useUserContext()
    const { slug } = route.params
    const [article, setArticle] = useState<ArticleType>(null)
    useEffect(() => {
        Object.keys(articles).forEach((key) => {
            articles[key].forEach((article) => {
                if (article.slug === slug) {
                    setArticle(article)
                }
            })
        })
    }, [])

    useEffect(() => {
        if (article) {
            navigation.setOptions({ title: article.title })
        }
    }, [article])

    return (
        <SafeAreaView style={styles.container}>
            <ScrollView>
                <Container sx={{ padding: isMd ? 4 : 2 }}>
                    {article ? (
                        <>
                            <Typography
                                variant={isMd ? 'h4' : 'h5'}
                                sx={{
                                    fontFamily: defaultStyles.titleFont,
                                    marginBottom: 4,
                                }}
                            >
                                {article.title}
                            </Typography>
                            {article?.sections &&
                                article.sections.map((section) => (
                                    <Box>
                                        {section.title && (
                                            <Typography
                                                variant={isMd ? 'h5' : 'body1'}
                                                sx={{
                                                    fontFamily:
                                                        defaultStyles.titleFont,
                                                }}
                                            >
                                                {section.title}
                                            </Typography>
                                        )}
                                        {section.text && (
                                            <Typography
                                                variant={
                                                    isMd ? 'body1' : 'body2'
                                                }
                                                sx={{
                                                    margin: 2,
                                                }}
                                            >
                                                {section.text}
                                            </Typography>
                                        )}
                                        {section?.bullets &&
                                            section.bullets.map(
                                                (bullet, index) => (
                                                    <Box
                                                        sx={{
                                                            marginTop: 1,
                                                            marginLeft: 2,
                                                        }}
                                                    >
                                                        <Typography
                                                            variant={
                                                                isMd
                                                                    ? 'body1'
                                                                    : 'body2'
                                                            }
                                                            sx={{
                                                                fontFamily:
                                                                    defaultStyles.titleFont,
                                                            }}
                                                        >
                                                            {index + 1}.{' '}
                                                            {bullet.title}
                                                        </Typography>

                                                        <Typography
                                                            variant={
                                                                isMd
                                                                    ? 'body2'
                                                                    : 'subtitle1'
                                                            }
                                                            sx={{
                                                                marginLeft: 2,
                                                            }}
                                                        >
                                                            {bullet.text}
                                                        </Typography>
                                                    </Box>
                                                )
                                            )}
                                    </Box>
                                ))}
                        </>
                    ) : (
                        <ActivityIndicator />
                    )}
                </Container>

                <Footer />
            </ScrollView>
        </SafeAreaView>
    )
}

export default HelpArticle
