import {
    Box,
    Collapse,
    CSSObject,
    Drawer,
    Theme,
    useTheme,
} from '@mui/material'
import React from 'react'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import TutorListFilters from '../TutorListFilters'
import { FilterType } from 'dataTypes'

interface Props {
    filters: FilterType[]
    setFilters: React.Dispatch<React.SetStateAction<FilterType[]>>
}
export const TutorListLargeDrawer: React.FC<Props> = ({
    filters,
    setFilters,
}) => {
    const [drawerOpen, setDrawerOpen] = React.useState(true)
    const theme = useTheme()

    return (
        <Collapse in={!drawerOpen} collapsedSize={120}>
            <Drawer
                variant="permanent"
                sx={{
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    width: drawerOpen ? 400 : 72,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        width: drawerOpen ? 400 : 72,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Box sx={{ overflowY: 'auto', marginTop: '133px' }}>
                    <TutorListFilters
                        filters={filters}
                        setFilters={setFilters}
                        drawerOpen={drawerOpen}
                        setDrawerOpen={setDrawerOpen}
                    />
                </Box>
            </Drawer>
        </Collapse>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
