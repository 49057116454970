import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import { debounce } from '@mui/material/utils'
import { Control, Controller } from 'react-hook-form'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY

interface MainTextMatchedSubstrings {
    offset: number
    length: number
}
interface StructuredFormatting {
    main_text: string
    secondary_text: string
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[]
}
interface PlaceType {
    description: string
    structured_formatting: StructuredFormatting
}

interface AddressAutoCompleteProps {
    control: Control
    errors: any
}

const AddressAutoComplete: React.FC<AddressAutoCompleteProps> = ({
    control,
    error,
    setAddress,
}) => {
    const [value, setValue] = React.useState<PlaceType | null>(null)
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState<readonly PlaceType[]>([])
    const loaded = React.useRef(false)

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: process.env.GOOGLE_MAPS_API_KEY,
    })

    React.useEffect(() => {
        if (inputValue === '') {
            setOptions(value ? [value] : [])
            return undefined
        }

        debounce(() => {
            getPlacePredictions({
                input: inputValue,
                types: ['address'],
                componentRestrictions: { country: ['us', 'ca'] },
            })
        }, 400)()
    }, [value, inputValue, fetch])

    return (
        <Controller
            defaultValue=""
            name="address"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
                <Autocomplete
                    {...field}
                    getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.description
                    }
                    filterOptions={(x) => x}
                    options={placePredictions}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    noOptionsText="No locations"
                    onChange={(event: any, newValue: PlaceType | null) => {
                        setOptions(newValue ? [newValue, ...options] : options)
                        placesService.getDetails(
                            {
                                placeId: newValue?.place_id,
                            },
                            (data: any) => {
                                const address = {
                                    streetNumber: '',
                                    street: '',
                                    city: '',
                                    province: '',
                                    postalCode: '',
                                    country: '',
                                }
                                data.address_components.forEach(
                                    (component: any) => {
                                        if (
                                            component.types.includes(
                                                'street_number'
                                            )
                                        ) {
                                            address.streetNumber =
                                                component.long_name
                                        }
                                        if (
                                            component.types.includes('country')
                                        ) {
                                            address.country =
                                                component.long_name
                                        }
                                        if (
                                            component.types.includes(
                                                'administrative_area_level_1'
                                            )
                                        ) {
                                            address.province =
                                                component.long_name
                                        }
                                        if (
                                            component.types.includes('locality')
                                        ) {
                                            address.city = component.long_name
                                        }
                                        if (
                                            component.types.includes(
                                                'postal_code'
                                            )
                                        ) {
                                            address.postalCode =
                                                component.long_name
                                        }
                                        if (component.types.includes('route')) {
                                            address.street = component.long_name
                                        }
                                    }
                                )
                                setAddress(address)
                            }
                        )
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputRef={ref}
                            label="Street Address"
                            fullWidth
                            placeholder="Search for your address"
                            error={!!error}
                        />
                    )}
                    renderOption={(props, option) => {
                        const { key, ...optionProps } = props
                        const matches =
                            option.structured_formatting
                                .main_text_matched_substrings || []

                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match: any) => [
                                match.offset,
                                match.offset + match.length,
                            ])
                        )
                        return (
                            <li key={key} {...optionProps}>
                                <Grid container sx={{ alignItems: 'center' }}>
                                    <Grid
                                        item
                                        sx={{ display: 'flex', width: 44 }}
                                    >
                                        <LocationOnIcon
                                            sx={{ color: 'text.secondary' }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            width: 'calc(100% - 44px)',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        {parts.map((part, index) => (
                                            <Box
                                                key={index}
                                                component="span"
                                                sx={{
                                                    fontWeight: part.highlight
                                                        ? 'bold'
                                                        : 'regular',
                                                }}
                                            >
                                                {part.text}
                                            </Box>
                                        ))}
                                        <Typography
                                            variant="body2"
                                            sx={{ color: 'text.secondary' }}
                                        >
                                            {
                                                option.structured_formatting
                                                    .secondary_text
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        )
                    }}
                />
            )}
        />
    )
}

export default AddressAutoComplete
