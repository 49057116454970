import { useColorsContext } from "@utils/styles"
import * as React from "react"
import Svg, { Path, Rect, Defs, Pattern, Use, Image } from "react-native-svg"
const TutredIcon = ({
    size = 64,
    noBorder = false,
    textColor,
}) => {
    const { text, primary } = useColorsContext();

    return (

        <Svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"

            viewBox="0 0 183 95"
            width={size * 1.878}
            height={size}
            fill="none"
        >
            <Path
                fill={textColor || primary}
                d="M29.925 60.489v7.25h-4.35c-3.1 0-5.517-.75-7.25-2.25-1.733-1.533-2.6-4.017-2.6-7.45v-11.1h-3.4v-7.1h3.4v-6.8h8.55v6.8h5.6v7.1h-5.6v11.2c0 .833.2 1.433.6 1.8.4.367 1.067.55 2 .55h3.05Zm32.312-20.65v27.9h-8.55v-3.8c-.866 1.233-2.05 2.233-3.55 3-1.466.733-3.1 1.1-4.9 1.1-2.133 0-4.016-.467-5.65-1.4-1.633-.967-2.9-2.35-3.8-4.15-.9-1.8-1.35-3.917-1.35-6.35v-16.3h8.5v15.15c0 1.867.484 3.317 1.45 4.35.967 1.033 2.267 1.55 3.9 1.55 1.667 0 2.984-.517 3.95-1.55.967-1.033 1.45-2.483 1.45-4.35v-15.15h8.55Zm21.692 20.65v7.25h-4.35c-3.1 0-5.517-.75-7.25-2.25-1.734-1.533-2.6-4.017-2.6-7.45v-11.1h-3.4v-7.1h3.4v-6.8h8.55v6.8h5.6v7.1h-5.6v11.2c0 .833.2 1.433.6 1.8.4.367 1.066.55 2 .55h3.05Zm13.312-16c1-1.533 2.25-2.733 3.75-3.6 1.5-.9 3.167-1.35 5-1.35v9.05h-2.35c-2.133 0-3.733.467-4.8 1.4-1.066.9-1.6 2.5-1.6 4.8v12.95h-8.55v-27.9h8.55v4.65Zm39.137 8.85c0 .8-.05 1.633-.15 2.5h-19.35c.133 1.733.683 3.067 1.65 4 1 .9 2.217 1.35 3.65 1.35 2.133 0 3.617-.9 4.45-2.7h9.1a12.334 12.334 0 0 1-2.55 4.95c-1.2 1.467-2.717 2.617-4.55 3.45-1.833.833-3.883 1.25-6.15 1.25-2.733 0-5.167-.583-7.3-1.75s-3.8-2.833-5-5c-1.2-2.167-1.8-4.7-1.8-7.6 0-2.9.583-5.433 1.75-7.6 1.2-2.167 2.867-3.833 5-5 2.133-1.167 4.583-1.75 7.35-1.75 2.7 0 5.1.567 7.2 1.7 2.1 1.133 3.733 2.75 4.9 4.85 1.2 2.1 1.8 4.55 1.8 7.35Zm-8.75-2.25c0-1.467-.5-2.633-1.5-3.5s-2.25-1.3-3.75-1.3c-1.433 0-2.65.417-3.65 1.25-.967.833-1.567 2.017-1.8 3.55h10.7Zm11.56 2.65c0-2.867.534-5.383 1.6-7.55 1.1-2.167 2.584-3.833 4.45-5 1.867-1.167 3.95-1.75 6.25-1.75 1.834 0 3.5.383 5 1.15 1.534.767 2.734 1.8 3.6 3.1v-12.95h8.55v37h-8.55v-4c-.8 1.333-1.95 2.4-3.45 3.2-1.466.8-3.183 1.2-5.15 1.2-2.3 0-4.383-.583-6.25-1.75-1.866-1.2-3.35-2.883-4.45-5.05-1.066-2.2-1.6-4.733-1.6-7.6Zm20.9.05c0-2.133-.6-3.817-1.8-5.05-1.166-1.233-2.6-1.85-4.3-1.85-1.7 0-3.15.617-4.35 1.85-1.166 1.2-1.75 2.867-1.75 5s.584 3.833 1.75 5.1c1.2 1.233 2.65 1.85 4.35 1.85 1.7 0 3.134-.617 4.3-1.85 1.2-1.233 1.8-2.917 1.8-5.05Z"
            />
            {!noBorder &&
                <>

                    <Rect
                        width={179}
                        height={91}
                        x={2}
                        y={2.739}
                        stroke={text}
                        strokeWidth={4}
                        rx={12}
                    />
                    <Rect width={35} height={7} x={105} y={85.739} fill={text} rx={1.5} />
                </>
            }
            <Path
                fill={text}
                fillRule="evenodd"
                d="M133.853 31.414c-3.782-1.38-7.555-2.482-11.351-2.477-3.796.005-7.57 1.108-11.354 2.477-.145.053-.236.173-.236.313v5.343a.32.32 0 0 0 .166.28.405.405 0 0 0 .352.033c3.69-1.322 7.37-2.37 11.072-2.376 3.701-.005 7.38 1.042 11.067 2.374.12.044.245.032.352-.033a.32.32 0 0 0 .167-.28V31.727c0-.14-.09-.259-.235-.312Zm4.965 4.548-.446 2.987h.259c.205 0 .373-.146.378-.33l.035-1.301.045 1.63h.244c.204 0 .372-.145.377-.328l.057-2.074.065 2.403h.015a.387.387 0 0 0 .275-.106.305.305 0 0 0 .102-.252l-.06-.886.116.943c.021.172.182.3.375.3h.057l-.446-2.986a.309.309 0 0 1 .113-.288.111.111 0 0 0 .04-.085v-.067h-1.755v.067c0 .033.016.064.041.085a.31.31 0 0 1 .113.288Zm1.601-.845v-.523c0-.186-.169-.337-.377-.337h-1c-.208 0-.378.151-.378.337v.523c0 .186.17.337.378.337h1c.208 0 .377-.151.377-.337Zm0-.928v-.093c0-.064-.058-.116-.129-.117-.206-.004-.371-.154-.371-.338v-5.008c0-.138.087-.256.229-.31l2.597-1c.142-.053.229-.171.229-.309 0-.137-.087-.255-.229-.31l-20.096-7.727a.403.403 0 0 0-.298 0l-20.096 7.727c-.142.055-.229.173-.229.31 0 .138.087.256.229.31l8.657 3.329v-.064c3.864-1.408 7.715-2.558 11.59-2.563 3.875-.005 7.725 1.143 11.586 2.563v.064l3.998-1.537c.143-.055.23-.174.229-.313-.002-.139-.091-.256-.234-.31l-8.671-3.191c-.192-.07-.284-.269-.205-.44.079-.172.301-.255.493-.184l9.233 3.4c.144.052.233.172.233.31v5.253c0 .184-.165.334-.37.337-.072.002-.13.054-.13.118v.093h1.755Z"
                clipRule="evenodd"
            />
            <Path
                fill={text}
                d="m110.912 28.227 11.59-.621 11.586.62v3.88h-23.176v-3.88ZM139.087 27.89l.34.719-1.516.574-.341-.718 1.517-.575Z"
            />
            <Path
                fill={text}
                d="m128.39 23.932 10.875 3.315-.654 1.495-10.87-3.108.649-1.702Z"
            />
        </Svg>
    )
}

export default TutredIcon
