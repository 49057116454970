import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'

const Discover = () => {
    const { primary, primaryDark } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Box
            sx={{
                p: { xs: 2, md: 4 },
                mt: { xs: 4, md: 10 },
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    height: 275,
                    width: 275,
                    backgroundColor: '#026FFF',
                    position: 'absolute',
                    top: -56,
                    right: -166,
                    opacity: 0.3,
                    borderRadius: 300,
                }}
            />
            <Box>
                <Typography
                    component="span"
                    variant={isMd ? 'h2' : 'h4'}
                    fontFamily={defaultStyles.titleFont}
                    sx={{ mb: 2 }}
                >
                    <span style={{ position: 'relative' }}>
                        <img
                            src={require('@assets/images/landingpage/dash-accent.svg')}
                            alt="quote"
                            style={{
                                position: 'absolute',
                                top: isMd ? -16 : -8,
                                left: isMd ? -32 : -16,
                                width: isMd ? 40 : 20,
                            }}
                        />
                    </span>
                    Discover what{' '}
                    <span
                        style={{
                            color: primary,
                            fontFamily: 'Poppins_700Bold',
                            position: 'relative',
                            marginBottom: isSm ? 0 : 8,
                        }}
                    >
                        tutred
                        <img
                            src={require('@assets/images/landingpage/underline.svg')}
                            style={{
                                width: '100%',
                                position: 'absolute',
                                bottom: -6,
                                left: 0,
                            }}
                            alt="logo"
                        />
                    </span>{' '}
                    <br />
                    has to offer
                </Typography>

                <Typography
                    variant={isMd ? 'h4' : 'h6'}
                    fontFamily={'Poppins_500Medium'}
                >
                    We designed an innovative solution for students
                    <br /> to instantly match with the right tutor
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMd ? 'row' : 'column',
                    gap: 4,
                    mt: 4,
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        height: 300,
                        width: 300,
                        backgroundColor: '#026FFF',
                        position: 'absolute',
                        top: -66,
                        left: -288,
                        opacity: 0.3,
                        borderRadius: 120,
                    }}
                />
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            height: 180,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={require('@assets/images/landingpage/discover1.png')}
                            alt="discover-1"
                            style={{
                                maxHeight: 140,
                                width: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            position: 'relative',
                            height: 120,
                            width: 240,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 4,
                            p: 2,
                        }}
                    >
                        <img
                            src={require('@assets/images/landingpage/brushstroke.svg')}
                            alt="brushstroke"
                            style={{
                                position: 'absolute',
                                width: '160%',
                                height: '160%',
                            }}
                        />
                        <Typography
                            variant="h5"
                            fontFamily={defaultStyles.boldFont}
                            textAlign="center"
                            sx={{
                                position: 'relative',
                                zIndex: 1,
                            }}
                        >
                            Use{' '}
                            <span style={{ color: primary }}>Instabook</span> to
                            start a session instantly
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            height: 180,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={require('@assets/images/landingpage/discover2.svg')}
                            alt="discover-2"
                            style={{ maxHeight: 140, width: '100%' }}
                        />
                    </Box>
                    <Box
                        sx={{
                            position: 'relative',
                            height: 120,
                            width: 240,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 4,
                            p: 2,
                        }}
                    >
                        <img
                            src={require('@assets/images/landingpage/brushstroke.svg')}
                            alt="brushstroke"
                            style={{
                                position: 'absolute',
                                width: '160%',
                                height: '160%',
                            }}
                        />
                        <Typography
                            variant="h5"
                            fontFamily={defaultStyles.boldFont}
                            textAlign="center"
                            sx={{
                                position: 'relative',
                                zIndex: 1,
                            }}
                        >
                            <span style={{ color: primary }}>24/7</span> access
                            to <span style={{ color: primary }}>tutors</span>
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            height: 180,

                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={require('@assets/images/landingpage/discover3.svg')}
                            alt="discover-3"
                            style={{ maxHeight: 120, width: '100%' }}
                        />
                    </Box>
                    <Box
                        sx={{
                            position: 'relative',
                            height: 120,
                            width: 240,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 4,
                            p: 2,
                        }}
                    >
                        <img
                            src={require('@assets/images/landingpage/brushstroke.svg')}
                            alt="brushstroke"
                            style={{
                                position: 'absolute',
                                width: '160%',
                                height: '160%',
                            }}
                        />
                        <Typography
                            variant="h5"
                            fontFamily={defaultStyles.boldFont}
                            textAlign="center"
                            sx={{
                                position: 'relative',
                                zIndex: 1,
                            }}
                        >
                            Browse and choose tutors based on{' '}
                            <span style={{ color: primary }}>filters</span>,
                            <span style={{ color: primary }}>
                                {' '}
                                credentials{' '}
                            </span>
                            and <span style={{ color: primary }}>reviews</span>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Discover
