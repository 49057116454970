import React, { useState } from 'react'
import { useColorsContext, useStyles } from '../../utils/styles'
import { useUserContext } from '../../contexts/userContext'
import {
    Box,
    Divider,
    Tabs,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { MultiSwitchToggle } from '@components/MultiSwitchToggle'

import UnreadNotifications from './UnreadNotifications'
import AllNotifications from './AllNotifications'
import PendingNotifications from './PendingNotifications'
import Footer from '@components/Footer'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { useNotificationsContext } from '@contexts/notificationsContext'

export const Notifications = () => {
    const { background } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))
    const { pendingNotifications, unreadNotifications } =
        useNotificationsContext()

    const { db } = useFirebaseContext()

    const [selectedIndex, setSelectedIndex] = useState(0)

    return (
        <Box
            sx={{
                flex: 1,
                justifyContent: 'center',
                backgroundColor: background,
                overflowY: 'auto',
            }}
        >
            <Box
                sx={{
                    padding: isSm ? 2 : 4,
                    maxWidth: 1280,
                    margin: 'auto',
                    minHeight: '100%',
                    borderLeft: `1px solid ${theme.palette.grey[400]}`,
                    borderRight: `1px solid ${theme.palette.grey[400]}`,
                    overflowY: 'auto',
                }}
            >
                <Typography
                    variant={isSm ? 'h5' : 'h4'}
                    textAlign="center"
                    fontFamily={defaultStyles.titleFont}
                >
                    Notifications
                </Typography>
                <Divider sx={{ mt: 1, mb: isSm ? 1 : 2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <MultiSwitchToggle
                        options={[
                            {
                                label: `${pendingNotifications.length ? `(${pendingNotifications.length})` : ''}Pending`,
                                value: 'pending',
                            },
                            {
                                label: `${unreadNotifications.length ? `(${unreadNotifications.length})` : ''}Unread`,
                                value: 'unread',
                            },
                            { label: 'All', value: 'all' },
                        ]}
                        highlightColor={theme.palette.primary.main}
                        highlightTextColor={theme.palette.primary.contrastText}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                    />
                </Box>
                <Box sx={{ mt: isSm ? 2 : 4, flex: 1, overflowY: 'auto' }}>
                    {selectedIndex === 0 && <PendingNotifications />}
                    {selectedIndex === 1 && <UnreadNotifications />}
                    {selectedIndex === 2 && <AllNotifications />}
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}
