import Agenda from '@components/Agenda'
import { Box, Button, DialogActions, DialogContent } from '@mui/material'
import { PricingOption } from 'dataTypes'
import React, { useState } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { MultiSwitchToggleOption } from '@components/MultiSwitchToggle/MutliSwitchToggleOption'

interface Props {
    setSelectedTime: React.Dispatch<React.SetStateAction<Date>>
    tutor: any
    onSelect: () => void
    setSelectedPricingIndex: React.Dispatch<React.SetStateAction<number>>
    selectedPricingIndex: number
    setView: React.Dispatch<React.SetStateAction<number>>
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    selectedTime: Date
    view: number
    pricingOptions: MultiSwitchToggleOption[]
}

const SelectSession = ({
    setSelectedTime,
    tutor,
    onSelect,
    setSelectedPricingIndex,
    selectedPricingIndex,
    setView,
    setVisible,
    selectedTime,
    view,
    pricingOptions,
}: Props) => {
    return (
        <>
            <DialogContent
                sx={{
                    minWidth: 600,
                    padding: 2,
                    display: view === 0 ? 'block' : 'none',
                }}
            >
                <Agenda
                    tutor={tutor}
                    selectedTime={selectedTime}
                    pricingOptions={pricingOptions}
                    setSelectedTime={setSelectedTime}
                    setSelectedPricingIndex={setSelectedPricingIndex}
                    selectedPricingIndex={selectedPricingIndex}
                />
            </DialogContent>
            <DialogActions sx={{ display: view === 0 ? 'flex' : 'none' }}>
                <Button variant="outlined" onClick={() => setVisible(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setView(1)
                    }}
                >
                    Next
                </Button>
            </DialogActions>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    button: {
        width: 100,
        height: 40,
        marginLeft: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default SelectSession
